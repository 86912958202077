import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Box, Text, TouchableBox} from '@youtoken/ui.primitives';
import {ProgressBar} from '@youtoken/ui.progress-bar';
import {
  InformationTable,
  InformationTableRow,
} from '@youtoken/ui.information-table';
import {useForm} from '@youtoken/ui.form-elements';
import {Icon} from '@youtoken/ui.icons';
import {Link} from '@youtoken/ui.elements';
import {QuestionTooltip} from '@youtoken/ui.tooltip';
import {ExchangeFormState} from '../../state';
import {RateModal} from '../RateModal';

export const ExchangeFormInfoTableV2: React.FC = observer(() => {
  const {t} = useTranslation();

  const {
    form: {
      rateIsFixed,
      rateTimeIntervalName,
      rateTimeIntervalProgress,
      ticker,
      conversionTicker,
    },
    view: {
      tickerFormatted,
      conversionTickerFormatted,
      rateFormatted,
      feeFormatted,
    },
  } = useForm<ExchangeFormState>();

  const [isRateModalOpen, setIsRateModalOpen] = React.useState(false);

  const handlePressRate = React.useCallback(() => {
    setIsRateModalOpen(true);
  }, []);

  return (
    <>
      <InformationTable mt={24} py={0}>
        <TouchableBox onPress={handlePressRate}>
          <InformationTableRow
            testID="CONVERT_TABLE_RATE_ROW"
            left={
              <Box flexDirection="row">
                <Icon color="$interactive-01" name="hodl" />
                <Link ml={2} onPress={handlePressRate}>
                  {rateIsFixed
                    ? t('surface.wallets.conversion_form_v2.table.rate_simple')
                    : t('surface.wallets.conversion_form_v2.table.rate', {
                        type: t(
                          'surface.wallets.conversion_form_v2.rate_type.approximate'
                        ),
                      })}
                </Link>
                <QuestionTooltip
                  content={t(
                    rateIsFixed
                      ? 'surface.wallets.conversion_form_v2.rate_type.fixed_tooltip'
                      : 'surface.wallets.conversion_form_v2.rate_type.approximate_tooltip'
                  )}
                  iconColor="$interactive-01"
                  iconOpacity={0.3}
                  position="right"
                />
              </Box>
            }
            tooltipCaretPosition="left"
            tooltipOffset={-24}
            right={
              <Link onPress={handlePressRate}>
                1 {tickerFormatted} {rateIsFixed ? '=' : '≈'} {rateFormatted}{' '}
                {conversionTickerFormatted}
              </Link>
            }
          />
        </TouchableBox>
        <Box mb={20}>
          <ProgressBar
            key={rateTimeIntervalName}
            progress={rateTimeIntervalProgress}
          />
        </Box>
        <InformationTableRow
          testID="CONVERT_TABLE_FEE_ROW"
          left={
            <Text color="$text-02">
              {t('surface.wallets.conversion_form.table.fee')}
            </Text>
          }
          right={
            <Text color="$text-02">
              <Text color="$text-01" testID="CONVERT_TABLE_FEE_VALUE">
                {feeFormatted}
              </Text>{' '}
              {tickerFormatted}
            </Text>
          }
          isLast
        />
      </InformationTable>

      <RateModal
        ticker={ticker}
        conversionTicker={conversionTicker}
        tickerFormatted={tickerFormatted}
        conversionTickerFormatted={conversionTickerFormatted}
        isOpen={isRateModalOpen}
        onClose={() => setIsRateModalOpen(false)}
      />
    </>
  );
});
