import React from 'react';
import {cell} from '@youtoken/ui.cell';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Text, type BoxProps, useIsMobile, Box} from '@youtoken/ui.primitives';
import {NewReferralOverviewResource} from '@youtoken/ui.resource-new-referral';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {Button} from '@youtoken/ui.buttons';

type NewReferralBadgeProps = {};

export const NewReferralBadge: React.FC<NewReferralBadgeProps & BoxProps> =
  cell(boxProps => {
    const {t} = useTranslation();
    const isMobile = useIsMobile();
    const {
      overview: {
        new: newFriends,
        conditions: {percentFormatted},
      },
      available,
    } = NewReferralOverviewResource.use({});

    const handlePress = () => {
      SHARED_ROUTER_SERVICE.navigate('InviteFriends');
    };

    const content = React.useMemo(() => {
      return !isMobile ? (
        <>
          {t('surface.top_bar.referral_badge', {percent: percentFormatted})}
          {newFriends > 0 && (
            <Box
              backgroundColor="$attention-01"
              borderRadius={32}
              px={4}
              pb={1}
              ml={4}
              testID="NEW_REFERRAL_BADGE_COUNT"
            >
              <Text variant="$body-02-medium-accent" color="$text-04">
                +{newFriends}
              </Text>
            </Box>
          )}
        </>
      ) : null;
    }, [isMobile]);

    if (!available) {
      return null;
    }

    return (
      <Button
        icon="gift"
        size="small"
        onPress={handlePress}
        color="attention"
        type="secondary"
        ml={8}
        testID="MINER_NEW_REFERRAL_BADGE"
        {...boxProps}
      >
        {content}
      </Button>
    );
  });
