import * as React from 'react';
import {observer} from 'mobx-react';
import {Separator} from '@youtoken/ui.elements';
import {
  ResizableBoxWithOverlay,
  TradingViewModeSwitch,
  useResizableBox,
  AutoConvertChartSmartCell,
} from '@youtoken/ui.legacy-charts';
import {SmartTVChartContainerWeb} from '@youtoken/ui.trading-view-charts';
import {useColors} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {type AutoConvertRateModalChartProps} from './types';

export const AutoConvertRateModalChart: React.FC<AutoConvertRateModalChartProps> =
  observer(({fromTicker, toTicker, triggerPrice}) => {
    const [
      {
        size: {width, height},
      },
      bind,
    ] = useResizableBox();

    const [isTradingViewChartOpen, setIsTradingViewChartOpen] =
      React.useState(false);

    const handleTradingViewChartOpen = React.useCallback(() => {
      setIsTradingViewChartOpen(true);
    }, []);

    const handleTradingViewChartClose = React.useCallback(() => {
      setIsTradingViewChartOpen(false);
    }, []);

    const colors = useColors();
    const {t} = useTranslation();

    return (
      <ResizableBoxWithOverlay height={360} width="100%" {...bind}>
        {width &&
          height &&
          (isTradingViewChartOpen ? (
            <>
              <SmartTVChartContainerWeb
                fromTicker={fromTicker}
                toTicker={toTicker}
                mode="mid"
                // @ts-ignore
                screenName="Convert"
                height={300}
                triggerPriceLine={{
                  text: (
                    t('surface.auto_converts.desired_rate') as string
                  ).toUpperCase(),
                  price: triggerPrice,
                  bodyTextColor: colors['$interactive-01'],
                  lineColor: colors['$interactive-01'],
                }}
              />
              <Separator />
              <TradingViewModeSwitch
                mode="tradingview"
                onChange={handleTradingViewChartClose}
                pt={15}
                pb={16}
                px={24}
              />
            </>
          ) : (
            <AutoConvertChartSmartCell
              width={width}
              height={height}
              fromTicker={fromTicker}
              toTicker={toTicker}
              triggerPrice={triggerPrice}
              onPressTradingViewModeButton={handleTradingViewChartOpen}
            />
          ))}
      </ResizableBoxWithOverlay>
    );
  });
