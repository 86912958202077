import {i18n} from '@youtoken/ui.service-i18n';

type ItemName = 'tariff' | 'tariff_space';

export interface Item {
  name: ItemName;
  data?: any;
}

export const getBundlesSurfaceTitle = () => {
  return i18n.t('surface.bundles.title');
};

export const keyExtractor = (item: Item, index: number) => item.name + index;
