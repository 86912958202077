import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {type BoxProps, Box, Heading} from '@youtoken/ui.primitives';

export const BundleOperationsHeader: React.FC<BoxProps> = observer(boxProps => {
  const {t} = useTranslation();

  return (
    <Box testID="BUNDLE_OPERATIONS_HEADER" {...boxProps}>
      <Heading variant="$heading-02" color="$text-01">
        {t('surface.bundle.history')}
      </Heading>
    </Box>
  );
});
