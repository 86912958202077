import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {type BoxProps, Box, Text} from '@youtoken/ui.primitives';

export const BundlesInfo: React.FC<BoxProps> = observer(boxProps => {
  const {t} = useTranslation();

  return (
    <Box
      testID="BUNDLES_INFO"
      px={24}
      py={20}
      borderColor="$ui-01"
      borderWidth={1}
      borderRadius={16}
      {...boxProps}
    >
      <Text variant="$body-02" color="$text-02">
        {t('surface.bundles.info')}
      </Text>
    </Box>
  );
});
