import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {AutoConvertList} from '@youtoken/ui.surface-history-list';
import {InnerPageShell} from '@web-app/src/components/layout/Shell';
import {RouterResource} from '@web-app/src/components/routes/RouterResource';
import {WebPageWrapper} from '@youtoken/ui.screen-components';

export const AutoConvertsListPage: React.FC = observer(() => {
  const {t} = useTranslation();
  const {
    queryParams: {ticker},
  } = RouterResource.use({});

  const handleBackPress = React.useCallback(() => {
    SHARED_ROUTER_SERVICE.goBack();
  }, []);

  return (
    <InnerPageShell>
      <WebPageWrapper
        hasBackButton
        onBackPress={handleBackPress}
        windowTitle={t('surface.auto_convert.title.plural')}
        title={t('surface.auto_convert.title.plural')}
      >
        <AutoConvertList ticker={ticker} />
      </WebPageWrapper>
    </InnerPageShell>
  );
});
