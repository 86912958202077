import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, Text, TouchableBox} from '@youtoken/ui.primitives';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {Icon} from '@youtoken/ui.icons';
import {useTranslation} from '@youtoken/ui.service-i18n';

interface AutoConvertListButtonProps {
  ticker?: string;
}

export const AutoConvertListButton: React.FC<AutoConvertListButtonProps> =
  observer(({ticker}) => {
    const {t} = useTranslation();

    const handlePress = React.useCallback(() => {
      SHARED_ROUTER_SERVICE.navigate('AutoConvertsList', {ticker});
    }, [ticker]);

    return (
      <TouchableBox
        width="100%"
        backgroundColor="$ui-background"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        px={24}
        height={72}
        onPress={handlePress}
      >
        <Box flexDirection="row" alignItems="center">
          <Box mr={8}>
            <Icon name="ftp" size={20} />
          </Box>
          <Text variant="$body-01-high-accent">
            {t('surface.auto_convert.title')}
          </Text>
        </Box>
        <Icon name="chevron_right" size={20} />
      </TouchableBox>
    );
  });
