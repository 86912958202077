import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {
  BundleTariffTickersListItem,
  BundlePortfolioTickersListItem,
} from '@youtoken/ui.resource-bundles';
import {
  type BoxProps,
  type TextProps,
  Box,
  Text,
} from '@youtoken/ui.primitives';

interface BundleTickersHeaderProps {
  variant?: TextProps['variant'];
  items: Array<BundleTariffTickersListItem | BundlePortfolioTickersListItem>;
}

export const BundleTickersHeader: React.FC<
  BundleTickersHeaderProps & BoxProps
> = observer(({variant = '$heading-02', items, ...boxProps}) => {
  const {t} = useTranslation();

  return (
    <Box
      testID="BUNDLE_TICKERS_HEADER"
      flexDirection="row"
      justifyContent="space-between"
      {...boxProps}
    >
      <Text testID="PRIMARY" variant={variant} color="$text-01">
        {t('surface.bundle.tickers')}
      </Text>
      <Text testID="SECONDARY" variant={variant} color="$text-03">
        {t('surface.bundle.tickers_assets', {count: items.length})}
      </Text>
    </Box>
  );
});
