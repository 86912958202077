import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, Text} from '@youtoken/ui.primitives';
import {useForm} from '@youtoken/ui.form-elements';
import {
  AccountLevelProgressBarVariant,
  AccountLevelProgressWidgetV2,
} from '@youtoken/ui.surface-account-levels';
import {QuestionTooltip} from '@youtoken/ui.tooltip';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {
  ExchangeFormConversionPair,
  ExchangeFormContentWrap,
} from '../../ConvertBase';
import {AutoConvertDesiredRate} from './DesiredRate';
import {AutoConvertInfoTable} from './InfoTable';
import {AutoConvertState} from '../state';

interface AutoConvertFormContentProps {
  initialAmount?: number;
}

export const AutoConvertFormContent: React.FC<AutoConvertFormContentProps> =
  observer(({initialAmount}) => {
    const {
      form: {volumeForecast, sparksAmount},
    } = useForm<AutoConvertState>();

    const {t} = useTranslation();

    return (
      <ExchangeFormContentWrap>
        <Box flex={1} p={24} zIndex={1}>
          <ExchangeFormConversionPair
            withFastHandlers={false}
            initialAmount={initialAmount}
          />
          <AutoConvertDesiredRate mt={24} />
          <Box flexDirection="row" alignItems="center" mt={24}>
            <Text variant="$body-01-high-accent" mr={4}>
              {t('surface.auto_converts.form.level')}
            </Text>
            <QuestionTooltip
              content={t('surface.auto_converts.form.loyalty_tooltip')}
            />
          </Box>
          <AccountLevelProgressWidgetV2
            variant={AccountLevelProgressBarVariant.EXCHANGE}
            volume={volumeForecast}
            sparks={sparksAmount}
          />
          <AutoConvertInfoTable />
        </Box>
      </ExchangeFormContentWrap>
    );
  });
