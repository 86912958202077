import * as React from 'react';
import {Platform} from 'react-native';

const FormContext = React.createContext<any>({});

interface GenericForm {
  form: {
    instance: {
      $: (path: string) => {
        value: any;
        onChange: (...args: any[]) => void;
        error: null | Object;
      };
      errors: () => any;
      submitting: boolean;
      onSubmit: (e: any) => void;
    };
    dispose?: () => void;
  };
  view?: any;
}

export const Form: React.FC<React.PropsWithChildren<{form: any}>> = ({
  form,
  children,
}) => {
  return (
    <FormContext.Provider value={form}>
      {Platform.OS === 'web' ? (
        <form
          style={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            height: '100%',
          }}
        >
          {children}
        </form>
      ) : (
        children
      )}
    </FormContext.Provider>
  );
};

export const useForm = <T extends GenericForm = GenericForm>(): T => {
  return React.useContext<T>(FormContext);
};

export function makeForm<T extends GenericForm>(initForm: () => T) {
  const form = React.useRef<T | null>(null);

  if (!form.current) {
    form.current = initForm();
  }

  React.useEffect(() => {
    return () => {
      return form.current?.form?.dispose?.();
    };
  }, [form.current]);

  return form.current;
}
