import * as React from 'react';
import {useTheme} from '@youtoken/ui.primitives';
import {
  useSafeAreaInsets,
  initialWindowMetrics,
} from 'react-native-safe-area-context';

export const ScreenInsetsContext = React.createContext({
  top: initialWindowMetrics?.insets.top ?? 0,
  right: initialWindowMetrics?.insets.right ?? 0,
  bottom: initialWindowMetrics?.insets.bottom ?? 0,
  left: initialWindowMetrics?.insets.left ?? 0,
});

export function ScreenInsetsContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const insets = useSafeAreaInsets();
  const theme = useTheme();

  const value = React.useMemo(
    () => ({
      top: Math.max(insets.top, theme.spacing['$gap-l']),
      bottom: Math.max(insets.bottom, theme.spacing['$gap-l']),
      right: insets.right,
      left: insets.left,
    }),
    [insets, theme]
  );

  return (
    <ScreenInsetsContext.Provider value={value}>
      {children}
    </ScreenInsetsContext.Provider>
  );
}

export function useScreenInsets() {
  return React.useContext(ScreenInsetsContext);
}
