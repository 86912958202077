import * as React from 'react';
import {observer} from 'mobx-react';
import {BundlePortfolioTickersListItem} from '@youtoken/ui.resource-bundles';
import {type BoxProps, Box} from '@youtoken/ui.primitives';
import {BundleTickersHeader} from '..';
import {BundleTickersListItem} from '.';

interface BundleTickersListProps {
  items: BundlePortfolioTickersListItem[];
}

const renderItem = (item: BundlePortfolioTickersListItem) => {
  return <BundleTickersListItem key={item.ticker} item={item} />;
};

export const BundleTickersList: React.FC<BundleTickersListProps & BoxProps> =
  observer(({items, ...boxProps}) => {
    return (
      <Box testID="BUNDLE_TICKERS_LIST" gap={16} {...boxProps}>
        <BundleTickersHeader variant="$body-01-high-accent" items={items} />
        <Box>{items.map(renderItem)}</Box>
      </Box>
    );
  });
