import * as React from 'react';
import {observer} from 'mobx-react';
import {BundleTariff} from '@youtoken/ui.resource-bundles';
import {type BoxProps} from '@youtoken/ui.primitives';
import {
  BundlesTariffCard,
  BundlesTariffSummary,
  BundlesTariffChart,
} from './components';

export interface BundlesTariffProps {
  data: BundleTariff;
}

export const BundlesTariff: React.FC<BundlesTariffProps & BoxProps> = observer(
  ({data, ...boxProps}) => {
    return (
      <BundlesTariffCard data={data} {...boxProps}>
        <BundlesTariffSummary flex={1} data={data} px={20} py={16} />
        <BundlesTariffChart chartData={data.chartData} />
      </BundlesTariffCard>
    );
  }
);
