import * as React from 'react';
import {Box, Text} from '@youtoken/ui.primitives';
import {HistoryListSection} from '@youtoken/ui.resource-history-new';
import {AutoConvertListSection} from '../../../AutoConverts';

interface SectionHeaderProps {
  section: HistoryListSection | AutoConvertListSection;
}

export const SectionHeader = ({section}: SectionHeaderProps) => {
  return (
    <Box
      backgroundColor="$ui-overflow"
      width="100%"
      height={40}
      px={24}
      py={12}
      justifyContent="space-between"
      flexDirection="row"
    >
      <Text variant="$body-01" color="$text-01">
        {section.title}
      </Text>
    </Box>
  );
};
