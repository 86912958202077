import * as React from 'react';
import {observer} from 'mobx-react';
import {MinerFeature} from '@youtoken/ui.resources-miner';
import {Box} from '@youtoken/ui.primitives';
import {
  HexGrid,
  Overview,
  ReferralBanner,
  LevelsNew,
  AccountLevelInfo,
  NextAction,
} from '../components';
import {HowItWorksBanner} from './HowItWorksBanner';

export const MinerSurface: React.FC = observer(() => {
  const {
    resources: {
      authMe: {
        data: {
          clientSettings: {hasSeenMinerUpgradeModalFirstTime},
        },
        newReferralEnabled,
        setHasSeenMinerUpgradeModalFirstTime,
      },
      loyalty: {
        data: {currentLevel},
      },
      minerOverview: {
        hexagonalGrid: {navigateToGetMoreSparks},
      },
    },
  } = MinerFeature.use({});

  React.useEffect(() => {
    if (currentLevel >= 3 && !hasSeenMinerUpgradeModalFirstTime) {
      setHasSeenMinerUpgradeModalFirstTime();
      navigateToGetMoreSparks('mount');
    }
  }, [
    currentLevel,
    hasSeenMinerUpgradeModalFirstTime,
    setHasSeenMinerUpgradeModalFirstTime,
    navigateToGetMoreSparks,
  ]);

  return (
    <>
      <Box
        mx={{default: 20, desktop: 0}}
        mt={{default: 16, desktop: 0}}
        mb={20}
        borderColor="$ui-03"
        borderWidth={{default: 0, tablet: 1}}
        borderRadius={16}
      >
        <Box
          alignItems="center"
          width="100%"
          p={{default: 0, tablet: 20}}
          pt={{default: 0, tablet: 16}}
          pb={{default: 16, tablet: 56}}
          borderColor="$ui-01"
        >
          {newReferralEnabled && <ReferralBanner mb={16} mt={-16} />}

          <Overview zIndex={1} px={0} />

          <HexGrid />

          <NextAction />
        </Box>
      </Box>

      <HowItWorksBanner
        mb={{default: 20, tablet: 0}}
        mx={{default: 20, desktop: 0}}
        px={20}
        pb={20}
        borderColor="$ui-03"
        borderRadius={16}
      />

      <Box
        borderColor="$ui-03"
        borderRadius={16}
        borderWidth={{default: 1, tablet: 0}}
        mb={{default: 20, tablet: 0}}
        mx={{default: 20, desktop: 0}}
        px={{default: 20, tablet: 0}}
        py={{default: 16, tablet: 20}}
      >
        <LevelsNew
          flexDirection="column"
          borderRadius={16}
          borderColor="$ui-03"
          borderWidth={{default: 0, tablet: 1}}
          p={{default: 0, tablet: 20}}
          pb={{default: 0, tablet: 10}}
        />

        <AccountLevelInfo
          mt={{default: 16, tablet: 20}}
          px={{default: 0, tablet: 20}}
          py={{default: 0, tablet: 20}}
          mb={4}
          borderWidth={{default: 0, tablet: 1}}
        />
      </Box>
    </>
  );
});
