import * as React from 'react';
import {observer} from 'mobx-react';
import {SubmitButton, useForm} from '@youtoken/ui.form-elements';
import PlatformPayment from '@youtoken/ui.platform-payment';
import {Box, type BoxProps} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {useWizard} from '@youtoken/ui.elements';
import {Button} from '@youtoken/ui.buttons';
import {ExchangeFormAgreeToTerms} from '../../ConvertBase';
import {
  BuyCryptoFormAuthorizedOnRampState,
  BuyCryptoFormAuthorizedYouHodlerState,
} from '../state';
import type {BuyCryptoFormAuthorizedOnRampWizardNavigatorParams} from './Authorized';
import {DepositMethodRampEnum} from '../types';

export interface FormFooterProps {
  showAgreements?: boolean; // NOTE: only for authorised users
  onPressSubmit?: () => void; // NOTE: only for authorised Frames method
}

export const FormFooter: React.FC<FormFooterProps & BoxProps> = observer(
  ({showAgreements = true, onPressSubmit, ...boxProps}) => {
    const {t} = useTranslation();

    const {
      form: {
        instance,
        ticker,
        depositMethod,
        depositMethodType,
        checkIsLoading,
        rateTimeLeft,
      },
      view: {convertActionText},
    } = useForm<
      BuyCryptoFormAuthorizedOnRampState | BuyCryptoFormAuthorizedYouHodlerState
    >();

    const {navigate} = useWizard<
      BuyCryptoFormAuthorizedOnRampWizardNavigatorParams,
      'Main'
    >();

    const BuyCryptoSubmitButton = React.useMemo(() => {
      if (
        depositMethod === DepositMethodRampEnum.APPLE_PAY &&
        PlatformPayment.canMakePayments()
      ) {
        return (
          <Button
            onPress={instance.onSubmit}
            disabled={instance.submitting}
            loading={instance.submitting}
            domType="submit"
            size="large"
            borderRadius={6}
            icon="os_mac"
            color="apple-pay"
            testID="APPLE_PAY_DEPOSIT_SUBMIT_BUTTON"
          >
            {t('surface.apple_pay.deposit.components.submit.default')}
          </Button>
        );
      }

      if (depositMethodType === 'later') {
        return (
          <SubmitButton
            testID="CONVERSION_SUBMIT_BUTTON"
            onPress={() => {
              navigate('Later', {
                method: depositMethod,
                ticker,
              });
            }}
          >
            {t('ramp.conversion_form.action.submit_deposit')}
          </SubmitButton>
        );
      }

      return (
        <SubmitButton
          testID="CONVERSION_SUBMIT_BUTTON"
          loading={checkIsLoading}
          onPress={onPressSubmit}
        >
          {convertActionText} ({rateTimeLeft})
        </SubmitButton>
      );
    }, [
      depositMethod,
      depositMethodType,
      ticker,
      navigate,
      checkIsLoading,
      onPressSubmit,
      convertActionText,
      rateTimeLeft,
    ]);

    return (
      <Box
        backgroundColor="$ui-background"
        p={24}
        borderRadius={10}
        overflow="hidden"
        {...boxProps}
      >
        {showAgreements && <ExchangeFormAgreeToTerms mb={16} />}
        {BuyCryptoSubmitButton}
      </Box>
    );
  }
);
