import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {BaseErrorFallback} from './BaseFallback';
import {Handler} from './Handler';
import {ErrorFallbackProps} from './types';

export const NetworkErrorFallback: React.FC<ErrorFallbackProps> = observer(
  props => {
    const {t} = useTranslation();

    return (
      <BaseErrorFallback
        text={t('common.errors.offline')}
        iconName="network_error"
        hint={t('common.errors.offline.hint')}
        {...props}
      >
        <Handler onPress={props.dismissError}>
          {t('surface.error.retry')}
        </Handler>
      </BaseErrorFallback>
    );
  }
);
