import * as React from 'react';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';

export const useBundleWelcomePage = () => {
  const {bundleWelcomePageEnabled, setBundleWelcomePageEnabled} =
    AuthMeResource.use({});

  const handleComplete = React.useCallback(() => {
    setBundleWelcomePageEnabled(false);
  }, [setBundleWelcomePageEnabled]);

  return {bundleWelcomePageEnabled, handleComplete};
};
