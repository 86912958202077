import * as React from 'react';
import {observer} from 'mobx-react';
import {Trans, useTranslation} from '@youtoken/ui.service-i18n';
import {Box, type BoxProps, Text} from '@youtoken/ui.primitives';
import {formatPercent, toBig} from '@youtoken/ui.formatting-utils';
import {FeatureTurboCreateForm} from '../stores';
import {InformationTableRow} from '@youtoken/ui.information-table';

export const CalculatedTable: React.FC<BoxProps> = observer(props => {
  const {t} = useTranslation();

  const {calculated} = FeatureTurboCreateForm.use({});

  return (
    <Box opacity={calculated.ready ? 1 : 0.6} {...props}>
      <Box p={20}>
        <InformationTableRow
          left={t('surface.loans.item.table.ltv')}
          right={
            calculated.data && (
              <Text variant="$body-02" color="$text-02">
                <Text variant="$body-02-high-accent" color="$text-01">
                  {formatPercent(calculated.data.ltv)}
                </Text>{' '}
                %
              </Text>
            )
          }
        />
        <InformationTableRow
          left={t('surface.loans.item.table.pdl')}
          right={
            calculated.data && (
              <Text variant="$body-02" color="$text-02">
                <Text variant="$body-02-high-accent" color="$text-01">
                  {formatPercent(toBig(calculated.data.pdl).abs())}
                </Text>{' '}
                %
              </Text>
            )
          }
        />
        <InformationTableRow
          left={t('surface.loans.item.table.borrowed')}
          right={
            calculated.data && (
              <Text variant="$body-02" color="$text-02">
                <Text variant="$body-02-high-accent" color="$text-01">
                  {calculated.data.borrowedAmount}
                </Text>{' '}
                {calculated.data.borrowedTicker.toUpperCase()}
              </Text>
            )
          }
        />

        <InformationTableRow
          left={t('surface.loans.item.table.repayment')}
          right={
            calculated.data && (
              <Text variant="$body-02" color="$text-02">
                <Text variant="$body-02-high-accent" color="$text-01">
                  {calculated.data.overdraftAmount}
                </Text>{' '}
                {calculated.data.borrowedTicker.toUpperCase()}
              </Text>
            )
          }
        />

        <InformationTableRow
          left={t('surface.loans.item.table.term')}
          // TODO: There is tooltip in list, but there isn't it in create form. Is there it in create form?
          // tooltipText={
          //   calculated.data &&
          //   t('surface.loans.item.table.term_hint', {
          //     days: calculated.data.days,
          //   })
          // }

          right={
            calculated.data && (
              <Text variant="$body-02" color="$text-01">
                <Trans
                  t={t}
                  i18nKey="surface.loans.item.table.term_value"
                  components={{
                    Strong: (
                      <Text variant="$body-02-high-accent" color="$text-01" />
                    ),
                  }}
                  values={{days: calculated.data.days}}
                />
              </Text>
            )
          }
        />
        <InformationTableRow
          left={t('surface.loans.item.table.repay_until')}
          right={
            calculated.data && (
              <Text variant="$body-02" color="$text-02">
                {calculated.data.repayUntil}
              </Text>
            )
          }
        />
      </Box>
    </Box>
  );
});
