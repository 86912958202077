import {
  action,
  autorun,
  computed,
  IReactionDisposer,
  observable,
  reaction,
} from 'mobx';
//@ts-ignore
import MobxReactForm from 'mobx-react-form';
//@ts-ignore
import yupValidator from 'mobx-react-form/lib/validators/YUP';
import * as yupPackage from '@youtoken/ui.yup';
import {TRANSPORT} from '@youtoken/ui.transport';
import {formatByTicker, toBig} from '@youtoken/ui.formatting-utils';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {
  getTranslatedValidationMessage,
  messages,
} from '@youtoken/ui.validation-messages';
import {normalizeAmountByTicker} from '@youtoken/ui.normalizers';
import {handleFormSubmitError} from '@youtoken/ui.form-utils';
import {
  BundleDepositFormStateArgs,
  BundleDepositFormStateResources,
} from './types';
import {Calculated} from './Calculated';
import {i18n} from '@youtoken/ui.service-i18n';
import {debounce} from 'lodash';

export class Form {
  @observable
  public args: {};

  @observable
  public resources: BundleDepositFormStateResources;

  @observable
  public instance: MobxReactForm;

  @observable
  public calculated: Calculated;

  @observable
  public disposers: IReactionDisposer[] = [];

  @computed
  public get rates() {
    return this.resources.rates;
  }

  @computed
  public get wallets() {
    return this.resources.wallets;
  }

  @computed
  public get wallet() {
    return this.wallets.getByTicker(this.ticker)!;
  }

  @computed
  public get bundleTariffOverview() {
    return this.resources.bundleTariffOverview;
  }

  // TARIFF

  @computed
  public get tariff() {
    return this.bundleTariffOverview.data.tariff;
  }

  @computed
  public get tariffId() {
    return this.tariff.id;
  }

  @computed
  public get tariffConditions() {
    return this.tariff.conditions;
  }

  @computed
  public get tariffConditionsBuyTickers() {
    return this.tariffConditions.buyTickers;
  }

  @computed
  public get tariffAmountPreset() {
    const presets = [];

    (this.tariff.conditions.presets[this.ticker] ?? []).forEach(amount => {
      presets.push({
        label: amount,
        amountBaseCurrency: this.ticker,
        amountEquivalent: amount.toString(),
      });
    });

    presets.push({
      label: i18n.t('surface.bundles.deposit_form.amount_all'),
      amountBaseCurrency: this.wallet?.ticker,
      amountEquivalent: this.wallet?.amount.toString(),
    });

    return presets;
  }

  // userPortfolio

  @computed
  public get userPortfolio() {
    return this.bundleTariffOverview.data.userPortfolio;
  }

  @computed
  public get userPortfolioId() {
    return this.userPortfolio?.id;
  }

  // source start

  @computed
  public get tickerItems() {
    return this.wallets.walletsListWithAmountOrder
      .filter(({ticker}) => {
        return this.tariffConditionsBuyTickers.includes(ticker);
      })
      .map(({tickerName, ticker, tickerFormatted, amountFormatted}) => {
        return {
          hasAmount: true,
          ticker: ticker,
          tickerFormatted: tickerFormatted,
          amountFormatted: amountFormatted,
          coinName: tickerName,
          key: `${ticker} ${tickerName}`,
        };
      });
  }

  @computed
  public get ticker() {
    return this.instance?.$('ticker').get('value');
  }

  @computed
  public get tickerFormatted() {
    return this.ticker.toUpperCase();
  }

  @computed
  public get conversionTicker() {
    return 'usd';
  }

  @computed
  public get conversionTickerFormatted() {
    return this.conversionTicker.toUpperCase();
  }

  @computed
  public get rate() {
    return this.rates.getRate(this.ticker, this.conversionTicker);
  }

  @computed
  public get amount() {
    return this.instance?.$('amount').get('value');
  }

  @computed
  public get conversionAmount() {
    return formatByTicker(
      toBig(this.rate).mul(toBig(this.amount)),
      this.conversionTicker
    );
  }

  @computed
  public get sourceError() {
    return getTranslatedValidationMessage(
      this.instance.$('ticker').get('error') ??
        this.instance.$('amount').get('error')
    );
  }

  @computed
  public get sourceHasError() {
    return Boolean(this.sourceError);
  }

  @computed
  public get tickerOnChange() {
    return this.instance.$('ticker').get('onChange');
  }

  @computed
  public get amountOnChange() {
    return this.instance.$('amount').get('onChange');
  }

  // source end

  @computed
  public get resultAmount() {
    const res = toBig(this.amount).minus(toBig(this.calculated.data?.fee));

    if (res.gte(0)) {
      return res;
    }

    return toBig(0);
  }

  @computed
  public get resultAmountFormatted() {
    return formatByTicker(this.resultAmount, this.ticker);
  }

  //

  @computed
  get submitAction() {
    if (
      this.wallet.amount.gt(0) &&
      this.wallet.amount.gte(toBig(this.amount))
    ) {
      return 'deposit-bundle';
    }

    return 'deposit-wallet';
  }

  @action
  public submit = () => {
    if (this.submitAction === 'deposit-wallet') {
      DATA_LAYER.trackStrict('bundles-deposit-clicked', {
        tariffId: this.tariffId,
        quoteTicker: this.ticker,
        quoteAmount: this.amount,
      });

      if (this.wallet.isFiat) {
        SHARED_ROUTER_SERVICE.navigate('FiatDeposit', {
          ticker: this.ticker,
        });
      } else if (
        this.resources.authMe.checkProductAvailability('depositCrypto')
      ) {
        SHARED_ROUTER_SERVICE.navigate('CryptoDeposit', {
          ticker: this.ticker,
        });
      }

      return Promise.resolve();
    }

    DATA_LAYER.trackStrict('bundles-transaction-started', {
      transactionType: 'deposit',
      tariffId: this.tariffId,
      quoteTicker: this.ticker,
      quoteAmount: this.amount,
    });

    return TRANSPORT.API.post(`/v1/bundle/deposit`, {
      tariffId: this.tariffId,
      quoteTicker: this.ticker,
      quoteAmount: this.amount,
    })
      .then(() => {
        return this.bundleTariffOverview.refetch();
      })
      .then(() => {
        SHARED_ROUTER_SERVICE.navigate('__CloseModal');
      })
      .catch(e => {
        handleFormSubmitError(this.instance, e, {
          quoteAmount: 'amount',
        });
      });
  };

  public constructor(
    args: BundleDepositFormStateArgs,
    resources: BundleDepositFormStateResources
  ) {
    this.args = args;
    this.resources = resources;
    this.instance = new MobxReactForm(
      {
        fields: {
          ticker: {
            value: this.tickerItems[0]!.ticker,
          },
          amount: {
            value: '',
            handlers: {
              onChange:
                (field: any) =>
                (value = '') => {
                  field.set(
                    'value',
                    normalizeAmountByTicker(value, this.ticker)
                  );
                },
            },
          },
        },
      },
      {
        hooks: {
          onSuccess: () => {
            return this.submit();
          },
        },
        plugins: {
          yup: yupValidator({
            package: yupPackage,
            schema: (yup: typeof yupPackage) => {
              return yup.lazy(() => {
                const schema = {
                  ticker: yup.string(),
                  amount: yup.big(),
                } as any;

                if (this.wallet && this.submitAction === 'deposit-bundle') {
                  schema.amount
                    .gt(0)
                    .lte(this.wallet.amount, messages.FUNDS_INSUFFICIENT);
                }

                return yup.object().shape(schema);
              });
            },
          }),
        },
        options: {
          validateOnBlur: false,
          validateOnChange: false,
          validateOnChangeAfterSubmit: true,
          showErrorsOnReset: false,
        },
      }
    );
    this.calculated = new Calculated();

    this.disposers = [
      reaction(
        () => {
          return [this.tariffId, this.ticker, this.amount];
        },
        ([tariffId, quoteTicker, quoteAmount]) => {
          this.calculated.load({
            tariffId,
            quoteTicker,
            quoteAmount,
          });
        },
        {
          fireImmediately: true,
        }
      ),
    ];
  }

  @action
  public dispose = () => {
    this.disposers.forEach(disposer => {
      disposer();
    });
  };
}
