import React from 'react';
import {ModalDynamic, type ModalDynamicProps} from '@youtoken/ui.modal';
import {
  LoansListItemFeatureArgs,
  LoanIncreaseForm,
} from '@youtoken/ui.legacy-components';
import {useTranslation} from '@youtoken/ui.service-i18n';

interface ModalIncreaseLtvProps extends ModalDynamicProps {
  loan: LoansListItemFeatureArgs;
}

export const ModalIncreaseLtv: React.FC<ModalIncreaseLtvProps> = ({
  loan,
  ...props
}) => {
  const {t} = useTranslation();

  return (
    <ModalDynamic title={t('surface.loans.increase_form.title')} {...props}>
      <LoanIncreaseForm id={loan.id} />
    </ModalDynamic>
  );
};
