import {
  action,
  autorun,
  computed,
  IReactionDisposer,
  observable,
  reaction,
} from 'mobx';
import {debounce} from 'lodash';
//@ts-ignore
import MobxReactForm from 'mobx-react-form';
//@ts-ignore
import yupValidator from 'mobx-react-form/lib/validators/YUP';
import * as yupPackage from '@youtoken/ui.yup';
import {TRANSPORT} from '@youtoken/ui.transport';
import {formatByTicker, toBig} from '@youtoken/ui.formatting-utils';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {
  getTranslatedValidationMessage,
  messages,
} from '@youtoken/ui.validation-messages';
import {normalizeAmountByTicker} from '@youtoken/ui.normalizers';
import {handleFormSubmitError} from '@youtoken/ui.form-utils';
import {
  BundleWithdrawalFormStateArgs,
  BundleWithdrawalFormStateResources,
} from './types';
import {Calculated} from './Calculated';
import {i18n} from '@youtoken/ui.service-i18n';

export class Form {
  @observable
  public args: {};

  @observable
  public resources: BundleWithdrawalFormStateResources;

  @observable
  public instance: MobxReactForm;

  @observable
  public calculateWithAllAmount = false;

  @action
  public setCalculateWithAllAmount = (value: boolean) => {
    this.calculateWithAllAmount = value;
  };

  @observable
  public calculated: Calculated;

  @observable
  public disposers: IReactionDisposer[] = [];

  // WALLETS

  @computed
  public get wallets() {
    return this.resources.wallets;
  }

  @computed
  public get wallet() {
    return this.wallets.getByTicker(this.ticker)!;
  }

  @computed
  public get bundleTariffOverview() {
    return this.resources.bundleTariffOverview;
  }

  // TARIFF

  @computed
  public get tariff() {
    return this.bundleTariffOverview.data.tariff;
  }

  @computed
  public get tariffId() {
    return this.tariff.id;
  }

  @computed
  public get tariffConditions() {
    return this.tariff.conditions;
  }

  @computed
  public get tariffConditionsSellTickers() {
    return this.tariffConditions.sellTickers;
  }

  @computed
  public get userPortfolio() {
    return this.bundleTariffOverview.data.userPortfolio;
  }

  @computed
  public get userPortfolioId() {
    return this.userPortfolio!.id;
  }

  // source start

  @computed
  public get tickerItems() {
    return this.wallets.walletsListWithAmountOrder
      .filter(({ticker}) => {
        return this.tariffConditionsSellTickers.includes(ticker);
      })
      .map(({tickerName, ticker, tickerFormatted, amountFormatted}) => {
        return {
          hasAmount: true,
          ticker: ticker,
          tickerFormatted: tickerFormatted,
          amountFormatted: amountFormatted,
          coinName: tickerName,
          key: `${ticker} ${tickerName}`,
        };
      });
  }

  @computed
  public get ticker() {
    return this.instance?.$('ticker').get('value');
  }

  @computed
  public get tickerFormatted() {
    return this.ticker.toUpperCase();
  }

  @computed
  public get amount() {
    return this.instance?.$('amount').get('value');
  }

  @computed
  public get sourceInfo() {
    if (!this.calculated.data) {
      return '';
    }

    return i18n.t('surface.bundles.withdrawal_form.amount_info', {
      ticker: this.calculated.data?.tickerFormatted,
      remainingAmount: this.calculated.data?.afterWithdrawalBalanceFormatted,
      minAmount: this.minAmountFormatted,
    });
  }

  @computed
  public get sourceError() {
    return getTranslatedValidationMessage(
      this.instance.$('ticker').get('error') ??
        this.instance.$('amount').get('error')
    );
  }

  @computed
  public get sourceHasError() {
    return Boolean(this.sourceError);
  }

  @computed
  public get tickerOnChange() {
    return this.instance.$('ticker').get('onChange');
  }

  @computed
  public get amountOnChange() {
    return this.instance.$('amount').get('onChange');
  }

  @action
  public amountSetTotal = () => {
    this.setCalculateWithAllAmount(true);
    this.instance
      .$('amount')
      .set('value', this.calculated.data!.totalBundleAmountFormatted);
  };

  // source end

  @computed
  public get resultAmount() {
    const res = toBig(this.amount).minus(toBig(this.calculated.data?.fee));

    if (res.gt(0)) {
      return res;
    }

    return toBig(0);
  }

  @computed
  public get resultAmountFormatted() {
    return formatByTicker(this.resultAmount, this.ticker);
  }

  @computed
  public get amountWithComission() {
    return toBig(this.amount).plus(toBig(this.calculated.data?.fee));
  }

  @computed
  public get amountWithComissionFormatted() {
    return formatByTicker(this.amount, this.ticker);
  }

  @computed
  public get minAmount() {
    return this.calculated.data?.minWithdrawal;
  }

  @computed
  public get minAmountFormatted() {
    return formatByTicker(this.minAmount, this.ticker);
  }

  @action
  public submit = () => {
    DATA_LAYER.trackStrict('bundles-transaction-started', {
      transactionType: 'withdraw',
      tariffId: this.tariffId,
      bundleId: this.userPortfolioId,
      quoteTicker: this.ticker,
      quoteAmount: this.amount,
    });

    return TRANSPORT.API.post(`/v1/bundle/withdraw`, {
      bundleId: this.userPortfolioId,
      quoteTicker: this.ticker,
      quoteAmount: this.amountWithComissionFormatted,
      withdrawAll:
        this.amount === this.calculated.data?.totalBundleAmountFormatted,
    })
      .then(() => {
        return this.bundleTariffOverview.refetch();
      })
      .then(() => {
        SHARED_ROUTER_SERVICE.navigate('__CloseModal');
      })
      .catch(e => {
        handleFormSubmitError(this.instance, e, {
          quoteAmount: 'amount',
        });
      });
  };

  public constructor(
    args: BundleWithdrawalFormStateArgs,
    resources: BundleWithdrawalFormStateResources
  ) {
    this.args = args;
    this.resources = resources;
    this.instance = new MobxReactForm(
      {
        fields: {
          ticker: {
            value: this.tickerItems[0]!.ticker,
            handlers: {
              onChange:
                (field: any) =>
                (value = '') => {
                  this.setCalculateWithAllAmount(false);
                  field.set('value', value);
                },
            },
          },
          amount: {
            value: '',
            handlers: {
              onChange:
                (field: any) =>
                (value = '') => {
                  this.setCalculateWithAllAmount(false);

                  field.set(
                    'value',
                    normalizeAmountByTicker(value, this.ticker)
                  );
                },
            },
          },
        },
      },
      {
        hooks: {
          onSuccess: () => {
            return this.submit();
          },
        },
        plugins: {
          yup: yupValidator({
            package: yupPackage,
            schema: (yup: typeof yupPackage) => {
              return yup.lazy(() => {
                let amountSchema = yup.big().gt(0);

                if (this.wallet) {
                  amountSchema.lte(
                    this.wallet.amount,
                    messages.FUNDS_INSUFFICIENT
                  );
                }

                const schema = {
                  ticker: yup.string(),
                  amount: amountSchema,
                };

                return yup.object().shape(schema);
              });
            },
          }),
        },
        options: {
          validateOnBlur: false,
          validateOnChange: false,
          validateOnChangeAfterSubmit: true,
          showErrorsOnReset: false,
        },
      }
    );

    this.calculated = new Calculated();

    this.disposers = [
      reaction(
        () => {
          return [
            this.userPortfolioId,
            this.ticker,
            this.amount,
            this.calculateWithAllAmount,
          ];
        },
        ([bundleId, quoteTicker, quoteAmount, withdrawAll]) => {
          this.calculated.load(
            {
              bundleId,
              quoteTicker,
              quoteAmount,
              withdrawAll,
            },
            data => {
              if (data.onlyWithdrawAll) {
                this.amountSetTotal();
              }
            }
          );
        },
        {
          fireImmediately: true,
        }
      ),
    ];
  }

  @action
  public dispose = () => {
    this.disposers.forEach(disposer => {
      disposer();
    });
  };
}
