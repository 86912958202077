import * as React from 'react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {type BoxProps, Box} from '@youtoken/ui.primitives';
import {Slider} from '@youtoken/ui.elements';
import {Button} from '@youtoken/ui.buttons';
import {
  BundlesSliderSlide1,
  BundlesSliderSlide2,
  BundlesSliderSlide3,
} from './components';

const data = [BundlesSliderSlide1, BundlesSliderSlide2, BundlesSliderSlide3];

const renderItem = ({item: Slide}: {item: (typeof data)[number]}) => {
  return <Slide />;
};

interface BundlesSliderProps {
  onComplete: () => void;
}

export const BundlesSlider: React.FC<BundlesSliderProps & BoxProps> = ({
  onComplete,
  ...boxProps
}) => {
  const {t} = useTranslation();

  const [currentIndex, setCurrentIndex] = React.useState(0);

  const handlePress = React.useCallback(() => {
    if (currentIndex < data.length - 1) {
      return setCurrentIndex(currentIndex + 1);
    }

    onComplete();
  }, [currentIndex, onComplete]);

  return (
    <Box testID="BUNDLES_SLIDER" gap={24} {...boxProps}>
      <Box flexGrow={1} height={450}>
        <Slider
          data={data}
          renderItem={renderItem}
          currentIndex={currentIndex}
          scrollEnabled={false}
          paginationShowChevrons={false}
        />
      </Box>
      <Button testID="ACTION_BUTTON" onPress={handlePress}>
        {t('surface.bundles.slider.continue')}
      </Button>
    </Box>
  );
};
