import * as React from 'react';
import {observer} from 'mobx-react';
import {BundlePortfolio} from '@youtoken/ui.resource-bundles';
import {type BoxProps, Box, Text} from '@youtoken/ui.primitives';

interface BundleSummaryPortfolioProps {
  data: BundlePortfolio;
}

export const BundleSummaryPortfolio: React.FC<
  BundleSummaryPortfolioProps & BoxProps
> = observer(
  ({
    data: {
      difference,
      quoteTickerFormatted,
      quoteAmountFormatted,
      differenceFormatted,
    },
    ...boxProps
  }) => {
    return (
      <Box
        testID="BUNDLE_SUMMARY_PORTFOLIO"
        alignItems="flex-end"
        {...boxProps}
      >
        <Text variant="$body-01" color="$text-01" textAlign="right">
          <Text testID="QUOTE_AMOUNT">{quoteAmountFormatted}</Text>{' '}
          <Text testID="QUOTE_TICKER" color="$text-02">
            {quoteTickerFormatted}
          </Text>
        </Text>
        <Text
          testID="DIFFERENCE"
          variant="$body-02"
          color={difference.gte(0) ? '$success-01' : '$danger-01'}
          textAlign="right"
        >
          {differenceFormatted}
        </Text>
      </Box>
    );
  }
);
