import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Box, Text, TouchableBox} from '@youtoken/ui.primitives';
import {
  InformationTable,
  InformationTableRow,
} from '@youtoken/ui.information-table';
import {useForm} from '@youtoken/ui.form-elements';
import {Icon} from '@youtoken/ui.icons';
import {Link} from '@youtoken/ui.elements';
import {AutoConvertRateModalChart} from './Chart';
import {RateModal} from '../../ConvertBase/components/RateModal';
import {type AutoConvertState} from '../state';

export const AutoConvertInfoTable: React.FC = observer(() => {
  const {t} = useTranslation();

  const {
    form: {
      rateIsFixed,
      desiredRateUnreversedFormatted,
      ticker,
      conversionTicker,
      desiredRateValue,
    },
    view: {tickerFormatted, conversionTickerFormatted, feeFormatted},
  } = useForm<AutoConvertState>();

  const [isRateModalOpen, setIsRateModalOpen] = React.useState(false);

  const handlePressRate = React.useCallback(() => {
    setIsRateModalOpen(true);
  }, []);

  const handleCloseRateModal = React.useCallback(() => {
    setIsRateModalOpen(false);
  }, []);

  return (
    <>
      <InformationTable mt={24} py={0}>
        <TouchableBox onPress={handlePressRate}>
          <InformationTableRow
            testID="CONVERT_TABLE_RATE_ROW"
            left={
              <Box flexDirection="row">
                <Icon color="$interactive-01" name="hodl" />
                <Link ml={2} onPress={handlePressRate}>
                  {t('surface.auto_converts.desired_rate')}
                </Link>
              </Box>
            }
            tooltipCaretPosition="left"
            tooltipOffset={-24}
            right={
              <Link onPress={handlePressRate}>
                1 {tickerFormatted} {rateIsFixed ? '=' : '≈'}{' '}
                {desiredRateUnreversedFormatted} {conversionTickerFormatted}
              </Link>
            }
          />
        </TouchableBox>
        <InformationTableRow
          testID="CONVERT_TABLE_FEE_ROW"
          left={
            <Text color="$text-02">
              {t('surface.wallets.conversion_form.table.fee')}
            </Text>
          }
          right={
            <Text color="$text-02">
              <Text color="$text-01" testID="CONVERT_TABLE_FEE_VALUE">
                {feeFormatted}
              </Text>{' '}
              {tickerFormatted}
            </Text>
          }
          isLast
        />
      </InformationTable>

      <RateModal
        ticker={ticker}
        conversionTicker={conversionTicker}
        tickerFormatted={tickerFormatted}
        conversionTickerFormatted={conversionTickerFormatted}
        isOpen={isRateModalOpen}
        onClose={handleCloseRateModal}
        chartComponent={props => (
          <AutoConvertRateModalChart
            {...props}
            triggerPrice={desiredRateValue}
          />
        )}
      />
    </>
  );
});
