import * as React from 'react';
import {Box, type BoxProps} from '@youtoken/ui.primitives';
import {cell} from '@youtoken/ui.cell';
import {Form, makeForm} from '@youtoken/ui.form-elements';
import {getResourceDescriptor, useResources} from '@youtoken/ui.data-storage';
import {VerificationResource} from '@youtoken/ui.resource-verification';
import {WalletsResource} from '@youtoken/ui.resource-wallets';
import {MarketsResource} from '@youtoken/ui.resource-markets';
import {DocsResource} from '@youtoken/ui.resource-documents';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {RatesResource} from '@youtoken/ui.resource-rates';
import {ExchangeTariffsResource} from '@youtoken/ui.resource-exchange-tariffs';
import {AutoConvertResource} from '@youtoken/ui.resource-auto-convert';
import {AutoConvertFormContent, AutoConvertFormFooter} from './components';
import {AutoConvertState} from './state';

export interface AutoConvertProps {
  fromTicker: string;
  toTicker: string;
  initialAmount?: number;
}

export const AutoConvertForm: React.FC<AutoConvertProps & BoxProps> = cell(
  ({fromTicker, toTicker, initialAmount, ...boxProps}) => {
    const {residenceOrCountry} = AuthMeResource.use({});

    const resources = useResources({
      authMeResource: getResourceDescriptor(AuthMeResource, {}),
      verificationResource: getResourceDescriptor(VerificationResource, {}),
      docsResource: getResourceDescriptor(DocsResource, {
        country: residenceOrCountry,
      }),
      walletsResource: getResourceDescriptor(WalletsResource, {}),
      marketsResource: getResourceDescriptor(MarketsResource, {}),
      ratesResource: getResourceDescriptor(RatesResource, {}),
      exchangeTariffs: getResourceDescriptor(ExchangeTariffsResource, {}),
      autoConvertResource: getResourceDescriptor(AutoConvertResource, {}),
    });

    const state = makeForm(
      () =>
        new AutoConvertState(
          {
            initialAmount,
            ticker: fromTicker,
            conversionTicker: toTicker,
          },
          resources
        )
    );

    return (
      <Form form={state}>
        <Box flex={1} {...boxProps}>
          <AutoConvertFormContent initialAmount={initialAmount} />
          <AutoConvertFormFooter />
        </Box>
      </Form>
    );
  }
);
