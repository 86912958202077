import Big from 'big.js';
import {computed, observable} from 'mobx';
import {date, object, optional, primitive, serializable} from 'serializr';
import {formatBigNumber} from '@youtoken/ui.formatting-utils';
import {bigNumberNullable} from '@youtoken/ui.utils-serializr';
import {getCoinDecimalPrecision} from '@youtoken/ui.coin-utils';

class Payload {
  @observable
  @serializable(primitive())
  source!: string;
}

export class AutoConvertResponse {
  @observable
  @serializable(primitive())
  accountId!: string;

  @observable
  @serializable(date())
  createdAt!: Date;

  @observable
  @serializable(primitive())
  exchangeId!: string;

  @observable
  @serializable(optional(primitive()))
  executedRate: string | null = null;

  @observable
  @serializable(primitive())
  execution!: string;

  @observable
  @serializable(primitive())
  feeAmount!: number;

  @observable
  @serializable(optional(primitive()))
  fixedRate: string | null = null;

  @observable
  @serializable(primitive())
  fromAmount!: number;

  @observable
  @serializable(primitive())
  fromAmountWithoutFee!: number;

  @observable
  @serializable(primitive())
  fromTicker!: string;

  @observable
  @serializable(primitive())
  id!: string;

  @observable
  @serializable(object(Payload))
  payload!: Payload;

  @observable
  @serializable(primitive())
  source!: string;

  @observable
  @serializable(optional(bigNumberNullable()))
  rate!: Big | null;

  @observable
  @serializable(primitive())
  rateProduct!: string;

  @observable
  @serializable(optional(primitive()))
  resultFeeUSD: number | null = null;

  @observable
  @serializable(optional(primitive()))
  resultRateDiffUSD: number | null = null;

  @observable
  @serializable(optional(primitive()))
  resultUSD: number | null = null;

  @observable
  @serializable(primitive())
  status!: string;

  @observable
  @serializable(primitive())
  tariffId!: string;

  @observable
  @serializable(optional(primitive()))
  toAmount: number | null = null;

  @observable
  @serializable(primitive())
  toTicker!: string;

  @observable
  @serializable(bigNumberNullable())
  triggerRate!: Big;

  @computed
  get tickerPrecision() {
    return getCoinDecimalPrecision(this.toTicker, 8);
  }

  @computed
  get triggerRateFormatted() {
    return formatBigNumber(new Big(this.triggerRate), this.tickerPrecision);
  }

  @observable
  @serializable(primitive())
  type!: 'market' | 'limit';

  @observable
  @serializable(date())
  updatedAt!: Date;

  @observable
  @serializable(optional(primitive()))
  volumeUSD: number | null = null;

  @computed
  get fromTickerFormatted() {
    return this.fromTicker.toUpperCase();
  }

  @computed
  get toTickerFormatted() {
    return this.toTicker.toUpperCase();
  }
}
