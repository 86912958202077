import * as React from 'react';
import {useChartThemeContext} from '../../Providers';
import {DashedLine} from '../../Primitives';
import {observer} from 'mobx-react';
import type {AutoConvertChartState, BaseHODLChartState} from '../../logic';

export const TriggerPriceLine: React.FC<{
  chartState: BaseHODLChartState | AutoConvertChartState;
  fullWidth?: boolean;
  color?: string;
}> = React.memo(
  observer(({chartState, fullWidth, color}) => {
    const {
      width,
      isCursorActive,
      triggerPriceLabel,
      triggerPriceNameLabel,
      direction,
    } = chartState;

    const {getColorByDirection} = useChartThemeContext();

    if (!triggerPriceLabel.layout || !triggerPriceLabel.layout.isInDomain) {
      return null;
    }

    return (
      <DashedLine
        stroke={color ?? getColorByDirection(direction)}
        x1={
          fullWidth
            ? 0
            : triggerPriceNameLabel.layout && !isCursorActive
            ? triggerPriceNameLabel.layout.width
            : 0
        }
        x2={fullWidth ? width : width - triggerPriceLabel.layout.width}
        y1={triggerPriceLabel.layout.y}
        y2={triggerPriceLabel.layout.y}
      />
    );
  })
);
