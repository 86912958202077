import * as React from 'react';
import {Text, Box, useIsMobile} from '@youtoken/ui.primitives';
import {Trans, useTranslation} from '@youtoken/ui.service-i18n';
import {AgreementsList, Alert} from '@youtoken/ui.elements';
import {Icon} from '@youtoken/ui.icons';
import {Button} from '@youtoken/ui.buttons';
import {cell, DefaultSuspenseFallback} from '@youtoken/ui.cell';
import {FeatureLoanFTPFormArgs, FeatureLoanFTPForm} from './state';
import {Form} from '..';
import {InformationTableRow} from '@youtoken/ui.information-table';
import {Ticker} from './components/Ticker';
import {ConversionTicker} from './components/ConversionTicker';

export interface LoanFTPFormProps extends FeatureLoanFTPFormArgs {
  onUnMount?: () => void;
}

export const LoanFTPForm: React.FC<LoanFTPFormProps> = cell(
  ({mode, id, onUnMount}) => {
    const {t} = useTranslation();

    const isMobile = useIsMobile();

    const feature = FeatureLoanFTPForm.use({
      mode,
      id,
    });

    React.useEffect(() => {
      feature.restore();

      return () => {
        onUnMount && onUnMount();
        feature.reset();
      };
    }, []);

    if (!feature.restored) {
      return <DefaultSuspenseFallback />;
    }

    if (!feature.loan) {
      return null;
    }

    return (
      <Form form={feature}>
        <Box flexGrow={1}>
          <Alert type="information">{t('surface.loans.ftp_form.alert')}</Alert>
          <Box p={24}>
            <Box flexDirection={isMobile ? 'column' : 'row'} mb={20} zIndex={1}>
              <Box
                flexGrow={1}
                flexShrink={0}
                flexBasis={isMobile ? 'auto' : 0}
                mb={isMobile ? 20 : 0}
                zIndex={2}
              >
                <Ticker />
              </Box>
              {!isMobile && (
                <Box mx={15} mt={47} flexDirection="row">
                  <Icon name="exchange" color="$text-02" />
                </Box>
              )}
              <Box
                flexGrow={1}
                flexShrink={0}
                flexBasis={isMobile ? 'auto' : 0}
                zIndex={1}
              >
                <ConversionTicker />
              </Box>
            </Box>
            <Box mb={20}>
              <Text variant="$body-02" color="$text-02" mb={5}>
                <Trans
                  t={t}
                  components={{
                    Strong: <Text variant="$body-02-high-accent" />,
                    StrongGreen: (
                      <Text
                        variant="$body-02-high-accent"
                        color="$success-01"
                      />
                    ),
                  }}
                  values={{
                    percent: feature.diff,
                    amount: feature.initialPrice,
                    ticker: feature.loanBorrowedTicker,
                  }}
                  i18nKey="surface.loans.ftp_form.ftp_from_initial"
                />
              </Text>
              {feature.form.loanFTP && (
                <Text variant="$body-02" color="$text-02" mb={5}>
                  <Trans
                    t={t}
                    components={{
                      Strong: <Text variant="$body-02-high-accent" />,
                    }}
                    values={{
                      amount: feature.currentFTP,
                      ticker: feature.loanBorrowedTicker,
                    }}
                    i18nKey="surface.loans.ftp_form.ftp_last"
                  />
                </Text>
              )}
              <Text variant="$body-02" color="$text-02">
                <Trans
                  t={t}
                  components={{
                    Strong: <Text variant="$body-02-high-accent" />,
                  }}
                  values={{
                    amount: feature.currentPrice,
                    ticker: feature.loanBorrowedTicker,
                  }}
                  i18nKey="surface.loans.ftp_form.price"
                />
              </Text>
            </Box>
            <Box opacity={feature.calculated.ready ? 1 : 0.7}>
              <Text variant="$body-01-high-accent" mb={15}>
                {t('surface.loans.ftp_form.table')}
              </Text>
              <Box>
                <InformationTableRow
                  left={t('surface.loans.ftp_form.table.collateral')}
                  right={
                    <Text variant="$body-02" color="$text-02">
                      <Text variant="$body-02-high-accent">
                        {feature.collateral}
                      </Text>{' '}
                      {feature.loanCollateralTicker}
                    </Text>
                  }
                />
                <InformationTableRow
                  left={t('surface.loans.ftp_form.table.repayment')}
                  right={
                    <Text variant="$body-02" color="$text-02">
                      <Text variant="$body-02-high-accent">
                        {feature.repayment}
                      </Text>{' '}
                      {feature.loanBorrowedTicker}
                    </Text>
                  }
                />
                {feature.hasServiceFee && (
                  <InformationTableRow
                    left={t('surface.loans.ftp_form.table.service_fee')}
                    right={
                      <Text variant="$body-02" color="$text-02">
                        <Text variant="$body-02-high-accent">
                          {feature.serviceFee}
                        </Text>{' '}
                        {feature.loanBorrowedTicker}
                      </Text>
                    }
                  />
                )}
              </Box>
            </Box>
          </Box>
        </Box>
        <Box>
          <Box
            bg="$ui-01"
            p={24}
            borderBottomLeftRadius={isMobile ? 0 : 10}
            borderBottomRightRadius={isMobile ? 0 : 10}
          >
            <Box gap={16} justifyContent="space-between">
              <Box
                mb={isMobile ? 20 : 0}
                visible={Boolean(feature.calculated.data)}
              >
                <Text
                  variant="$body-01"
                  lineHeight={28}
                  color="$text-02"
                  textAlign="left"
                  mb={5}
                >
                  <Trans
                    t={t}
                    i18nKey="surface.loans.ftp_form.ftp_new"
                    components={{
                      Strong: <Text variant="$heading-01" color="$text-01" />,
                    }}
                    values={{
                      amount: feature.newClosePrice,
                      ticker: feature.loanBorrowedTicker,
                    }}
                  />
                </Text>
                <Text variant="$body-02" color="$text-02" textAlign="left">
                  <Trans
                    t={t}
                    components={{
                      Strong: <Text variant="$body-02-high-accent" />,
                    }}
                    values={{
                      amount: feature.expectedReturn,
                      ticker: feature.loanCollateralTicker,
                    }}
                    i18nKey="surface.loans.ftp_form.expected_return"
                  />
                </Text>
              </Box>

              <AgreementsList
                items={feature.agreements}
                pr={20}
                beforeListText={t(
                  'agreements.documents.without_checkbox.start_text',
                  {call_to_action: t('surface.loans.ftp_form.submit')}
                )}
              />

              <Button
                size="large"
                minWidth={isMobile ? '100%' : 200}
                loading={feature.form.instance.submitting}
                onPress={feature.form.instance.onSubmit}
              >
                {t('surface.loans.ftp_form.submit')}
              </Button>
            </Box>
            <Box mt={16}>
              <Text variant="$body-02" color="$text-02" mb={10}>
                {t('surface.loans.ftp_form.info_precalculated')}
              </Text>
              <Text variant="$body-02" color="$text-02">
                {t('surface.loans.ftp_form.info_volatility')}
              </Text>
            </Box>
          </Box>
        </Box>
      </Form>
    );
  }
);
