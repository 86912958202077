import * as React from 'react';
import {observer} from 'mobx-react';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Box, Text} from '@youtoken/ui.primitives';
import {useForm, SubmitButton} from '@youtoken/ui.form-elements';
import type {BundleWithdrawalFormState} from '../../state';

export const Footer: React.FC = observer(() => {
  const {t} = useTranslation();

  const {
    form: {
      resultAmountFormatted,
      tickerFormatted,
      calculated: {loading},
    },
  } = useForm<BundleWithdrawalFormState>();

  const {bottom} = useSafeAreaInsets();

  return (
    <Box
      p={24}
      pb={bottom || 24}
      borderColor="$ui-01"
      borderTopWidth={1}
      gap={24}
    >
      <Box gap={16}>
        <Text testID="SUBMIT_INFO" variant="$body-02" color="$text-02">
          {t('surface.bundles.withdrawal_form.result', {
            ticker: tickerFormatted,
            amount: resultAmountFormatted,
          })}
        </Text>
      </Box>
      <SubmitButton loading={loading}>
        {t('surface.bundles.withdrawal_form.submit')}
      </SubmitButton>
    </Box>
  );
});
