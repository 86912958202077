import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, type BoxProps} from '@youtoken/ui.primitives';
import {Button} from '@youtoken/ui.buttons';
import {useTranslation} from '@youtoken/ui.service-i18n';

interface ConversionSourceButtonsProps {
  setAmount: (percent: number) => void;
  balance: number;
  breakpoints?: number[];
}

export const ConversionSourceButtons: React.FC<
  ConversionSourceButtonsProps & BoxProps
> = observer(
  ({breakpoints = [25, 50, 75, 100], balance, setAmount, ...boxProps}) => {
    const createSetAmount = (percent: number) =>
      React.useCallback(() => {
        setAmount(percent);
      }, [setAmount]);

    const {t} = useTranslation();

    return (
      <Box
        flex={1}
        flexDirection="row"
        justifyContent="space-between"
        gap={8}
        {...boxProps}
      >
        {breakpoints.map(percent => (
          <Button
            flexGrow={1}
            key={percent}
            type={balance === percent ? 'primary' : 'secondary'}
            size="tiny"
            onPress={createSetAmount(percent)}
            testID={`CONVERT_AMOUNT_BUTTON_${percent}`}
          >
            {percent === 100
              ? t('surface.convert.quick_action.all')
              : `${percent}%`}
          </Button>
        ))}
      </Box>
    );
  }
);
