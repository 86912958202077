import React from 'react';
import {observer} from 'mobx-react';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {Box, type BoxProps, Text} from '@youtoken/ui.primitives';
import {LogoColored, type LogoColoredIconName} from '@youtoken/ui.icons';
import {SavingsSummaryAprSmart} from '@youtoken/ui.surface-savings';
import {IBANBadge} from '@youtoken/ui.surface-bank-wire';
import {ItemProps} from '../../types';

export const Summary: React.FC<ItemProps & BoxProps> = observer(
  ({
    showSavings,
    variant = 'list',
    needActivatePersonalIBAN,
    item,
    ...boxProps
  }) => {
    const {
      ticker,
      amountFormatted,
      tickerFormatted,
      equivalentFormatted,
      equivalentTickerFormatted,
      isAmountPositive,
    } = item;

    const handleBadgePress = React.useCallback(() => {
      if (variant === 'detailed') {
        if (needActivatePersonalIBAN) {
          SHARED_ROUTER_SERVICE.navigate('ActivatePersonalIBAN', {ticker});
        } else {
          SHARED_ROUTER_SERVICE.navigate('FiatDepositBankWire', {ticker});
        }
      } else {
        SHARED_ROUTER_SERVICE.navigate('WalletsItem', {ticker});
      }
    }, [variant, ticker]);

    return (
      <Box flexDirection="row" alignItems="center" {...boxProps}>
        <Box mr={10}>
          <LogoColored name={ticker as LogoColoredIconName} size={32} />
        </Box>
        <Box>
          <Box flexDirection="row" alignItems="center">
            <Text
              variant={isAmountPositive ? '$body-01-high-accent' : '$body-01'}
              color={isAmountPositive ? '$text-01' : '$text-02'}
              testID={`${tickerFormatted}_BALANCE`}
            >
              {amountFormatted}{' '}
            </Text>
            <Text variant="$body-01" color="$text-02">
              {tickerFormatted}
            </Text>
            <IBANBadge ticker={ticker} onPress={handleBadgePress} />
          </Box>
          <Box
            flexDirection="row"
            justifyContent="flex-start"
            alignItems="center"
            width="100%"
          >
            {Boolean(equivalentFormatted) && (
              <Text
                variant="$body-02"
                color="$text-02"
                testID={`${ticker.toUpperCase()}_IN_${equivalentTickerFormatted}_AMOUNT`}
              >
                {equivalentFormatted} {equivalentTickerFormatted}
              </Text>
            )}
            {Boolean(showSavings) && <SavingsSummaryAprSmart item={item} />}
          </Box>
        </Box>
      </Box>
    );
  }
);
