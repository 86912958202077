import React from 'react';
import {observer} from 'mobx-react';
import {type HODLItem} from '@youtoken/ui.resource-hodl';
import {Box, Text} from '@youtoken/ui.primitives';
import {
  IconSmall,
  LogoColored,
  type LogoColoredIconName,
} from '@youtoken/ui.icons';
import {DirectionBadge} from './DirectionBadge';

interface HODLItemInitialPropertiesProps {
  hodl: HODLItem;
  showDirectionTitle?: boolean;
  testID?: string;
}

export const HODLItemInitialProperties: React.FC<HODLItemInitialPropertiesProps> =
  observer(
    ({
      hodl: {
        inputTickerFormatted,
        baseTicker,
        inputAmountFormattedByAmount,
        currencyPair,
        direction,
        multiplier,
      },
      showDirectionTitle = true,
      testID,
    }) => {
      return (
        <Box flexDirection="row" testID={testID}>
          <Box mr={10}>
            <LogoColored name={baseTicker as LogoColoredIconName} size={32} />
          </Box>
          <Box>
            <Box flexDirection="row">
              <Text variant="$body-01-high-accent" color="$text-01">
                {currencyPair.toUpperCase()}
              </Text>
            </Box>
            <Box
              flexDirection={{
                phone: 'column',
                tablet: 'row',
              }}
            >
              <Box flexDirection="row" mr={2}>
                <Text variant="$body-02" color="$text-02">
                  {inputAmountFormattedByAmount} {inputTickerFormatted}
                </Text>
              </Box>
              <Box flexDirection="row" alignItems="center">
                <DirectionBadge
                  showTitle={showDirectionTitle}
                  direction={direction}
                  mr={2}
                />
                <Box flexDirection="row" alignItems="center">
                  <IconSmall size={12} color="$text-02" name="remove" />
                  <Text
                    color="$text-02"
                    variant="$body-02-high-accent"
                    ml={1}
                    mr={5}
                  >
                    {multiplier}
                  </Text>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      );
    }
  );
