import {observable} from 'mobx';
import {i18n} from '@youtoken/ui.service-i18n';
import {
  InitialPriceLabel,
  TriggerPriceLabel,
  TriggerPriceNameLabel,
} from './Labels';
import {
  BaseHODLChartState,
  type BaseHODLChartStateProps,
} from './BaseHODLChartState';

export interface AutoConvertChartStateProps extends BaseHODLChartStateProps {
  triggerPrice: number;
}

export class AutoConvertChartState extends BaseHODLChartState {
  constructor(props: AutoConvertChartStateProps) {
    super(props);

    this.triggerPrice = props.triggerPrice;
    this.triggerPriceLabel = new TriggerPriceLabel(this);
    this.triggerPriceNameLabel = new TriggerPriceNameLabel(
      this,
      (i18n.t('surface.auto_converts.desired_rate') as string).toUpperCase()
    );
  }

  setFromProps(props: AutoConvertChartStateProps): void {
    super.setFromProps(props);
  }

  @observable initialPriceLabel!: InitialPriceLabel;

  @observable triggerPrice: number;
  @observable triggerPriceLabel: TriggerPriceLabel;
  @observable triggerPriceNameLabel: TriggerPriceNameLabel;
}
