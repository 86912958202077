import * as React from 'react';
import {TSpan} from 'react-native-svg';
import {computed} from 'mobx';
import {BaseHODLChartState} from '../BaseHODLChartState';
import {i18n} from '@youtoken/ui.service-i18n';

export class TriggerPriceNameLabel<
  ChartState extends BaseHODLChartState = BaseHODLChartState
> {
  constructor(
    protected chart: ChartState,
    labelText: string = i18n.t('charts.trigger_price')
  ) {
    this.labelText = labelText;
  }

  @computed get layout() {
    if (!this.chart.triggerPrice) {
      return undefined;
    }

    const text = this.labelText;
    const labelLayout = this.chart.layout.getLabelLayout(
      this.chart.triggerPrice,
      text,
      'left',
      this.chart.direction
    )!;

    return {
      ...labelLayout!,
      text: <TSpan>{text}</TSpan>,
    };
  }

  labelText: string;
}
