import * as React from 'react';
import {Helmet} from 'react-helmet';
import {
  Box,
  Heading,
  Text,
  TouchableBox,
  XStack,
  YStack,
} from '@youtoken/ui.primitives';
import {SurfaceDefinition} from '../../types';
import {ScreenInsetsContextProvider} from '../../ScreenInsets';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {Icon} from '@youtoken/ui.icons';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {observer} from 'mobx-react-lite';
import {cell} from '@youtoken/ui.cell';
import {z} from 'zod';
import {useParams} from 'react-router';
import {SurfaceParamsContextProvider} from '../../SurfaceParams';

const backIconCompensation = 4;

// this should basically be a self contained componen
// back press should be decided by router or context or something
const BackButton = observer(({onBackPress}: {onBackPress?: () => void}) => {
  const {t} = useTranslation();

  return (
    <TouchableBox
      onPress={SHARED_ROUTER_SERVICE.goBack}
      ml={{
        default: 16,
        phone: 20 - backIconCompensation,
        desktop: -backIconCompensation,
      }}
      testID="BACK_BUTTON"
    >
      <Box width="100%" flexDirection="row" alignItems="center">
        <Icon name="chevron_left" size={24} color="$text-05" />
        <Text color="$interactive-01" variant="$body-01-medium-accent">
          {t('common.actions.back')}
        </Text>
      </Box>
    </TouchableBox>
  );
});

const SurfaceWrapper = ({children}: {children: React.ReactNode}) => {
  return (
    <Box
      flex={1}
      borderWidth={1}
      borderColor="$ui-01"
      borderRadius={{
        default: 0,
        desktop: 10,
      }}
    >
      {children}
    </Box>
  );
};

export function webAppAdapter<
  Name extends string,
  ParamsSchema extends z.ZodObject<z.ZodRawShape>
>(surface: SurfaceDefinition<Name, ParamsSchema>): React.ComponentType<{}> {
  const CellComonent = cell(surface.component, {
    CellWrap: ({children}) => {
      return <Box flex={1}>{children}</Box>;
    },
  });

  return () => {
    const params = useParams<z.infer<ParamsSchema>>();

    return (
      <SurfaceParamsContextProvider params={params}>
        <ScreenInsetsContextProvider>
          <Helmet
            title={surface.getTitle(params)}
            titleTemplate="YouHodler | %s"
          />

          <YStack gap="$gap-l" flex={1}>
            <YStack gap="$gap-s">
              <BackButton onBackPress={SHARED_ROUTER_SERVICE.goBack} />

              <XStack
                px={{
                  default: '$screen-section',
                  desktop: 0,
                }}
              >
                <Heading variant="$heading-01-responsive" color="$text-01">
                  {surface.getTitle(params)}
                </Heading>
              </XStack>
            </YStack>

            <SurfaceWrapper>
              <CellComonent {...params} />
            </SurfaceWrapper>
          </YStack>
        </ScreenInsetsContextProvider>
      </SurfaceParamsContextProvider>
    );
  };
}
