import React from 'react';
import {ActivityIndicator} from '@youtoken/ui.elements';
import {cell} from '@youtoken/ui.cell';
import {Box, Text, TouchableBox} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {BankWireFeature} from '../../states/BankWireFeature';
import {Requisites} from '../Requisites';
import {AccountTypeSwitcher} from './components/AccountTypeSwitcher';
import {DepositVerifications} from './components/DepositVerifications';
import {IncentivesPercentInfo} from './components/IncentivesPercentInfo';
import {ActivatePersonalIBANBanner} from '../../ActivatePersonalIBANBanner';

export interface DepositRequisitesProps {
  ticker: string;
  hideIbanBanner?: boolean;
  onShowAlternativeRequisites: () => void;
}

export const DepositRequisites: React.FC<DepositRequisitesProps> = cell(
  ({ticker, onShowAlternativeRequisites}) => {
    const {
      isAlternativeRequisitesLinkAvailable,
      setAccountType,
      alternativeRequisitesLinkText,
      needActivatePersonalIBAN,
    } = BankWireFeature.use({ticker});
    const {t} = useTranslation();

    React.useEffect(() => {
      return () => {
        setAccountType(null);
      };
    }, []);

    return (
      <Box width="100%" height="100%">
        <DepositVerifications ticker={ticker} />
        <IncentivesPercentInfo px={24} pt={24} />
        <AccountTypeSwitcher ticker={ticker} />
        {needActivatePersonalIBAN && (
          <Box px="$screen-default-offset" pt="$gap-l">
            <ActivatePersonalIBANBanner ticker={ticker} />
          </Box>
        )}

        {isAlternativeRequisitesLinkAvailable &&
          alternativeRequisitesLinkText && (
            <TouchableBox
              px={24}
              pt={24}
              onPress={onShowAlternativeRequisites}
              testID="FIAT_DEPOSIT_BANK_WIRE_IBAN_ALTERNATIVE_REQUISITES"
            >
              <Text variant="$body-02" color="$text-05">
                {t(alternativeRequisitesLinkText)}
              </Text>
            </TouchableBox>
          )}

        <Requisites ticker={ticker} />
      </Box>
    );
  },
  {
    SuspenseFallback: () => (
      <Box width="100%" p={24} alignItems="center">
        <ActivityIndicator />
      </Box>
    ),
  }
);
