import * as React from 'react';
import {observer} from 'mobx-react';
import {BundleTariff} from '@youtoken/ui.resource-bundles';
import {type BoxProps, Box} from '@youtoken/ui.primitives';
import {
  ResizableBox,
  ChartSmallPreview,
  useResizableBox,
} from '@youtoken/ui.legacy-charts';

export interface BundlesTariffChartProps {
  chartData: BundleTariff['chartData'];
}

export const BundlesTariffChart: React.FC<BundlesTariffChartProps & BoxProps> =
  observer(({chartData, ...boxProps}) => {
    const [
      {
        size: {width, height},
      },
      bind,
    ] = useResizableBox();

    return (
      <Box testID="BUNDLES_TARIFF_CHART" height={48} {...boxProps}>
        <ResizableBox flex={1} {...bind}>
          {width && height && (
            <ChartSmallPreview
              // @ts-ignore TODO: check type
              data={chartData}
              width={width}
              height={height}
              gradient
            />
          )}
        </ResizableBox>
      </Box>
    );
  });
