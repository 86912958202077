import * as React from 'react';
import {FlatList} from 'react-native';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {cell} from '@youtoken/ui.cell';
import {useResponsive} from '@youtoken/ui.primitives';
import {WebPageWrapper} from '@youtoken/ui.screen-components';
import {BundlesSlider, BundlesInfo, BundlesTooltip} from './components';
import {getBundlesSurfaceTitle, keyExtractor} from './utils';
import {useBundleWelcomePage, useData, useRenderItem} from './hooks';

export {getBundlesSurfaceTitle} from './utils';

export {BundlesTooltip} from './components';

export const BundlesSurface: React.FC = cell(() => {
  const {bundleWelcomePageEnabled, handleComplete} = useBundleWelcomePage();

  return (
    <WebPageWrapper
      hasBackButton
      windowTitle={getBundlesSurfaceTitle()}
      title={getBundlesSurfaceTitle()}
      tooltip={<BundlesTooltip />}
      onBackPress={SHARED_ROUTER_SERVICE.goBack}
    >
      {bundleWelcomePageEnabled ? (
        <BundlesSlider
          px={{default: 16, phone: 24, desktop: 0}}
          onComplete={handleComplete}
        />
      ) : (
        <BundlesSurfaceTariffs />
      )}
    </WebPageWrapper>
  );
});

const BundlesSurfaceTariffs: React.FC = cell(() => {
  const numColumns = useResponsive({
    default: 1,
    desktop: 2,
  })!;

  const {items} = useData(numColumns);

  const renderItem = useRenderItem(numColumns);

  return (
    <FlatList
      key={`BundlesSurfaceTariffs(${numColumns})`}
      ListHeaderComponent={<BundlesInfo />}
      data={items}
      renderItem={renderItem}
      keyExtractor={keyExtractor}
      numColumns={numColumns}
      columnWrapperStyle={
        numColumns > 1
          ? {
              gap: 16,
            }
          : undefined
      }
      contentContainerStyle={{
        gap: 16,
        paddingHorizontal: useResponsive({
          default: 16,
          desktop: 0,
        })!,
      }}
    />
  );
});
