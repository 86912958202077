import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, Text, Heading} from '@youtoken/ui.primitives';
import {AutoConvertResponse} from '@youtoken/ui.resource-auto-convert';
import {CoinsPair} from '@youtoken/ui.elements';
import {LogoColoredIconName} from '@youtoken/ui.icons';
import {useTranslation} from '@youtoken/ui.service-i18n';

export const AutoConvertDetailsTopBlock: React.FC<{
  item: AutoConvertResponse;
}> = observer(
  ({
    item: {
      fromTickerFormatted,
      triggerRateFormatted,
      toTickerFormatted,
      fromAmount,
      fromTicker,
      toTicker,
    },
  }) => {
    const {t} = useTranslation();

    return (
      <Box>
        <Box
          height={120}
          width="100%"
          backgroundColor="$fill-01"
          px={24}
          pt={24}
          alignItems="center"
        >
          <Text color="$text-02" mb={4}>
            {t('surface.history.auto_convert_item.subtitle', {
              fromTicker: fromTickerFormatted,
              rate: triggerRateFormatted,
              toTicker: toTickerFormatted,
            })}
          </Text>
          <Heading variant="$heading-02" mb={30}>
            {t('surface.history.auto_convert_item.title', {
              fromAmount,
              fromTicker: fromTickerFormatted,
              toTicker: toTickerFormatted,
            })}
          </Heading>
          <CoinsPair
            firstTicker={fromTicker as LogoColoredIconName}
            secondTicker={toTicker as LogoColoredIconName}
            iconSize={40}
          />
        </Box>
      </Box>
    );
  }
);
