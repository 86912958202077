import * as React from 'react';
import {Platform} from 'react-native';
import {observer} from 'mobx-react';
import {Box} from '@youtoken/ui.primitives';
import {Slider} from '@youtoken/ui.elements';
import {LoyaltyResource} from '@youtoken/ui.resource-loyalty';
import {useCards} from '../hooks';
import {StaticCard} from '../components';

const HEIGHT = Platform.OS === 'web' ? 420 : 400; // fix for mobile web

export const LevelsSlider: React.FC<{}> = observer(() => {
  const {levels, currentLevel} = LoyaltyResource.use({});

  const cards = useCards(levels, currentLevel);

  const initialIndex = React.useMemo(() => {
    return currentLevel === levels.length ? currentLevel - 1 : currentLevel;
  }, [currentLevel, levels]);

  return (
    <Box height={HEIGHT}>
      <Slider
        initialIndex={initialIndex}
        data={cards}
        renderItem={({item, index}) => (
          <StaticCard {...item} level={levels[index]!} height={HEIGHT} />
        )}
        paginationVisibility="never"
        cardWidthMax={250}
      />
    </Box>
  );
});
