import * as React from 'react';
import {Text, Box, useIsMobile} from '@youtoken/ui.primitives';
import {cell, DefaultSuspenseFallback} from '@youtoken/ui.cell';
import {Trans, useTranslation} from '@youtoken/ui.service-i18n';
import {Button} from '@youtoken/ui.buttons';
import {FeatureLoanCloseNowFormArgs, FeatureLoanCloseNowForm} from './state';
import {Alert} from '@youtoken/ui.elements';
import {Form} from '..';
import {InformationTableRow} from '@youtoken/ui.information-table';

export interface LoanCloseNowFormProps extends FeatureLoanCloseNowFormArgs {
  onUnMount?: () => void;
}

export const LoanCloseNowForm: React.FC<LoanCloseNowFormProps> = cell(
  ({mode, id, onUnMount}) => {
    const {t} = useTranslation();

    const isMobile = useIsMobile();

    const feature = FeatureLoanCloseNowForm.use({
      mode,
      id,
    });

    React.useEffect(() => {
      feature.restore();

      return () => {
        onUnMount && onUnMount();
        feature.reset();
      };
    }, []);

    if (!feature.restored) {
      return <DefaultSuspenseFallback />;
    }

    if (!feature.loan) {
      return null;
    }

    return (
      <Form form={feature}>
        <Box flexGrow={1}>
          <Alert type="information">
            {t('surface.loans.close_now_form.alert')}
          </Alert>
          <Box p={24}>
            <Text variant="$body-02-high-accent" mb={15}>
              {t('surface.loans.close_now_form.table')}
            </Text>
            <Box>
              <InformationTableRow
                left={t('surface.loans.close_now_form.table.outstanding_loan')}
                right={
                  <Text variant="$body-02" color="$text-02">
                    <Text variant="$body-02-high-accent">
                      {feature.outstandingLoan}
                    </Text>{' '}
                    {feature.borrowedTicker}
                  </Text>
                }
              />

              <InformationTableRow
                left={t('surface.loans.close_now_form.table.price', {
                  ticker: feature.collateralTicker,
                })}
                right={
                  <Text variant="$body-02" color="$text-02">
                    <Text variant="$body-02-high-accent">{feature.price}</Text>{' '}
                    {feature.borrowedTicker}
                  </Text>
                }
              />
              <InformationTableRow
                left={t(
                  'surface.loans.close_now_form.table.collateral_repayment'
                )}
                right={
                  <Text variant="$body-02" color="$text-02">
                    <Text variant="$body-02-high-accent">
                      {feature.collateralRepayment}
                    </Text>{' '}
                    {feature.collateralTicker}
                  </Text>
                }
              />
              <InformationTableRow
                left={t('surface.loans.close_now_form.table.service_fee')}
                right={
                  <Text variant="$body-02" color="$text-02">
                    <Text variant="$body-02-high-accent">
                      {feature.serviceFee}
                    </Text>{' '}
                    {feature.borrowedTicker}
                  </Text>
                }
              />
              <InformationTableRow
                left={t('surface.loans.close_now_form.table.collateral_return')}
                right={
                  <Text variant="$body-02" color="$text-02">
                    <Text variant="$body-02-high-accent">
                      {feature.collateralReturn}
                    </Text>{' '}
                    {feature.collateralTicker}
                  </Text>
                }
              />
            </Box>
          </Box>
        </Box>
        <Box>
          <Box
            flexDirection={isMobile ? 'column' : 'row'}
            justifyContent="space-between"
            alignItems={isMobile ? 'stretch' : 'center'}
            bg="$ui-01"
            p={24}
            borderBottomLeftRadius={10}
            borderBottomRightRadius={10}
          >
            <Box mb={isMobile ? 20 : 0}>
              <Text
                variant="$body-01"
                lineHeight={28}
                color="$text-02"
                textAlign={isMobile ? 'center' : 'left'}
                mb={5}
              >
                <Trans
                  t={t}
                  i18nKey="surface.loans.close_now_form.get_back"
                  components={{
                    Strong: <Text variant="$heading-01" color="$text-01" />,
                  }}
                  values={{
                    amount: feature.collateralReturn,
                    ticker: feature.collateralTicker,
                  }}
                />
              </Text>
              <Text
                variant="$body-02"
                color="$text-02"
                textAlign={isMobile ? 'center' : 'left'}
              >
                {t('surface.loans.close_now_form.get_back_info')}
              </Text>
            </Box>
            <Button
              size="large"
              loading={feature.form.instance.submitting}
              onPress={feature.form.instance.onSubmit}
            >
              {t('surface.loans.close_now_form.submit')}
            </Button>
          </Box>
        </Box>
      </Form>
    );
  }
);
