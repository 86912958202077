import {Icon} from '@youtoken/ui.icons';
import {Box, Heading, Text, TouchableBox} from '@youtoken/ui.primitives';
import {useTranslation} from '@youtoken/ui.service-i18n';
import * as React from 'react';
import {Helmet} from 'react-helmet';
import {observer} from 'mobx-react';

const backIconCompensation = 4;

export const WebPageWrapper: React.FC<{
  windowTitle: string;
  hasBackButton?: boolean;
  onBackPress?: () => void;
  title?: string | React.ReactNode;
  tooltip?: React.ReactNode;
}> = observer(
  ({windowTitle, children, hasBackButton, onBackPress, title, tooltip}) => {
    const {t} = useTranslation();

    return (
      <>
        {Boolean(windowTitle) && (
          <Helmet title={windowTitle} titleTemplate="YouHodler | %s" />
        )}

        <Box flex={1}>
          {hasBackButton && (
            <TouchableBox
              onPress={onBackPress}
              mb={24}
              ml={{
                default: 16,
                phone: 20 - backIconCompensation,
                desktop: -backIconCompensation,
              }}
              testID="BACK_BUTTON"
            >
              <Box width="100%" flexDirection="row" alignItems="center">
                <Icon name="chevron_left" size={24} color="$text-05" />
                <Text color="$interactive-01" variant="$body-01-medium-accent">
                  {t('common.actions.back')}
                </Text>
              </Box>
            </TouchableBox>
          )}

          {typeof title === 'string' && (
            <Box
              zIndex={tooltip ? 1 : undefined}
              mb={30}
              ml={{
                default: 16,
                phone: 24,
                desktop: 0,
              }}
            >
              <Heading variant="$heading-01-responsive" color="$text-01">
                {title}
                <Box
                  style={{
                    // @ts-ignore
                    verticalAlign: 'middle',
                    marginTop: -2,
                    marginLeft: 4,
                  }}
                >
                  {tooltip}
                </Box>
              </Heading>
            </Box>
          )}

          {React.isValidElement(title) && title}

          {children}
        </Box>
      </>
    );
  }
);
