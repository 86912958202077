import * as React from 'react';
import {KeyboardScrollView} from '@youtoken/ui.elements';

export const ScrollWrapper: React.FC = ({children}) => {
  return (
    <KeyboardScrollView
      insideTabs={false}
      wrapInSafeArea
      scrollViewProps={{
        style: {overflow: 'scroll'},
        contentContainerStyle: {
          paddingTop: 0,
          paddingBottom: 0,
          paddingLeft: 0,
          paddingRight: 0,
          overflow: 'visible',
        },
        keyboardShouldPersistTaps: 'handled',
      }}
    >
      {children}
    </KeyboardScrollView>
  );
};
