import * as React from 'react';
import {Platform} from 'react-native';
import {
  BundleTariffTickersListItem,
  BundlePortfolioTickersListItem,
} from '@youtoken/ui.resource-bundles';
import {type BoxProps, Box} from '@youtoken/ui.primitives';
import {type ScrollableAreaProps, ScrollableArea} from '@youtoken/ui.elements';
import {BundleTickersScrollableListItem} from '.';
import {BundleTickersHeader} from '../Header';

interface BundleTickersScrollableListProps {
  items: Array<BundleTariffTickersListItem | BundlePortfolioTickersListItem>;
}

export const BundleTickersScrollableList: React.FC<
  BundleTickersScrollableListProps & BoxProps
> = ({items, ...boxProps}) => {
  const renderItem = React.useCallback(
    ({
      item,
      index,
    }: {
      item: BundleTariffTickersListItem | BundlePortfolioTickersListItem;
      index: number;
    }) => {
      return (
        <BundleTickersScrollableListItem
          item={item}
          mr={index < items.length - 1 ? 12 : 0}
        />
      );
    },
    [items.length]
  );

  return (
    <Box gap={16} {...boxProps}>
      <BundleTickersHeader
        items={items}
        px={Platform.select({
          native: 24,
        })}
      />
      <ScrollableArea
        testID="BUNDLE_TICKERS_SCROLLABLE_AREA"
        isExpandRestricted
        items={items}
        renderItem={renderItem}
        mobileEstimatedListHeight={64}
      />
    </Box>
  );
};
