import {
  BoxProps,
  boxRestyleFunctions,
  composeRestyleFunctions,
  useRestyle,
} from '@shopify/restyle';
import {ElementRef, forwardRef} from 'react';
import {ScrollView, ScrollViewProps} from 'react-native';
import {Theme} from '../../system';
import {StyleProp} from 'react-native';

// export const ScrollBox = createBox<Theme, ScrollViewProps>(ScrollView);

type RestyleProps = BoxProps<Theme> &
  Omit<ScrollViewProps, 'contentContainerStyle'>;

type InferType<T extends StyleProp<unknown>> = T extends StyleProp<infer P>
  ? P extends object
    ? P
    : never
  : never;

type LoosePick<T, K extends keyof any> = Pick<T, Extract<keyof T, K>>;

type AllRestyleProps = BoxProps<Theme>;
type AsRestyle<P> = LoosePick<AllRestyleProps, keyof InferType<P>>;

const restyleFns = composeRestyleFunctions<Theme, RestyleProps>(
  boxRestyleFunctions
);

export interface ScrollBoxProps extends RestyleProps {
  contentContainerStyle: AsRestyle<ScrollViewProps['contentContainerStyle']>;
}

export const ScrollBox = forwardRef<
  ElementRef<typeof ScrollView>,
  ScrollBoxProps
>(({contentContainerStyle = {}, ...props}, ref) => {
  const restyleProps = useRestyle(restyleFns, props);

  const __contentContainerStyle = useRestyle(
    restyleFns as any,
    contentContainerStyle
  ) as ScrollViewProps['contentContainerStyle'];

  return (
    <ScrollView
      {...restyleProps}
      ref={ref}
      // contentContainerStyle={__contentContainerStyle}
    />
  );
});
