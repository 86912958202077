import * as React from 'react';
import {Box} from '@youtoken/ui.primitives';
import {BundlesTariff} from '../components';
import {type Item} from '../utils';

export const useRenderItem = (columns: number = 1) => {
  const basis = columns > 1 ? `${100 / columns}%` : 'auto';

  return React.useCallback(
    ({item: {name, data}}: {item: Item}) => {
      if (name === 'tariff') {
        return <BundlesTariff data={data} flex={1} flexBasis={basis} />;
      }

      if (name === 'tariff_space') {
        return <Box flex={1} flexBasis={basis} />;
      }

      return null;
    },
    [basis]
  );
};
