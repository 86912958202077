import {z} from 'zod';
import {Big} from 'big.js';
import {computed} from 'mobx';
import {serializable, primitive, date} from 'serializr';
import {bigNumber} from '@youtoken/ui.utils-serializr';
import {formatByTicker} from '@youtoken/ui.formatting-utils';
import {format} from '@youtoken/ui.date-fns';

export type BundleOperationType = 'DEPOSIT' | 'WITHDRAW';

type BundleOperationStatus = 'PROCESSING' | 'DONE';

export const bundleOperationSchema = z.object({
  id: z.string(),
  operationType: z.string(),
  status: z.string(),
  quoteTicker: z.string(),
  quoteSum: z.string(),
  createdAt: z.string(),
});

export class BundleOperation {
  @serializable(primitive())
  id!: string;

  @serializable(primitive())
  operationType!: BundleOperationType;

  @serializable(primitive())
  status!: BundleOperationStatus;

  @serializable(primitive())
  quoteTicker!: string;

  @computed
  get quoteTickerFormatted() {
    return this.quoteTicker.toUpperCase();
  }

  @serializable(bigNumber())
  quoteSum!: Big;

  @computed
  get quoteSumFormatted() {
    return formatByTicker(this.quoteSum, this.quoteTicker);
  }

  @serializable(bigNumber())
  fee!: Big;

  @computed
  get feeFormatted() {
    return formatByTicker(this.fee, this.quoteTicker);
  }

  @serializable(date())
  createdAt!: Date;

  @computed get createdAtFormatted() {
    return this.createdAt.getFullYear() === new Date().getFullYear()
      ? format(this.createdAt, 'd MMMM')
      : format(this.createdAt, 'd MMMM yyyy');
  }
}
