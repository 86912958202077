import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, type BoxProps} from '@youtoken/ui.primitives';
import {Button} from '@youtoken/ui.buttons';
import {useForm} from '@youtoken/ui.form-elements';
import {AutoConvertState} from '../state';

const BREAKPOINTS = [1, 3, 5, 10];

export const DesiredRateButtons: React.FC<BoxProps> = observer(boxProps => {
  const {
    form: {calculateDesiredRateByPercent},
  } = useForm<AutoConvertState>();

  const createHandlePress = React.useCallback(
    (value: number) => {
      return () => calculateDesiredRateByPercent(value);
    },
    [calculateDesiredRateByPercent]
  );

  return (
    <Box flex={1} flexDirection="row" gap={8} {...boxProps}>
      {BREAKPOINTS.map(value => (
        <Button
          type="secondary"
          size="tiny"
          flex={1}
          key={value}
          onPress={createHandlePress(value)}
        >
          -{value}%
        </Button>
      ))}
    </Box>
  );
});
