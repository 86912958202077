import * as React from 'react';
import {type ImageProps, Image} from 'react-native';
import {warning} from '@youtoken/ui.utils';
import {Icon} from '@youtoken/ui.icons';

export interface BundleIconProps {
  uri: string;
  size?: number;
}

export const BundleIcon: React.FC<BundleIconProps & ImageProps> = ({
  uri,
  size = 32,
  ...imageProps
}) => {
  const [error, setError] = React.useState();

  if (error) {
    return <Icon name="question_outlined" size={size} color="$danger-01" />;
  }

  return (
    <Image
      testID="BUNDLE_ICON"
      source={{uri}}
      onError={({nativeEvent: {error}}) => {
        warning(true, 'Image: uri is not found', {}, {uri});
        setError(error);
      }}
      style={{width: size, height: size, resizeMode: 'contain'}}
      {...imageProps}
    />
  );
};
