import * as React from 'react';
import {Box, Text} from '@youtoken/ui.primitives';
import {type LogoColoredIconName} from '@youtoken/ui.icons';
import {CoinsPair} from '@youtoken/ui.elements';

interface DesiredRateLeftPartProps {
  ticker: LogoColoredIconName;
  tickerFormatted: string;
  conversionTicker: LogoColoredIconName;
  conversionTickerFormatted: string;
}

export const DesiredRateLeftPart: React.FC<DesiredRateLeftPartProps> = ({
  ticker,
  tickerFormatted,
  conversionTicker,
  conversionTickerFormatted,
}) => {
  return (
    <Box flexDirection="row" alignItems="center" pl={16} height="100%">
      <Box mr={8}>
        <CoinsPair
          firstTicker={ticker}
          secondTicker={conversionTicker}
          iconSize={20}
          iconOffset={14}
        />
      </Box>
      <Box position="relative">
        <Text variant="$body-01-medium-accent">
          {conversionTickerFormatted}/{tickerFormatted}
        </Text>
      </Box>
    </Box>
  );
};
