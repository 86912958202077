import * as React from 'react';
import {Platform} from 'react-native';
import {useResources, getResourceDescriptor} from '@youtoken/ui.data-storage';
import {
  LocaliseResourceNamespace,
  LocaliseResource,
} from '@youtoken/ui.resource-lokalise';
import {
  BundleTariffOverviewResource,
  BundleOperationsResource,
} from '@youtoken/ui.resource-bundles';
import {type Item} from '../utils';

export const useData = (id: string) => {
  const {
    bundleTariffOverview: {data},
    bundleOperations: {sections},
  } = useResources({
    lokalise: getResourceDescriptor(LocaliseResource, {
      namespace: LocaliseResourceNamespace.BUNDLE,
    }),
    bundleTariffOverview: getResourceDescriptor(BundleTariffOverviewResource, {
      id,
    }),
    bundleOperations: getResourceDescriptor(BundleOperationsResource, {
      id,
    }),
  });

  return React.useMemo(() => {
    const items: Item[] = [];

    //#region bundle-general

    items.push({
      type: 'general',
      name: 'bundle-general-summary',
      data,
    });

    items.push({
      type: 'chart',
      name: 'bundle-general-chart',
      data,
    });

    if (Platform.OS === 'web') {
      items.push({
        type: 'general',
        name: 'bundle-general-action',
        data,
      });
    }

    //#endregion bundle-general

    //#region bundle-tickers

    items.push({
      type: 'tickers',
      name: 'bundle-tickers',
      data: data.userPortfolio
        ? data.userPortfolio.tickers
        : data.tariff.tickersList,
    });

    //#endregion tickers

    //#region bundle-operations

    if (sections.length) {
      items.push({
        type: 'general',
        name: 'bundle-operations-header',
      });

      sections.forEach((section, sectionIndex) => {
        items.push({
          type: 'general',
          name: 'bundle-operations-sub-header',
          data: section,
          options: {
            isFirst: sectionIndex === 0,
          },
        });

        section.operations.forEach((operation, operationIndex) => {
          items.push({
            type: 'general',
            name: 'bundle-operations-item',
            data: {
              tariff: data.tariff,
              operation: operation,
            },
            options: {
              isLast:
                sectionIndex === sections.length - 1 &&
                operationIndex === section.operations.length - 1,
            },
          });
        });
      });
    }

    //#endregion bundle-operations

    return items;
  }, [data, sections]);
};
