import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {BundleTariff} from '@youtoken/ui.resource-bundles';
import {type BoxProps, Box, Text} from '@youtoken/ui.primitives';
import {type LogoColoredIconName, Icon, LogoColored} from '@youtoken/ui.icons';
import {BundleIcon} from '../../../../../../components';

export interface BundlesTariffSummaryProps {
  data: BundleTariff;
}

export const BundlesTariffSummary: React.FC<
  BundlesTariffSummaryProps & BoxProps
> = observer(({data, ...boxProps}) => {
  const {
    design: {iconUrl, title, description},
  } = data;

  return (
    <Box testID="BUNDLES_TARIFF_SUMMARY" gap={16} {...boxProps}>
      <Box gap={8}>
        <Box flexDirection="row" alignItems="center" gap={8}>
          <BundleIcon uri={iconUrl} size={24} />
          <Box flex={1}>
            <Text
              testID="TITLE"
              variant="$body-01-high-accent"
              color="$text-01"
            >
              {title}
            </Text>
          </Box>
        </Box>
        <Box>
          <Text testID="DESCRIPTION" variant="$body-02" color="$text-01">
            {description}
          </Text>
        </Box>
        <BundlesTariffSummaryTickers data={data} />
        <BundlesTariffSummaryDifference data={data} />
      </Box>
    </Box>
  );
});

const BundlesTariffSummaryTickers: React.FC<
  BundlesTariffSummaryProps & BoxProps
> = observer(({data: {tickersList}, ...boxProps}) => {
  const {t} = useTranslation();

  return (
    <Box
      testID="BUNDLES_TARIFF_SUMMARY_TICKERS"
      flexDirection="row"
      flexWrap="wrap"
      py={6}
      gap={5}
      {...boxProps}
    >
      <Text testID="TITLE" variant="$body-02" color="$text-01">
        {t('surface.bundle.tickers')}
      </Text>
      {tickersList.map(({ticker}) => {
        return (
          <LogoColored
            testID={`ITEM_${ticker.toUpperCase()}`}
            key={ticker}
            name={ticker as LogoColoredIconName}
            size={20}
          />
        );
      })}
    </Box>
  );
});

const BundlesTariffSummaryDifference: React.FC<
  BundlesTariffSummaryProps & BoxProps
> = observer(
  ({data: {priceDiffIsPositive, priceDiffFormatted}, ...boxProps}) => {
    const {t} = useTranslation();

    return (
      <Box testID="BUNDLES_TARIFF_SUMMARY_DIFFERENCE" {...boxProps}>
        <Text testID="TITLE" variant="$body-02" color="$text-01">
          {t('surface.bundles.tariff.anual_average')}
        </Text>
        <Box flexDirection="row" alignItems="center" gap={4}>
          <Icon
            testID="ICON"
            name={priceDiffIsPositive ? 'up' : 'down'}
            color={priceDiffIsPositive ? '$success-01' : '$danger-01'}
            size={20}
          />
          <Text
            testID="VALUE"
            variant="$body-01-medium-accent"
            color={priceDiffIsPositive ? '$success-01' : '$danger-01'}
          >
            {priceDiffFormatted}
          </Text>
        </Box>
      </Box>
    );
  }
);
