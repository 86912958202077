import * as React from 'react';
import {observer} from 'mobx-react';
import {useForm} from '@youtoken/ui.form-elements';
import {BundleWithdrawalFormState} from '../../../state';
import {BundleTickersList} from '../../../../../components';

export const Tickers: React.FC = observer(() => {
  const {
    form: {
      calculated: {loading, data},
    },
  } = useForm<BundleWithdrawalFormState>();

  if (!data || !data.tickers.length) {
    return null;
  }

  return <BundleTickersList items={data.tickers} opacity={loading ? 0.7 : 1} />;
});
