import {observable} from 'mobx';
import {View as ViewBase} from '../../ConvertBase/state/Authorized/View';
import {Form} from './Form';
import {AutoConvertFormArgs, AutoConvertFormResources} from './types';

export class AutoConvertState {
  @observable
  public form: Form;

  @observable
  public view: ViewBase;

  constructor(args: AutoConvertFormArgs, resources: AutoConvertFormResources) {
    this.form = new Form(args, resources);
    this.view = new ViewBase(this.form);
  }
}
