import * as React from 'react';
import {type SectionListRenderItem} from 'react-native';
import {HistoryItemData} from '@youtoken/ui.resource-history-new';
import {AutoConvertListButton} from '../../AutoConverts';
import {HistoryItem} from '../../HistoryItem';
import {type AutoConvertListItem} from '../../AutoConverts';

export const renderItem: SectionListRenderItem<
  HistoryItemData | AutoConvertListItem
> = ({item, ...props}) => {
  if ('isAutoConvert' in item && item.isAutoConvert) {
    return <AutoConvertListButton ticker={item.ticker} />;
  }
  return <HistoryItem item={item as HistoryItemData} {...props} />;
};
