import * as React from 'react';
import {Platform, ScrollView} from 'react-native';
import {Amount} from './Amount';
import {Tickers} from './Tickers';

export const Content: React.FC = () => {
  return (
    <ScrollView
      keyboardDismissMode="interactive"
      keyboardShouldPersistTaps="handled"
      style={{
        overflow: Platform.select({default: 'hidden', web: 'visible'}),
        zIndex: 1,
        flex: 1,
      }}
      contentContainerStyle={{
        padding: 24,
        gap: 24,
      }}
    >
      <Amount />
      <Tickers />
    </ScrollView>
  );
};
