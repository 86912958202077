import * as React from 'react';
import {observer} from 'mobx-react';
import {type BoxProps, Box} from '@youtoken/ui.primitives';
import {ItemProps} from './types';
import {Summary, Rate} from './components';

export const WalletSummaryAndRate: React.FC<ItemProps & BoxProps> = observer(
  ({
    showSavings = false,
    variant,
    needActivatePersonalIBAN,
    item,
    ...boxProps
  }) => {
    return (
      <Box
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        gap={40}
        {...boxProps}
      >
        <Summary
          showSavings={showSavings}
          item={item}
          variant={variant}
          needActivatePersonalIBAN={needActivatePersonalIBAN}
        />
        {item.shouldDisplayRate && <Rate item={item} />}
      </Box>
    );
  }
);
