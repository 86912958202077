import * as React from 'react';
import {z} from 'zod';
import {SurfaceDefinition, SurfaceOptions} from './types';
import {useSurfaceParams} from './SurfaceParams';
import {ErrorBoundary} from '@youtoken/ui.error-boundary';

export {
  ScreenInsetsContext,
  ScreenInsetsContextProvider,
  useScreenInsets,
} from './ScreenInsets';
export {
  SurfaceParamsContext,
  SurfaceParamsContextProvider,
  useSurfaceParams,
} from './SurfaceParams';
export {expoRouterAdapter, webAppAdapter, nativeAppAdapter} from './Adapters';

export function surface<
  Name extends string,
  ParamsSchema extends z.ZodObject<z.ZodRawShape>
>(
  options: SurfaceOptions<Name, ParamsSchema>,
  Component: React.ComponentType<z.infer<ParamsSchema>>
): SurfaceDefinition<Name, ParamsSchema> {
  const ParamsValidatorComponent = () => {
    const params = useSurfaceParams<z.infer<ParamsSchema>>();

    options.paramsSchema.parse(params);

    return <Component {...params} />;
  };

  ParamsValidatorComponent.displayName = `paramsValidator<(${
    options.name ?? Component.displayName ?? 'Component'
  })>`;

  // should be put into error boundary
  const SurfaceComponent = () => {
    return (
      <ErrorBoundary>
        <ParamsValidatorComponent />
      </ErrorBoundary>
    );
  };

  SurfaceComponent.displayName = `surface<(${
    options.name ?? Component.displayName ?? 'Component'
  })>`;

  return {
    name: options.name,
    paramsSchema: options.paramsSchema,
    component: SurfaceComponent,
    getTitle: options.getTitle,
  };
}
