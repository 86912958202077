import * as React from 'react';
import {Item} from '../types';
import {WalletsFeature} from '../state';
import {createWalletsItems} from '../utils';

export const useData = () => {
  const {
    enableStories,
    enableOnboardingWidget,
    sections,
    sectionsVisibilities,
  } = WalletsFeature.use({});

  return React.useMemo<Item[]>(() => {
    let data: Item[] = [];

    if (enableStories) {
      data.push({
        type: 'stories',
        data: {
          mb: 20,
        },
      });
    }

    data.push({
      type: 'value-and-actions',
      data: {
        mb: 8,
        mx: {default: 20, desktop: 0},
      },
    });

    if (enableOnboardingWidget) {
      data.push({
        type: 'onboarding-widget',
        data: {
          mx: {default: 20, desktop: 0},
          mt: 16,
        },
      });
    } else {
      data.push({
        type: 'verification-issue',
        data: {
          mx: {default: 20, desktop: 0},
          mt: 16,
        },
      });
      data.push({
        type: 'carousel-banners',
        data: {mt: 16},
      });
    }

    data.push({
      type: 'widgets-bar',
      data: {
        mx: {default: 20, desktop: 0},
        mt: 16,
      },
    });

    data.push({
      type: 'bank-cards',
      data: {
        mt: 16,
        mx: 16,
      },
    });

    data.push(...createWalletsItems(sections, sectionsVisibilities));

    return data;
  }, [enableStories, enableOnboardingWidget, sections, sectionsVisibilities]);
};
