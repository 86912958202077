import {z} from 'zod';
import {Big} from 'big.js';
import {computed} from 'mobx';
import {
  type AdditionalPropArgs,
  serializable,
  optional,
  primitive,
  object,
  list,
  alias,
} from 'serializr';
import {bigNumber} from '@youtoken/ui.utils-serializr';
import {
  currencyName,
  getCoinDecimalPrecisionForLoans,
  getCoinName,
} from '@youtoken/ui.coin-utils';
import {
  formatBigNumber,
  formatByTicker,
  formatPercent,
  toBig,
} from '@youtoken/ui.formatting-utils';
import {RatesResource} from '@youtoken/ui.resource-rates';

const bundlePortfolioTickersItemSchema = z.object({
  ticker: z.string(),
  amount: z.string(),
});

export const getBundlePortfolioTickersListItemAdditionalProps = (
  type: 'base' | 'calculated'
): AdditionalPropArgs => {
  return {
    afterDeserialize: (
      callback,
      err,
      newValue: BundlePortfolioTickersListItem,
      jsonValue,
      jsonParentValue,
      propNameOrIndex,
      context
    ) => {
      newValue.type = type;
      newValue.conversionTicker = context.target.quoteTicker;

      callback(err, newValue);
    },
  };
};

export const bundlePortfolioSchema = z.object({
  id: z.string(),
  quoteTicker: z.string(),
  quoteAmount: z.string(),
  difference: z.string(),
  tickers: z.array(bundlePortfolioTickersItemSchema),
});

export class BundlePortfolioTickersListItem {
  @serializable(primitive())
  type!: 'base' | 'calculated';

  @serializable(primitive())
  ticker!: string;

  @computed
  get tickerFormatted() {
    return this.ticker.toUpperCase();
  }

  @computed
  get tickerName() {
    return getCoinName(this.ticker);
  }

  @serializable(primitive())
  conversionTicker!: currencyName;

  @computed
  get conversionTickerFormatted() {
    return this.conversionTicker.toUpperCase();
  }

  @serializable(bigNumber())
  amount!: Big;

  @computed
  get amountFormatted() {
    return formatByTicker(this.amount, this.ticker);
  }

  @serializable(optional(alias('rate', bigNumber())))
  _rate?: currencyName;

  @computed
  get rate() {
    if (this._rate) {
      return this._rate;
    }

    return toBig(
      RatesResource.getInstanceSafely({})?.getRate(
        this.ticker,
        this.conversionTicker
      )
    );
  }

  @computed
  get rateFormatted() {
    return formatBigNumber(
      this.rate,
      getCoinDecimalPrecisionForLoans(this.conversionTicker) + 2,
      true
    );
  }

  @computed
  get conversionAmount() {
    return this.amount.mul(this.rate);
  }

  @computed
  get conversionAmountFormatted() {
    return formatByTicker(this.conversionAmount, this.conversionTicker);
  }
}

export class BundlePortfolio {
  @serializable(primitive())
  id!: string;

  @serializable(primitive())
  quoteTicker!: currencyName;

  @computed
  get quoteTickerFormatted() {
    return this.quoteTicker.toUpperCase();
  }

  @serializable(bigNumber())
  quoteAmount!: Big;

  @computed
  get quoteAmountFormatted() {
    return formatByTicker(this.quoteAmount, this.quoteTicker);
  }

  @serializable(bigNumber())
  difference!: Big;

  @computed
  get differenceFormatted() {
    return formatPercent(this.difference) + '%';
  }

  @serializable(
    list(
      object(
        BundlePortfolioTickersListItem,
        getBundlePortfolioTickersListItemAdditionalProps('base')
      )
    )
  )
  tickers!: BundlePortfolioTickersListItem[];
}
