import React from 'react';
import {observer} from 'mobx-react';
import {type HODLItem} from '@youtoken/ui.resource-hodl';
import {Box, Text, useIsMobile} from '@youtoken/ui.primitives';
import {RolloverIn} from './RolloverIn';
import {getThemeColorForDirection} from '../../../utils';

export const CurrentResultRollover: React.FC<CurrentResultProps> = observer(
  ({hodl}) => {
    const {rolloverData} = hodl;

    return (
      <Box alignItems="flex-end">
        {!rolloverData?.active && (
          <Text color="$text-02" variant="$body-02">
            {hodl.relativeDate}
          </Text>
        )}
        {rolloverData?.active && <RolloverIn rolloverData={rolloverData} />}
      </Box>
    );
  }
);

interface CurrentResultProps {
  hodl: HODLItem;
}

export const CurrentResult: React.FC<CurrentResultProps> = observer(
  ({hodl}) => {
    const {
      hodlResultFormatted: {
        resultAmount,
        resultTicker,
        resultPercent,
        resultDirection,
      },
    } = hodl;

    const _directionColor = React.useMemo(() => {
      return getThemeColorForDirection(resultDirection);
    }, [resultDirection]);

    const isMobile = useIsMobile();

    return (
      <Box alignItems="flex-end" justifyContent="flex-start">
        <Box
          flexDirection={{
            phone: 'column',
            tablet: 'row',
          }}
          alignItems="flex-end"
        >
          <Box flexDirection="row">
            {Boolean(resultAmount) && (
              <Text color={_directionColor} variant="$body-01">
                {resultAmount} {resultTicker}
              </Text>
            )}
            {Boolean(resultAmount && resultPercent && !isMobile) && (
              <Text
                color={_directionColor}
                variant="$body-01-high-accent"
                mx={4}
              >
                ·
              </Text>
            )}
          </Box>
          <Box>
            {Boolean(resultPercent) && (
              <Text color={_directionColor} variant="$body-01-high-accent">
                {resultPercent}%
              </Text>
            )}
          </Box>
        </Box>
        <CurrentResultRollover hodl={hodl} />
      </Box>
    );
  }
);
