import * as React from 'react';
import {TouchableBox, Box, Text, type BoxProps} from '@youtoken/ui.primitives';
import {Icon, type IconName} from '@youtoken/ui.icons/src/Icon';

interface FeatureButtonProps {
  icon: IconName;
  title: string;
  onPress: () => void;
}

export const FeatureButton: React.FC<FeatureButtonProps & BoxProps> = ({
  icon,
  title,
  onPress,
  ...boxProps
}) => {
  return (
    <TouchableBox
      flexDirection="row"
      borderColor="$ui-01"
      justifyContent="space-between"
      px={24}
      py={18}
      onPress={onPress}
      {...boxProps}
    >
      <Box flexDirection="row">
        <Icon name={icon} size={20} />
        <Text variant="$body-01-high-accent" ml={8}>
          {title}
        </Text>
      </Box>
      <Icon size={20} name="chevron_right" />
    </TouchableBox>
  );
};
