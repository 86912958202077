import * as React from 'react';
import Svg, {Path} from 'react-native-svg';
import type {SvgProps} from 'react-native-svg';
import {memo} from 'react';

export const NetworkErrorIcon = React.memo(
  ({
    size = 24,
    color = '#212121',
    ...props
  }: SvgProps & {
    size?: number,
  }) => {
    return (
      <Svg
        width={size}
        height={size}
        fill="none"
        viewBox="0 0 24 24"
        {...props}
      >
        <Path
          fill={color}
          fillRule="evenodd"
          d="M23.4 7.2a5.1 5.1 0 1 1-10.2 0 5.1 5.1 0 0 1 10.2 0m-5.1-3.6a.96.96 0 0 0-.96.96v2.597a.96.96 0 1 0 1.92 0V4.56a.96.96 0 0 0-.96-.96m0 5.4a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92"
          clipRule="evenodd"
        />
        <Path
          fill={color}
          d="M12 4.193q.21 0 .42.006a6.6 6.6 0 0 0-.646 2.008A13 13 0 0 0 2.81 10.01a1.004 1.004 0 1 1-1.42-1.42A15 15 0 0 1 12 4.193M4.22 11.42a11.08 11.08 0 0 1 7.556-3.217c.109.716.333 1.394.653 2.015A9 9 0 0 0 5.64 12.84a1.004 1.004 0 1 1-1.42-1.42M12 12.2a7.1 7.1 0 0 1 2.674.516 6.6 6.6 0 0 0 1.195.622q.612.397 1.131.912a1 1 0 0 1-.76 1.71 1 1 0 0 1-.7-.3 5 5 0 0 0-7.08 0 1 1 0 0 1-1.41 0 1 1 0 0 1 0-1.41A7.06 7.06 0 0 1 12 12.2"
        />
        <Path
          fill={color}
          fillRule="evenodd"
          d="M12 16.2a3 3 0 1 0 0 6.001 3 3 0 0 0 0-6m0 4a1 1 0 1 1 0-2 1 1 0 0 1 0 2"
          clipRule="evenodd"
        />
      </Svg>
    );
  }
);
