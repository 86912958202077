import * as React from 'react';
import {Separator} from '@youtoken/ui.elements';
import {
  HODLChartSmartCell,
  ResizableBoxWithOverlay,
  TradingViewModeSwitch,
  useResizableBox,
} from '@youtoken/ui.legacy-charts';
import {SmartTVChartContainerWeb} from '@youtoken/ui.trading-view-charts';
import {RateModalChartProps} from './types';

export const RateModalChart: React.FC<RateModalChartProps> = ({
  fromTicker,
  toTicker,
}) => {
  const [
    {
      size: {width, height},
    },
    bind,
  ] = useResizableBox();

  const [isTradingViewChartOpen, setIsTradingViewChartOpen] =
    React.useState(false);

  const handleTradingViewChartOpen = React.useCallback(() => {
    setIsTradingViewChartOpen(true);
  }, []);

  const handleTradingViewChartClose = React.useCallback(() => {
    setIsTradingViewChartOpen(false);
  }, []);

  return (
    <ResizableBoxWithOverlay height={360} width="100%" {...bind}>
      {width &&
        height &&
        (isTradingViewChartOpen ? (
          <>
            <SmartTVChartContainerWeb
              fromTicker={fromTicker}
              toTicker={toTicker}
              mode="mid"
              // @ts-ignore
              screenName="Convert"
              height={300}
            />
            <Separator />
            <TradingViewModeSwitch
              mode="tradingview"
              onChange={handleTradingViewChartClose}
              pt={15}
              pb={16}
              px={24}
            />
          </>
        ) : (
          <HODLChartSmartCell
            displayTypeSwitcher={false}
            width={width}
            height={height}
            fromTicker={fromTicker}
            toTicker={toTicker}
            onPressTradingViewModeButton={handleTradingViewChartOpen}
          />
        ))}
    </ResizableBoxWithOverlay>
  );
};
