import * as React from 'react';
import {Image, PixelRatio} from 'react-native';
import {observer} from 'mobx-react';
import {warning} from '@youtoken/ui.utils';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {BundleTariff} from '@youtoken/ui.resource-bundles';
import {type BoxProps, TouchableBox, Box} from '@youtoken/ui.primitives';

const RATIO = PixelRatio.get();
const DENSITY = RATIO < 1.5 ? '' : RATIO < 3 ? '@2x' : '@3x';

const useImageUriWithDensity = (uri: string) => {
  return React.useMemo(() => {
    return uri.replace(/(\.png)$/, `${DENSITY}$1`);
  }, [uri]);
};

export interface BundlesTariffCardProps {
  data: BundleTariff;
}

export const BundlesTariffCard: React.FC<BundlesTariffCardProps & BoxProps> =
  observer(
    ({
      testID = 'BUNDLES_TARIFF_CARD',
      data: {
        id,
        design: {backgroundColor, backgroundImageUrl},
      },
      children,
      ...boxProps
    }) => {
      const uri = useImageUriWithDensity(backgroundImageUrl);

      const handlePress = React.useCallback(() => {
        DATA_LAYER.trackStrict('bundles-details-viewed', {tariffId: id});
        SHARED_ROUTER_SERVICE.navigate('Bundle', {id});
      }, [id]);

      return (
        <TouchableBox
          testID={`${testID}_${id.toUpperCase()}`}
          p={4}
          pt={0}
          bg={backgroundColor}
          borderRadius={16}
          onPress={handlePress}
          {...boxProps}
        >
          <Box alignSelf="center" alignItems="stretch" width={280} height={140}>
            <Image
              testID="IMAGE"
              source={{uri}}
              style={{width: '100%', height: '100%', resizeMode: 'contain'}}
              onError={() => {
                warning(
                  true,
                  'Image: uri is not found',
                  {},
                  {uri: backgroundImageUrl}
                );
              }}
            />
          </Box>
          <Box
            flexGrow={1}
            backgroundColor="$ui-background"
            borderRadius={12}
            overflow="hidden"
          >
            {children}
          </Box>
        </TouchableBox>
      );
    }
  );
