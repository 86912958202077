import * as React from 'react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {createWizard} from '@youtoken/ui.elements';
import {cell} from '@youtoken/ui.cell';
import {ConvertStep, AutoConvertStep} from './steps';

export interface ParamList {
  Convert: {};
  AutoConvert: {};
}

const Wizard = createWizard<ParamList>();

export interface ConvertWizardProps {
  fromTicker: string;
  toTicker?: string;
  initialAmount?: number;
  dl_source?: string;
  onPressBack?: () => void;
}

export interface ConvertWizardParams {
  Convert: ConvertWizardProps;
  AutoConvert: Required<Pick<ConvertWizardProps, 'fromTicker' | 'toTicker'>> &
    Pick<ConvertWizardProps, 'initialAmount'>;
}

export const ConvertWizard: React.FC<ConvertWizardProps> = cell(
  ({onPressBack, ...params}) => {
    const {t} = useTranslation();

    return (
      <Wizard.Navigator
        initialName="Convert"
        goBack={onPressBack}
        initialProps={params}
      >
        <Wizard.Screen
          name="Convert"
          component={ConvertStep}
          options={{title: t('surface.wallets.conversion_form.title')}}
        />

        <Wizard.Screen
          name="AutoConvert"
          component={AutoConvertStep}
          options={{title: t('surface.auto_convert.title')}}
        />
      </Wizard.Navigator>
    );
  }
);
