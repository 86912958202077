import * as React from 'react';
import {observer} from 'mobx-react';
import {KeyboardAwareScrollView} from 'react-native-keyboard-controller';
import {type StackScreenProps} from '@react-navigation/stack';
import {cell} from '@youtoken/ui.cell';
import {Box} from '@youtoken/ui.primitives';
import {useSafeArea} from '@youtoken/ui.hooks';
import {ModalScreenStatusBar} from './StatusBar';
import {ScreenOptions} from './ScreenOptions';

const ModalScreen = cell(
  function ModalScreen({
    children,
  }: React.PropsWithChildren<{}>): React.JSX.Element {
    const {bottom} = useSafeArea();

    return (
      <KeyboardAwareScrollView
        contentContainerStyle={{
          paddingBottom: bottom,
          flexGrow: 1,
          flexShrink: 0,
        }}
      >
        {children}
      </KeyboardAwareScrollView>
    );
  },
  {
    CellWrap: ({children}) => {
      return <Box flex={1}>{children}</Box>;
    },
  }
);

export function modalScreen<
  ParamList extends Record<string, object | undefined>,
  RouteName extends keyof ParamList = keyof ParamList
>(Component: React.ComponentType<StackScreenProps<ParamList, RouteName>>) {
  const ObserverComponent = observer(Component);

  return function ModalScreenWrapper({
    route,
    navigation,
  }: StackScreenProps<ParamList, RouteName>) {
    return (
      <>
        <ModalScreenStatusBar />

        <ModalScreen>
          <ObserverComponent route={route} navigation={navigation} />
        </ModalScreen>
      </>
    );
  };
}

export function screen<
  ParamList extends Record<string, object | undefined>,
  RouteName extends keyof ParamList = keyof ParamList
>(
  Component: React.ComponentType<StackScreenProps<ParamList, RouteName>>,
  defaultOptions?: React.ComponentProps<typeof ScreenOptions>
) {
  const ObserverComponent = cell(Component, {
    CellWrap: ({children}) => {
      const {bottom} = useSafeArea();

      return (
        <Box flex={1} backgroundColor="$ui-background">
          <KeyboardAwareScrollView
            contentContainerStyle={{
              paddingBottom: bottom,
              flexGrow: 1,
              flexShrink: 0,
            }}
          >
            {children}
          </KeyboardAwareScrollView>
        </Box>
      );
    },
  });

  return function ScreenWrapper({
    route,
    navigation,
  }: StackScreenProps<ParamList, RouteName>) {
    return (
      <>
        {defaultOptions && <ScreenOptions {...defaultOptions} />}
        <ObserverComponent route={route} navigation={navigation} />
      </>
    );
  };
}
