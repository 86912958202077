import * as React from 'react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {cell} from '@youtoken/ui.cell';
import {WebPageWrapper} from '@youtoken/ui.screen-components';
import {MinerSurface} from './MinerSurface';
import {MinerSurfaceTooltip} from './Tooltip';

export const MinerSurfaceCell: React.FC = cell(() => {
  const {t} = useTranslation();

  return (
    <WebPageWrapper
      windowTitle={t('surface.menu.cloud_miner')}
      title={t('surface.menu.cloud_miner')}
      tooltip={<MinerSurfaceTooltip />}
    >
      <MinerSurface />
    </WebPageWrapper>
  );
});
