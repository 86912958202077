import {computed} from 'mobx';
import {Form} from './Form';
import {View as ConversionViewUnauthorized} from '../../../../ConvertBase';
import {i18n} from '@youtoken/ui.service-i18n';

export class View extends ConversionViewUnauthorized {
  @computed get convertActionText() {
    return i18n.t('ramp.conversion_form.action.submit_buy', {
      ticker: this.conversionTickerFormatted,
    });
  }

  public constructor(form: Form) {
    super(form);
  }
}
