import * as React from 'react';
import {PromoBanner} from '@youtoken/ui.elements';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {useTranslation} from '@youtoken/ui.service-i18n';

export const ActivatePersonalIBANBanner: React.FC<{
  ticker: string;
}> = ({ticker}) => {
  const {t} = useTranslation();

  return (
    <PromoBanner
      text={t('surface.wallets.personal_iban.activate_promo.body')}
      imageName="ibanOpenAccount"
      secondaryText={t('surface.wallets.personal_iban.activate_promo.title')}
      onPressBanner={() => {
        SHARED_ROUTER_SERVICE.navigate('ActivatePersonalIBAN', {ticker});
      }}
      testID="PERSONAL_IBAN_PROMO_BANNER"
    />
  );
};
