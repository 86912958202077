import * as React from 'react';
import {Platform} from 'react-native';
import {AutoConvert} from '@youtoken/ui.surfaces-wallets';
import {useWizard} from '@youtoken/ui.elements';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {HeaderBase} from '../../../components';
import {type ConvertWizardParams} from '..';

const isNative = Platform.select({
  default: false,
  native: true,
});

export const AutoConvertStep = () => {
  const {
    step: {
      props: {fromTicker, toTicker, initialAmount},
    },
    goBack,
  } = useWizard<ConvertWizardParams, 'AutoConvert'>();

  const {t} = useTranslation();

  return (
    <>
      {!isNative && (
        <HeaderBase shouldShowSeparator onPressBack={goBack}>
          {t('surface.auto_convert.title')}
        </HeaderBase>
      )}
      <AutoConvert
        fromTicker={fromTicker}
        toTicker={toTicker}
        initialAmount={initialAmount}
      />
    </>
  );
};
