import * as React from 'react';
import {observer} from 'mobx-react';
import {type TKey} from '@youtoken/ui.service-i18n';
import {type BoxProps} from '@youtoken/ui.primitives';
import {useForm} from '@youtoken/ui.form-elements';
import {ConversionFormClass, ExchangeFormState} from '../../../state';
import {ExchangeFormConversionPairField} from '../Field';
import {ConversionSourceButtons} from './Buttons';
import {AutoConvertState} from '../../../../AutoConvert/state';

interface ExchangeFormConversionPairSourceProps {
  showBalance?: boolean;
  labelKey?: TKey;
  initialAmount?: number;
  withFastHandlers?: boolean;
}

export const ExchangeFormConversionPairSource: React.FC<
  ExchangeFormConversionPairSourceProps & BoxProps
> = observer(
  ({
    showBalance = true,
    labelKey = 'surface.wallets.conversion_form.from',
    initialAmount,
    withFastHandlers = true,
    ...boxProps
  }) => {
    const {
      form: {
        ticker,
        amount,
        hasSourceError,
        sourceError,
        setTicker,
        setAmount,
        setAmountByPercentOfBalance,
        balancePercent,
      },
      view: {wallets},
    } = useForm<ConversionFormClass | ExchangeFormState | AutoConvertState>();

    return (
      <>
        <ExchangeFormConversionPairField
          labelKey={labelKey}
          wallets={wallets}
          ticker={ticker}
          amount={amount}
          hasError={hasSourceError}
          error={sourceError}
          onChangeTicker={setTicker}
          onChangeAmount={setAmount}
          zIndex={3}
          {...boxProps}
        />
        {withFastHandlers && (
          <ConversionSourceButtons
            mt={8}
            setAmount={setAmountByPercentOfBalance}
            balance={balancePercent}
          />
        )}
      </>
    );
  }
);
