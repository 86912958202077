import * as React from 'react';
import {Box} from '@youtoken/ui.primitives';
import {TextInput} from '@youtoken/ui.inputs';
import {TickerDropdownToggle} from '../components';
import {TickerCombobox} from './TickerCombobox';
import {type TickerAndAmountInputProps} from '../types';
import {getAmountPlaceholder} from '../utils';
import {getCoinDecimalPrecision} from '@youtoken/ui.coin-utils';
import {normalizeAmountWithPrecision} from '@youtoken/ui.normalizers';

export const TickerAndAmountInput: React.FC<TickerAndAmountInputProps> = ({
  zIndex,
  disabled,
  hasError,
  onChangeAmount,
  onChangeTicker,
  ticker,
  amount,
  wallets,
  precision = getCoinDecimalPrecision(ticker),
  isTickerImmutable = false,
  tickerOnly,
  testID,
  textInputEditable = true,
  textInputAccessible = true,
  size = 'large',
}) => {
  const [mode, setMode] = React.useState<'amount' | 'ticker'>('amount');

  const handleTextChange = React.useCallback(
    (value: string) => {
      onChangeAmount?.(normalizeAmountWithPrecision(value, precision));
    },
    [onChangeAmount, precision]
  );

  const handlePressLeftPart = React.useCallback(() => {
    if (isTickerImmutable) {
      return;
    }

    setMode('ticker');
  }, [isTickerImmutable]);

  const amountPlaceholder = React.useMemo(
    () => getAmountPlaceholder(precision),
    [precision]
  );

  return (
    <Box
      position="relative"
      zIndex={10}
      testID={testID ?? 'TICKER_AND_AMOUNT_INPUT'}
    >
      {mode === 'amount' && !tickerOnly && (
        <TextInput
          disabled={disabled}
          value={amount}
          textAlign="right"
          size={size}
          onChangeText={handleTextChange}
          placeholder={amountPlaceholder}
          autoComplete="off"
          autoCorrect={false}
          autoCapitalize="none"
          importantForAutofill="no"
          hasError={hasError}
          editable={textInputEditable}
          accessible={textInputAccessible}
          LeftPartComponent={
            Boolean(wallets.length) && (
              <TickerDropdownToggle
                testID="TICKER_INPUT"
                disabled={isTickerImmutable}
                ticker={ticker}
                onPress={handlePressLeftPart}
              />
            )
          }
          testID="AMOUNT_INPUT"
        />
      )}

      {(mode === 'ticker' || tickerOnly) && (
        <TickerCombobox
          key={mode}
          initialIsOpen={mode === 'ticker'}
          items={wallets}
          setMode={setMode}
          ticker={ticker}
          onChangeTicker={onChangeTicker}
          zIndex={zIndex}
          disabled={disabled}
          hasError={hasError}
          testID="TICKER_INPUT"
        />
      )}
    </Box>
  );
};
