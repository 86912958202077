import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {type BoxProps, Box} from '@youtoken/ui.primitives';
import {SubmitButton, useForm} from '@youtoken/ui.form-elements';
import {ExchangeFormAgreeToTerms} from '../../ConvertBase';
import {AutoConvertState} from '../state';

export const AutoConvertFormFooter: React.FC<BoxProps> = observer(boxProps => {
  const {t} = useTranslation();

  const {
    form: {checkIsLoading},
  } = useForm<AutoConvertState>();

  return (
    <Box p={24} borderTopWidth={1} borderColor="$ui-01" {...boxProps}>
      <ExchangeFormAgreeToTerms />
      <SubmitButton
        testID="AUTO_CONVERSION_SUBMIT_BUTTON"
        loading={checkIsLoading}
      >
        {t('surface.auto_converts.form.submit')}
      </SubmitButton>
    </Box>
  );
});
