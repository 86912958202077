import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Box, type BoxProps, Heading, Text} from '@youtoken/ui.primitives';
import {ProgressBar} from '@youtoken/ui.progress-bar';
import {
  InformationTable,
  InformationTableRow,
} from '@youtoken/ui.information-table';
import {useForm} from '@youtoken/ui.form-elements';
import {
  BuyCryptoFormAuthorizedOnRampState,
  BuyCryptoFormAuthorizedYouHodlerState,
} from '../state';

export const InfoTableV2: React.FC<BoxProps> = observer(boxProps => {
  const {t} = useTranslation();

  const {
    form: {rateIsFixed, rateTimeIntervalName, rateTimeIntervalProgress},
    view: {
      tickerFormatted,
      conversionTickerFormatted,
      rateFormatted,
      convertFeeFormatted,
      paymentFeeFormatted,
      totalPayFormatted,
      paymentFeeIsActive,
      paymentFeeType,
      showTotalPay,
    },
  } = useForm<
    BuyCryptoFormAuthorizedOnRampState | BuyCryptoFormAuthorizedYouHodlerState
  >();

  return (
    <InformationTable py={0} {...boxProps}>
      <InformationTableRow
        zIndex={5}
        webTooltipPosition="bottom"
        testID="CONVERT_TABLE_RATE_ROW"
        left={
          t('surface.wallets.conversion_form_v2.table.rate', {
            type: t(
              rateIsFixed
                ? 'surface.wallets.conversion_form_v2.rate_type.fixed'
                : 'surface.wallets.conversion_form_v2.rate_type.approximate'
            ),
          }) + ' '
        }
        leftTooltipText={t(
          rateIsFixed
            ? 'surface.wallets.conversion_form_v2.rate_type.fixed_tooltip'
            : 'surface.wallets.conversion_form_v2.rate_type.approximate_tooltip'
        )}
        tooltipCaretPosition="left"
        tooltipOffset={-24}
        right={
          <Text variant="$body-02" color="$text-02">
            <Text variant="$body-02-high-accent" color="$text-01">
              1
            </Text>{' '}
            {tickerFormatted} {rateIsFixed ? '=' : '≈'}{' '}
            <Text
              variant="$body-02-high-accent"
              color="$text-01"
              testID="CONVERT_TABLE_RATE_VALUE"
            >
              {rateFormatted}
            </Text>{' '}
            {conversionTickerFormatted}
          </Text>
        }
      />
      <Box mb={20} zIndex={4}>
        <ProgressBar
          key={rateTimeIntervalName}
          progress={rateTimeIntervalProgress}
        />
      </Box>
      <InformationTableRow
        zIndex={3}
        webTooltipPosition="bottom"
        testID="CONVERT_TABLE_FEE_ROW"
        left={t('ramp.conversion_form.table.service_fee')}
        leftTooltipText={t('ramp.conversion_form.table.service_fee.tooltip')}
        tooltipCaretPosition="left"
        tooltipOffset={-24}
        right={
          <Text variant="$body-02" color="$text-02">
            <Text
              variant="$body-02-high-accent"
              color="$text-01"
              testID="CONVERT_TABLE_FEE_VALUE"
            >
              {convertFeeFormatted}
            </Text>{' '}
            {tickerFormatted}
          </Text>
        }
        isLast={!paymentFeeIsActive}
      />
      {paymentFeeIsActive && paymentFeeType === 'defined' && (
        <>
          <InformationTableRow
            testID="CONVERT_TABLE_PAYMENT_FEE_ROW"
            left={t('ramp.conversion_form.table.payment_fee')}
            right={
              <Text variant="$body-02" color="$text-02">
                <Text
                  variant="$body-02-high-accent"
                  color="$text-01"
                  testID="CONVERT_TABLE_PAYMENT_FEE_VALUE"
                >
                  {paymentFeeFormatted}
                </Text>{' '}
                {tickerFormatted}
              </Text>
            }
          />
          {showTotalPay && (
            <Box pt={8} flexDirection="row" alignItems="baseline">
              <Heading variant="$heading-02" color="$text-01" mr={4}>
                {totalPayFormatted}
              </Heading>
              <Text variant="$body-01" color="$text-02">
                {tickerFormatted} {t('ramp.conversion_form.table.total_pay')}
              </Text>
            </Box>
          )}
        </>
      )}
      {paymentFeeIsActive && paymentFeeType === 'volatile' && (
        <InformationTableRow
          testID="CONVERT_TABLE_FEE_INFO_ROW"
          left={t('ramp.conversion_form.table.payment_fee_info')}
          right={null}
          isLast
        />
      )}
    </InformationTable>
  );
});
