import React from 'react';
import {
  ResizableBoxWithOverlay,
  WalletChartSmartCell,
  useResizableBox,
  PanResponder,
} from '@youtoken/ui.legacy-charts';

export interface ChartProps {
  ticker: string;
  equivalentTicker: string;
  precision: number;
  onPressTradingViewModeButton?: () => void;
}

export const WalletsItemChart: React.FC<ChartProps> = React.memo(
  ({ticker, equivalentTicker, precision, onPressTradingViewModeButton}) => {
    const [
      {
        size: {width, height},
      },
      bind,
    ] = useResizableBox();

    return (
      <ResizableBoxWithOverlay
        height={360}
        width="100%"
        testID={`WALLETS_ITEM_CHART_${ticker.toUpperCase()}_${equivalentTicker.toUpperCase()}`}
        {...bind}
      >
        {!!(width && height) && (
          <WalletChartSmartCell
            width={width}
            height={height}
            fromTicker={ticker}
            toTicker={equivalentTicker}
            precision={precision}
            ChartPanComponent={PanResponder}
            onPressTradingViewModeButton={onPressTradingViewModeButton}
          />
        )}
      </ResizableBoxWithOverlay>
    );
  }
);
