import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {type BoxProps, Box, Text} from '@youtoken/ui.primitives';
import {Button} from '@youtoken/ui.buttons';

interface BundlesTooltipContentProps {
  onClose?: () => void;
}

export const BundlesTooltipContent: React.FC<
  BundlesTooltipContentProps & BoxProps
> = observer(({onClose, ...boxProps}) => {
  const {t} = useTranslation();

  return (
    <Box testID="BUNDLES_TOOLTIP_CONTENT" {...boxProps}>
      <Text testID="TEXT" variant="$body-01" color="$text-04">
        {t('surface.bundles.title_tooltip')}
      </Text>
      <Button
        testID="ACTION_BUTTON"
        type="inverted"
        size="medium"
        onPress={onClose}
        alignSelf="stretch"
        mt={16}
      >
        {t('surface.miner.heading.tooltip.action')}
      </Button>
    </Box>
  );
});
