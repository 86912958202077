import * as React from 'react';
import {observer} from 'mobx-react';
import {ENVIRONMENT} from '@youtoken/ui.environment';
import {Box, Text} from '@youtoken/ui.primitives';
import {Icon} from '@youtoken/ui.icons';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {DevelopmentInfo} from './DevelopmentInfo';
import {ErrorFallbackProps} from '../types';

export interface BaseErrorFallbackProps extends ErrorFallbackProps {
  /**
   * error text
   * @default 'Something went wrong'
   **/
  text?: string;
  /**
   * error icon name
   * @default 'warning'
   * */
  iconName?: React.ComponentProps<typeof Icon>['name'];

  /** optional hint for error */
  hint?: string;
}

export const BaseErrorFallback: React.FC<BaseErrorFallbackProps> = observer(
  ({
    error,
    text,
    iconName = 'warning',
    hint,
    backgroundColor,
    reportingResult,
    children,
    ...rest
  }) => {
    const {t} = useTranslation();

    return (
      <Box
        backgroundColor={backgroundColor}
        flexGrow={1}
        justifyContent="center"
        alignItems="stretch"
        height="100%"
        width="100%"
        p={24}
        {...rest}
      >
        <Box
          p={16}
          borderRadius={12}
          borderWidth={1}
          borderColor="$ui-01"
          justifyContent="center"
          alignItems="center"
        >
          <Icon name={iconName} size={24} color="$text-01" />

          <Text
            color="$text-01"
            variant="$body-01-high-accent"
            mt={4}
            textAlign="center"
          >
            {text ?? t('common.errors.smth_went_wrong')}
          </Text>

          {hint && (
            <Text color="$text-02" variant="$body-02" mt={4} textAlign="center">
              {hint}
            </Text>
          )}

          {children}
        </Box>
        {ENVIRONMENT.DEV && (
          <DevelopmentInfo error={error} reportingResult={reportingResult} />
        )}
      </Box>
    );
  }
);
