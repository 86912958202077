import * as React from 'react';
import {ModalDynamic, type ModalDynamicProps} from '@youtoken/ui.modal';
import {AutoConvertDetails} from '@youtoken/ui.surfaces-wallets';
import {cell} from '@youtoken/ui.cell';

export const AutoConvertDetailsModal: React.FC<
  {id: string; ticker?: string} & Pick<ModalDynamicProps, 'isOpen' | 'onClose'>
> = cell(({id, ticker, isOpen, onClose}) => {
  return (
    <ModalDynamic isOpen={isOpen} onClose={onClose}>
      <AutoConvertDetails onClose={onClose} id={id} ticker={ticker} />
    </ModalDynamic>
  );
});
