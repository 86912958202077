import {action, runInAction} from 'mobx';
import axios from 'axios';
import {debounce} from 'lodash';
import {TRANSPORT} from '@youtoken/ui.transport';
import {handleFormFieldsErrors} from '@youtoken/ui.form-utils';
import {Form as WidgetFormUnauthorized} from '../../../../ConvertBase';
import {
  type WidgetUnauthorizedFormArgs,
  type WidgetUnauthorizedFormResources,
} from './types';

export class Form extends WidgetFormUnauthorized {
  @action
  check = () => {
    this.cancelCheck();

    const {token, cancel} = axios.CancelToken.source();

    this.checkCanceller = cancel;

    return TRANSPORT.API.post(
      '/v2/converts/ramp/check-public',
      {
        side: this.side,
        fromTicker: this.ticker,
        toTicker: this.conversionTicker,
        fromAmount: this.amount,
        toAmount: this.conversionAmount,
        rate: this.rate,
      },
      {
        cancelToken: token,
      }
    )
      .then(({data: {isFixedRate}}) => {
        runInAction(() => {
          this.rateIsFixed = isFixedRate;
        });
      })
      .catch(error => {
        if (this.checkRateShowError) {
          runInAction(() => {
            handleFormFieldsErrors(this.instance, error);
          });
        }
      })
      .finally(() => {
        runInAction(() => {
          this.checkIsLoading = false;
        });
      });
  };

  checkDebounced = debounce(this.check, 300);

  constructor(
    args: WidgetUnauthorizedFormArgs,
    resources: WidgetUnauthorizedFormResources
  ) {
    super(args, resources);
  }
}
