import * as React from 'react';
import {observer} from 'mobx-react';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {NewReferralBadge} from '@youtoken/ui.surface-new-referral';

export const NewReferralBadgeSmart: React.FC = observer(() => {
  const {newReferralEnabled} = AuthMeResource.use({});

  if (!newReferralEnabled) {
    return null;
  }
  return <NewReferralBadge />;
});
