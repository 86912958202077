import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {BaseErrorFallback} from './BaseFallback';
import {ErrorFallbackProps} from './types';

export const UnauthorizedErrorFallback: React.FC<ErrorFallbackProps> = observer(
  props => {
    const {t} = useTranslation();

    return (
      <BaseErrorFallback
        iconName="warning"
        text={t('common.errors.auth_required')}
        {...props}
      />
    );
  }
);
