import * as React from 'react';
import {observer} from 'mobx-react';
import {useTheme} from '@youtoken/ui.primitives';
import {Label} from '../../Primitives';
import type {AutoConvertChartState, BaseHODLChartState} from '../../logic';

export const TriggerPriceNameLabel: React.FC<{
  chartState: BaseHODLChartState | AutoConvertChartState;
}> = React.memo(
  observer(({chartState}) => {
    const {colors} = useTheme();

    const {
      isCursorActive,
      triggerPriceNameLabel: {layout},
    } = chartState;

    if (!layout || isCursorActive) {
      return null;
    }

    return (
      <Label
        x={layout.x}
        y={layout.y}
        width={layout.width}
        height={layout.height}
        textFill={colors['$chart-interactive-01']}
        labelFill={colors['$chart-interactive-02']}
      >
        {layout.text}
      </Label>
    );
  })
);
