import * as React from 'react';
import {type BoxProps, TouchableBox} from '@youtoken/ui.primitives';
import {Icon} from '@youtoken/ui.icons';
import {TooltipModal} from './Modal';
import {TOOLTIP_ICON_SIZE} from './constants';

export interface TooltipProps {
  Component: React.ComponentType<{
    onClose?: () => void;
  }>;
  initialOpen?: boolean;
  onClose?: () => void;
}

export const Tooltip: React.FC<TooltipProps & BoxProps> = ({
  Component,
  initialOpen = false,
  onClose,
  ...boxProps
}) => {
  const [isOpen, setIsOpen] = React.useState(initialOpen);

  const handleToggle = React.useCallback(() => {
    setIsOpen(isOpen => {
      if (isOpen) {
        onClose?.();
      }

      return !isOpen;
    });
  }, [onClose]);

  const handleClose = React.useCallback(() => {
    setIsOpen(false);
    onClose?.();
  }, [onClose]);

  return (
    <>
      <TouchableBox onPress={handleToggle} {...boxProps}>
        <Icon
          name="question_outlined"
          color={isOpen ? '$ui-04' : '$text-01'}
          size={TOOLTIP_ICON_SIZE}
        />
      </TouchableBox>
      <TooltipModal isOpen={isOpen} onClose={handleClose}>
        <Component onClose={handleClose} />
      </TooltipModal>
    </>
  );
};
