import * as React from 'react';
import {Heading, Text, XStack, YStack, useTheme} from '@youtoken/ui.primitives';
import {surface} from '@youtoken/ui.screen-components';
import {Banner, FeatureList, Illustration} from '@youtoken/ui.elements';
import {i18n, useTranslation} from '@youtoken/ui.service-i18n';
import {ScrollView} from 'react-native';
import {z} from 'zod';
import {
  DisclosureForm,
  AgreeToTermsField,
  SubmitDisclosureButton,
} from '@youtoken/ui.surface-legal-info';
import {ActivatePersonalIBANFeature} from './ActivatePersonalIBANFeature';

export const ActivatePersonalIBANSurface = surface(
  {
    name: 'ActivatePersonalIBAN',
    paramsSchema: z
      .object({
        ticker: z
          .string()
          .describe(
            'ticker of the currency for which the personal IBAN is being activated'
          ),
      })
      .strict(),
    getTitle: () => i18n.t('surface.activate_personal_iban.title'),
  },
  ({ticker}) => {
    const {
      personalIBANStatus,
      showAgreements,
      actionDisabled,
      productForDisclosure,
      handleOpenAccount,
    } = ActivatePersonalIBANFeature.use({ticker});
    const {t} = useTranslation();
    const {spacing} = useTheme();

    return (
      <>
        <YStack justifyContent="space-between" flex={1}>
          <ScrollView
            // TODO: refactor to some sensible component
            style={{
              flex: 1,
            }}
            contentContainerStyle={{
              paddingVertical: spacing['$gap-l'],
            }}
          >
            <YStack gap="$gap-l" justifyContent="center" alignItems="center">
              <YStack
                g="$gap-m"
                justifyContent="center"
                alignItems="center"
                px="$screen-section"
              >
                <Illustration
                  name="illustration-15"
                  style={{
                    width: 220,
                    height: 160,
                  }}
                />

                <YStack gap="$gap-xs">
                  <Heading variant="$heading-01" textAlign="center">
                    {t('surface.activate_personal_iban.heading', {
                      ticker: ticker.toUpperCase(),
                    })}
                  </Heading>
                  <Text textAlign="center">
                    {t('surface.activate_personal_iban.description')}
                  </Text>
                </YStack>
              </YStack>
              <YStack px="$screen-section" width="100%">
                <FeatureList
                  width="100%"
                  items={[
                    {
                      icon: 'card',
                      title: t(
                        'surface.activate_personal_iban.features.0.title'
                      ),
                      children: t(
                        'surface.activate_personal_iban.features.0.text'
                      ),
                    },
                    {
                      icon: 'interest',
                      title: t(
                        'surface.activate_personal_iban.features.1.title'
                      ),
                      children: t(
                        'surface.activate_personal_iban.features.1.text'
                      ),
                    },
                    {
                      icon: 'convert',
                      title: t(
                        'surface.activate_personal_iban.features.2.title'
                      ),
                      children: t(
                        'surface.activate_personal_iban.features.2.text'
                      ),
                    },
                  ]}
                />
              </YStack>
            </YStack>
          </ScrollView>

          <YStack>
            {personalIBANStatus === 'AVAILABLE' && (
              <Banner>
                {t(
                  'surface.activate_personal_iban.notice.have_old_credentials'
                )}
              </Banner>
            )}

            {personalIBANStatus === 'HAS_PENDING_TRANSACTIONS' && (
              <Banner variant="attention">
                {t(
                  'surface.activate_personal_iban.notice.have_pending_transactions'
                )}
              </Banner>
            )}

            <YStack
              px="$screen-section"
              pb={{default: 40, tablet: '$gap-l'}}
              width="100%"
            >
              <DisclosureForm
                product={productForDisclosure!}
                handleOnSuccess={handleOpenAccount}
              >
                {showAgreements && (
                  <XStack justifyContent="space-between" gap="$gap-s">
                    <AgreeToTermsField
                      beforeListText={t('surface.disclosure_form.action')}
                      width="100%"
                      mt="$gap-m"
                      alignSelf="stretch"
                      testID="ACTIVATE_PERSONAL_IBAN_AGREEMENT"
                    />
                  </XStack>
                )}
                <SubmitDisclosureButton
                  disabled={actionDisabled}
                  width="100%"
                  mt="$gap-l"
                  testID="ACTIVATE_PERSONAL_IBAN_ACTION_BUTTON"
                >
                  {t('surface.activate_personal_iban.action')}
                </SubmitDisclosureButton>
              </DisclosureForm>
            </YStack>
          </YStack>
        </YStack>
      </>
    );
  }
);
