import * as React from 'react';
import {FlatList} from 'react-native';
import {
  AutoConvertResource,
  type AutoConvertResponse,
} from '@youtoken/ui.resource-auto-convert';
import {Box} from '@youtoken/ui.primitives';
import {Separator} from '@youtoken/ui.elements';
import {cell} from '@youtoken/ui.cell';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {AutoConvertListItem} from '../AutoConvertListItem';
import {type AutoConvertListProps} from '../types';
import {AutoConvertDetailsModal} from '../AutoConvertDetailsModal';

export const AutoConvertList: React.FC<AutoConvertListProps> = cell(
  ({ticker}) => {
    const {autoConvertsList, hasAutoConverts} = AutoConvertResource.use({
      ticker,
    });
    const [detailsModalId, setDetailsModalId] = React.useState<string | null>(
      null
    );

    const handleCloseModal = React.useCallback(() => {
      setDetailsModalId(null);
    }, []);

    const renderItem = React.useCallback(
      ({item, index}: {item: AutoConvertResponse; index: number}) => {
        const handleOpenModal = () => {
          setDetailsModalId(item.id);
        };

        return (
          <AutoConvertListItem
            item={item}
            testID={`AUTO_CONVERT_ITEM_${index}`}
            onPress={handleOpenModal}
          />
        );
      },
      []
    );

    if (!hasAutoConverts) {
      SHARED_ROUTER_SERVICE.navigate('AccountStats');
    }

    return (
      <>
        {detailsModalId !== null && (
          <AutoConvertDetailsModal
            isOpen
            onClose={handleCloseModal}
            id={detailsModalId}
            ticker={ticker}
          />
        )}
        <Box
          borderColor="$ui-01"
          borderWidth={1}
          overflow="hidden"
          borderRadius={10}
          mb={8}
        >
          <FlatList
            data={autoConvertsList}
            renderItem={renderItem}
            ItemSeparatorComponent={Separator}
          />
        </Box>
      </>
    );
  }
);
