import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {useIsMobile, Box, Heading} from '@youtoken/ui.primitives';
import {Icon} from '@youtoken/ui.icons';
import {Button} from '@youtoken/ui.buttons';

interface MinerSurfaceTooltipContentProps {
  onClose?: () => void;
}

export const MinerSurfaceTooltipContent: React.FC<MinerSurfaceTooltipContentProps> =
  observer(({onClose}) => {
    const {t} = useTranslation();

    const isMobile = useIsMobile();

    return (
      <Box testID="MINER_TOOLTIP_CONTENT" alignItems="center" flex={1}>
        <Icon name="mining" size={48} color="$text-04" />
        <Heading
          variant="$heading-02"
          mt={{default: 16, tablet: 8}}
          mb={{default: 16, tablet: 24}}
          color="$text-04"
          textAlign="center"
        >
          {t('surface.miner.heading.tooltip.text')}
        </Heading>
        <Button
          type="inverted"
          onPress={onClose}
          alignSelf="stretch"
          testID="MINER_TOOLTIP_ACTION"
          size={isMobile ? 'medium' : 'large'}
        >
          {t('surface.miner.heading.tooltip.action')}
        </Button>
      </Box>
    );
  });
