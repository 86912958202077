import * as React from 'react';
import {InnerPageShell} from '@web-app/src/components/layout/Shell';
import {webAppAdapter} from '@youtoken/ui.screen-components/src/Surface';
import {ActivatePersonalIBANSurface} from '@youtoken/ui.surface-bank-wire';

const WalletsAvtivatePersonalIBAN = webAppAdapter(ActivatePersonalIBANSurface);

export const WalletsAvtivatePersonalIBANPage: React.FC = () => {
  return (
    <InnerPageShell>
      <WalletsAvtivatePersonalIBAN />
    </InnerPageShell>
  );
};
