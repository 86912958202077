import * as React from 'react';
import {cell} from '@youtoken/ui.cell';
import {ReSignDisclosuresSurfaceCell} from '@youtoken/ui.surface-re-sign-disclosures';
import {ModalDynamic} from '@youtoken/ui.modal';
import {useTranslation} from '@youtoken/ui.service-i18n';

export const ModalReSignDisclosures: typeof ModalDynamic = cell(props => {
  const {t} = useTranslation();

  return (
    <ModalDynamic
      title={t('surface.disclosure_form.title')}
      closeable={false}
      {...props}
    >
      <ReSignDisclosuresSurfaceCell />
    </ModalDynamic>
  );
});
