import * as React from 'react';
import {Platform} from 'react-native';
import {BundleSummary, BundleTickersScrollableList} from '../../../components';
import {
  BundleGeneralActions,
  BundleGeneralChart,
  BundleOperationsHeader,
  BundleOperationsSubHeader,
  BundleOperationsItem,
} from '../components';

type ItemType = 'general' | 'chart' | 'tickers';

type ItemName =
  | 'bundle-general-summary'
  | 'bundle-general-chart'
  | 'bundle-general-action'
  | 'bundle-tickers'
  | 'bundle-operations-header'
  | 'bundle-operations-sub-header'
  | 'bundle-operations-item';

export interface Item {
  type: ItemType;
  name: ItemName;
  data?: any;
  options?: {
    isLast?: boolean;
    isFirst?: boolean;
  };
}

export const keyExtractor = (item: Item, index: number) =>
  item.type + item.name + index;

export const getItemType = (item: Item) => {
  return item.type;
};

export const renderItem = ({item: {name, data, options}}: {item: Item}) => {
  //#region bundle-general

  if (name === 'bundle-general-summary') {
    return (
      <BundleSummary
        data={data}
        p={{default: 16, phone: 24}}
        borderColor="$ui-01"
        borderTopWidth={Platform.select({web: 1})}
        borderLeftWidth={{default: 0, desktop: 1}}
        borderRightWidth={{default: 0, desktop: 1}}
        borderTopLeftRadius={{default: 0, desktop: 10}}
        borderTopRightRadius={{default: 0, desktop: 10}}
      />
    );
  }

  if (name === 'bundle-general-chart') {
    return (
      <BundleGeneralChart
        data={data}
        borderColor="$ui-01"
        borderLeftWidth={{default: 0, desktop: 1}}
        borderRightWidth={{default: 0, desktop: 1}}
      />
    );
  }

  if (name === 'bundle-general-action') {
    return (
      <BundleGeneralActions
        data={data}
        p={{default: 16, phone: 24}}
        borderColor="$ui-01"
        borderBottomWidth={1}
        borderLeftWidth={{default: 0, desktop: 1}}
        borderRightWidth={{default: 0, desktop: 1}}
        borderBottomLeftRadius={{default: 0, desktop: 10}}
        borderBottomRightRadius={{default: 0, desktop: 10}}
      />
    );
  }

  //#endregion bundle-general

  //#region bundle-tickers

  if (name === 'bundle-tickers') {
    return (
      <BundleTickersScrollableList
        items={data}
        pt={{default: 16, phone: 24}}
        px={Platform.select({web: {default: 16, phone: 24, desktop: 0}})}
      />
    );
  }

  //#endregion tickers

  //#region bundle-operations

  if (name === 'bundle-operations-header') {
    return (
      <BundleOperationsHeader
        pt={{default: 16, phone: 24}}
        px={{default: 16, phone: 24, desktop: 0}}
        py={16}
      />
    );
  }

  if (name === 'bundle-operations-sub-header') {
    return (
      <BundleOperationsSubHeader
        item={data}
        px={{default: 16, phone: 24}}
        py={{default: 8, phone: 12}}
        borderColor="$ui-01"
        borderTopWidth={options?.isFirst ? 1 : 0}
        borderTopLeftRadius={
          options?.isFirst ? {default: 0, desktop: 10} : undefined
        }
        borderTopRightRadius={
          options?.isFirst ? {default: 0, desktop: 10} : undefined
        }
        borderBottomWidth={1}
        borderLeftWidth={{default: 0, desktop: 1}}
        borderRightWidth={{default: 0, desktop: 1}}
      />
    );
  }

  if (name === 'bundle-operations-item') {
    return (
      <BundleOperationsItem
        tariff={data.tariff}
        operation={data.operation}
        borderColor="$ui-01"
        borderBottomWidth={1}
        borderLeftWidth={{default: 0, desktop: 1}}
        borderRightWidth={{default: 0, desktop: 1}}
        borderBottomLeftRadius={
          options?.isLast ? {default: 0, desktop: 10} : undefined
        }
        borderBottomRightRadius={
          options?.isLast ? {default: 0, desktop: 10} : undefined
        }
      />
    );
  }

  //#endregion bundle-operations

  return null;
};
