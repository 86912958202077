import * as React from 'react';
import {observer} from 'mobx-react';
import {ENVIRONMENT} from '@youtoken/ui.environment';
import {WebAppEnv} from '@youtoken/ui.env-utils';
import {Box} from '@youtoken/ui.primitives';
import {useForm} from '@youtoken/ui.form-elements';
import {
  AccountLevelProgressBarVariant,
  AccountLevelProgressWidgetV2,
} from '@youtoken/ui.surface-account-levels';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {useWizard} from '@youtoken/ui.elements';
import {ConvertWizardParams} from '../../../../ConvertWizard';
import {type ExchangeFormContentProps} from '../types';
import {ExchangeFormConversionPair, ExchangeFormInfoTableV2} from '../..';
import {ExchangeFormContentWrap} from '../Wrap';
import {ExchangeFormState} from '../../../state';
import {FeatureButton} from './components';

export const ExchangeFormContentV2: React.FC<ExchangeFormContentProps> =
  observer(({showBalance, initialAmount}) => {
    const shouldShowWidget = ENVIRONMENT.WEB_APP_ENV !== WebAppEnv['ramp-app'];

    const {
      form: {volumeForecast, sparksAmount, ticker, conversionTicker, amount},
    } = useForm<ExchangeFormState>();

    const {autoConvertEnabled} = AuthMeResource.use({});

    const {navigate} = useWizard<ConvertWizardParams, 'AutoConvert'>();

    const navigateToAutoConvert = React.useCallback(() => {
      navigate('AutoConvert', {
        fromTicker: ticker,
        toTicker: conversionTicker,
        initialAmount: amount,
      });
    }, [ticker, conversionTicker, amount]);

    const {t} = useTranslation();

    return (
      <ExchangeFormContentWrap>
        <Box flex={1} p={24} zIndex={1}>
          <ExchangeFormConversionPair
            showBalance={showBalance}
            initialAmount={initialAmount}
          />
          {shouldShowWidget && (
            <AccountLevelProgressWidgetV2
              variant={AccountLevelProgressBarVariant.EXCHANGE}
              volume={volumeForecast}
              sparks={sparksAmount}
              mt={24}
            />
          )}
          {autoConvertEnabled && shouldShowWidget && (
            <FeatureButton
              title={t('surface.auto_convert.title')}
              icon="ftp"
              onPress={navigateToAutoConvert}
              borderTopWidth={1}
              borderBottomWidth={1}
              mt={24}
              mx={-24}
            />
          )}
          <ExchangeFormInfoTableV2 />
        </Box>
      </ExchangeFormContentWrap>
    );
  });
