import {action, computed} from 'mobx';
import {computedFn} from 'mobx-utils';
import {deserialize} from 'serializr';
import {i18n} from '@youtoken/ui.service-i18n';
import {createResource} from '@youtoken/ui.data-storage';
import {TRANSPORT} from '@youtoken/ui.transport';
import {LOCAL_NOTIFICATIONS} from '@youtoken/ui.local-notifications';
import {AxiosError} from '@youtoken/ui.errors';
import {
  extractErrorFromResponse,
  getTranslatedValidationMessage,
} from '@youtoken/ui.validation-messages';
import {AutoConvertResponse} from './AutoConvertResponse';

interface AutoConvertResourceArgs {
  ticker?: string;
}

export class AutoConvertResource extends createResource<
  AutoConvertResourceArgs,
  AutoConvertResponse[]
>({
  getKey: ({ticker}) => `AutoConvert_${ticker}`,
  getData: ({ticker}) =>
    TRANSPORT.API.get('/v2/converts/limit', {
      params: {...(ticker ? {ticker} : {})},
    }).then(res => {
      return deserialize(
        AutoConvertResponse,
        res.data as AutoConvertResponse[]
      );
    }),
  skipRefreshOnVisible: false,
}) {
  @computed
  get autoConvertsList() {
    return this.data;
  }

  @computed
  get hasAutoConverts() {
    return Boolean(this.data.length);
  }

  getAutoConvertById = computedFn((id: string) => {
    return this.data.find(autoConvert => autoConvert.id === id);
  });

  @action
  deleteAutoConvert = (id: string) => {
    return TRANSPORT.API.post(`/v2/converts/cancel`, {id})
      .then(() => {
        LOCAL_NOTIFICATIONS.info({
          text: i18n.t('surface.auto_converts.notification.deleted'),
        });
      })
      .catch((error: AxiosError) => {
        LOCAL_NOTIFICATIONS.error({
          text:
            getTranslatedValidationMessage(
              extractErrorFromResponse(error.response?.data, '_error')
            ) || i18n.t('surface.auto_converts.notification.delete_failed'),
        });
      });
  };

  @computed
  get autoConvertSection() {
    if (this.hasAutoConverts) {
      return {
        title: i18n.t('surface.auto_converts_list.title') as string,
        data: [{isAutoConvert: true, ticker: this.args.ticker}],
      };
    }
    return {title: '', data: []};
  }
}
