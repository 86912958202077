import * as React from 'react';
import {CustomError, ERROR_TYPE} from '@youtoken/ui.errors';
import {DefaultErrorFallback} from './DefaultErrorFallback';
import {NetworkErrorFallback} from './NetworkErrorFallback';
import {UnauthorizedErrorFallback} from './UnauthorizedErrorFallback';
import {type ErrorFallbackProps} from './types';
import {MaintenanceFallback} from './MaintenanceFallback';

export {type ErrorFallbackProps} from './types';

const fallbacks: Partial<{[key in ERROR_TYPE]: React.FC<ErrorFallbackProps>}> =
  {
    GENERAL_ERROR: DefaultErrorFallback,
    NETWORK_ERROR: NetworkErrorFallback,
    NETWORK_ERROR_OFFLINE: NetworkErrorFallback,
    NETWORK_ERROR_TIMEOUT: NetworkErrorFallback,
    NETWORK_ERROR_MAINTENANCE: MaintenanceFallback,
    NETWORK_ERROR_SERVER: NetworkErrorFallback,
    NETWORK_ERROR_UNAUTHORIZED: UnauthorizedErrorFallback,
    UNKNOWN_ERROR: DefaultErrorFallback,
  };

const pickErrorFallback = (error: CustomError) => {
  return fallbacks[error.__type] ?? DefaultErrorFallback;
};

export const ErrorFallback: React.FC<ErrorFallbackProps> = ({...props}) => {
  if ((props.error as CustomError).__isCustomError) {
    const Component = pickErrorFallback(props.error as CustomError);

    return <Component {...props} />;
  }

  return <DefaultErrorFallback {...props} />;
};
