import * as React from 'react';
import {TouchableBox, Box} from '@youtoken/ui.primitives';
import {type TooltipModalProps} from './types';
import {
  TOOLTIP_ICON_SIZE,
  TOOLTIP_CONTENT_WIDTH,
  TOOLTIP_CONTENT_TRIANGLE_WIDTH,
} from '../constants';

export const TooltipModal: React.FC<TooltipModalProps> = ({
  isOpen,
  onClose,
  children,
}) => {
  if (!isOpen) {
    return null;
  }

  return (
    <>
      <TouchableBox
        width="100vw"
        height="100vh"
        position="fixed"
        top={0}
        bottom={0}
        left={0}
        right={0}
        onPress={onClose}
      />
      <Box
        position="absolute"
        top={45}
        left={-TOOLTIP_CONTENT_WIDTH / 2 + TOOLTIP_ICON_SIZE / 2}
        backgroundColor="$interactive-01"
        width={TOOLTIP_CONTENT_WIDTH}
        p={20}
        borderRadius={16}
        zIndex={10}
      >
        <Box
          backgroundColor="$interactive-01"
          position="absolute"
          top={-6}
          left={TOOLTIP_CONTENT_WIDTH / 2 - TOOLTIP_CONTENT_TRIANGLE_WIDTH / 2}
          width={TOOLTIP_CONTENT_TRIANGLE_WIDTH}
          height={TOOLTIP_CONTENT_TRIANGLE_WIDTH}
          borderRadius={4}
          style={{
            transform: [{rotate: '45deg'}],
          }}
        />
        {children}
      </Box>
    </>
  );
};
