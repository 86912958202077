import * as React from 'react';
import {observer} from 'mobx-react';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import {ModalDynamic} from '@youtoken/ui.modal';
import {Box} from '@youtoken/ui.primitives';
import {RateModalChart} from './Chart';

interface RateModalProps<T> {
  isOpen: boolean;
  onClose: () => void;
  ticker: string;
  conversionTicker: string;
  tickerFormatted: string;
  conversionTickerFormatted: string;
  chartComponent?: T;
}

export const RateModal: React.FC<RateModalProps<typeof RateModalChart>> =
  observer(
    ({
      isOpen,
      onClose,
      ticker,
      tickerFormatted,
      conversionTicker,
      conversionTickerFormatted,
      chartComponent: ChartComponent = RateModalChart,
    }) => {
      const {bottom} = useSafeAreaInsets();

      return (
        <ModalDynamic
          isOpen={isOpen}
          onClose={onClose}
          title={`${conversionTickerFormatted}/${tickerFormatted}`}
        >
          <Box pb={bottom}>
            <Box width="100%">
              <ChartComponent
                fromTicker={conversionTicker}
                toTicker={ticker}
                onClose={onClose}
              />
            </Box>
          </Box>
        </ModalDynamic>
      );
    }
  );
