import * as React from 'react';
import {cell} from '@youtoken/ui.cell';
import {type BoxProps, Box} from '@youtoken/ui.primitives';
import {Form, makeForm} from '@youtoken/ui.form-elements';
import {getResourceDescriptor, useResources} from '@youtoken/ui.data-storage';
import {VerificationResource} from '@youtoken/ui.resource-verification';
import {WalletsResource} from '@youtoken/ui.resource-wallets';
import {MarketsResource} from '@youtoken/ui.resource-markets';
import {DocsResource} from '@youtoken/ui.resource-documents';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {FeeAllResource} from '@youtoken/ui.resource-fee-all';
import {RatesResource} from '@youtoken/ui.resource-rates';
import {type ExchangeFormProps} from '../types';
import {useExchangeAttemptTracker} from '../hooks';
import {
  ConversionFormClass,
  ExchangeFormHeader,
  ExchangeFormContentV1,
  ExchangeFormFooterV1,
} from '../../ConvertBase';

export const ExchangeFormV1: React.FC<ExchangeFormProps & BoxProps> = cell(
  ({
    showHeader = false,
    ticker: _ticker,
    conversionTicker: _conversionTicker,
    ...props
  }) => {
    const {residenceOrCountry} = AuthMeResource.use({});

    const resources = useResources({
      authMeResource: getResourceDescriptor(AuthMeResource, {}),
      verificationResource: getResourceDescriptor(VerificationResource, {}),
      docsResource: getResourceDescriptor(DocsResource, {
        country: residenceOrCountry,
      }),
      walletsResource: getResourceDescriptor(WalletsResource, {}),
      marketsResource: getResourceDescriptor(MarketsResource, {}),
      ratesResource: getResourceDescriptor(RatesResource, {}),
      feeResource: getResourceDescriptor(FeeAllResource, {}),
    });

    const state = makeForm(
      () =>
        new ConversionFormClass(resources, {
          ticker: _ticker,
          conversionTicker: _conversionTicker,
        })
    );

    const {
      form: {ticker, conversionTicker},
    } = state;

    useExchangeAttemptTracker(ticker, conversionTicker, 'v1');

    return (
      <Form form={state}>
        <Box flex={1} {...props}>
          {showHeader && <ExchangeFormHeader />}
          <ExchangeFormContentV1 />
          <ExchangeFormFooterV1 />
        </Box>
      </Form>
    );
  }
);
