import * as React from 'react';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {cell} from '@youtoken/ui.cell';
import {type BoxProps} from '@youtoken/ui.primitives';
import {Tooltip} from '@youtoken/ui.screen-components';
import {MinerSurfaceTooltipContent} from './Content';

export const MinerSurfaceTooltip: React.FC<BoxProps> = cell(boxProps => {
  const {
    data: {
      clientSettings: {hasSeenMinerTooltipFirstTime},
    },
    setHasSeenMinerTooltipFirstTime,
  } = AuthMeResource.use({});

  return (
    <Tooltip
      testID="MINER_TOOLTIP"
      Component={MinerSurfaceTooltipContent}
      initialOpen={!hasSeenMinerTooltipFirstTime}
      onClose={setHasSeenMinerTooltipFirstTime}
      {...boxProps}
    />
  );
});
