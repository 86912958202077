import * as React from 'react';
import {ConvertWizard} from '@youtoken/ui.surfaces-wallets';
import {ModalDynamic} from '@youtoken/ui.modal';
import {useWizard} from '@youtoken/ui.elements';

interface ModalConversionProps
  extends React.ComponentProps<typeof ModalDynamic> {
  ticker: string;
  toTicker?: string;
  initialAmount?: number;
  dl_source?: string;
}

export const ModalConversion: React.FC<ModalConversionProps> = ({
  ticker,
  toTicker,
  initialAmount,
  dl_source,
  ...otherProps
}) => {
  const {goBack} = useWizard();

  return (
    <ModalDynamic shouldBeScrollable minHeight={570} {...otherProps}>
      <ConvertWizard
        fromTicker={ticker}
        toTicker={toTicker}
        initialAmount={initialAmount}
        dl_source={dl_source}
        onPressBack={goBack}
      />
    </ModalDynamic>
  );
};
