import Cookies from 'js-cookie';

export const getAnalyticsSearchParams = (): {[key: string]: any} => {
  //#region paramsOld

  let paramsOld: Record<string, any> = {};
  try {
    paramsOld = JSON.parse(Cookies.get('search-params-from-landing') as string);
  } catch (e) {}

  let initialTrafficSource = {};
  try {
    initialTrafficSource = JSON.parse(
      Cookies.get('initialTrafficSourceJSON') as string
    );
  } catch (e) {}

  if (initialTrafficSource) {
    paramsOld['initialTrafficSource'] = initialTrafficSource;
  }

  let historyTrafficSourceJSON = {};
  try {
    historyTrafficSourceJSON = JSON.parse(
      Cookies.get('historyTrafficSourceJSON') as string
    );
  } catch (e) {}

  if (historyTrafficSourceJSON) {
    paramsOld['historyTrafficSourceJSON'] = historyTrafficSourceJSON;
  }

  //#endregion paramsOld

  //#region params

  const search = window.location.search;
  const hashes = search.slice(search.indexOf(`?`) + 1).split(`&`);
  const params = hashes.reduce((params, hash) => {
    const split = hash.indexOf('=');
    const key = hash.slice(0, split);
    const val = hash.slice(split + 1);
    return {...params, [key]: decodeURIComponent(val)};
  }, {} as any);

  //#endregion params

  if (Object.keys(params).length) {
    const landingSource = (() => {
      if (params.cid) {
        return 'cid';
      }

      if (params.gclid) {
        return 'gclid';
      }

      if (params.aid) {
        return 'aid';
      }

      return undefined;
    })();

    const paramsNew = {
      ...paramsOld,
      ...params,
      ...(landingSource ? {[landingSource]: params, landingSource} : {}),
    };

    Cookies.set('search-params-from-landing', JSON.stringify(paramsNew), {
      domain: '.youhodler.com',
      path: '/',
      expires: 120 * 24 * 60 * 60,
    });

    return paramsNew;
  }

  return paramsOld;
};
