import React from 'react';
import {ModalDynamic, type ModalDynamicProps} from '@youtoken/ui.modal';
import {
  LoansListItemFeatureArgs,
  LoanFTPForm,
} from '@youtoken/ui.legacy-components';
import {useTranslation} from '@youtoken/ui.service-i18n';

interface ModalSetClosePriceProps extends ModalDynamicProps {
  loan: LoansListItemFeatureArgs;
}

export const ModalSetClosePrice: React.FC<ModalSetClosePriceProps> = ({
  loan,
  ...props
}) => {
  const {t} = useTranslation();

  return (
    <ModalDynamic title={t('surface.loans.ftp_form.title')} {...props}>
      <LoanFTPForm mode={loan.mode} id={loan.id} />
    </ModalDynamic>
  );
};
