import * as React from 'react';
import {useResources, getResourceDescriptor} from '@youtoken/ui.data-storage';
import {
  LocaliseResourceNamespace,
  LocaliseResource,
} from '@youtoken/ui.resource-lokalise';
import {BundleTariffListResource} from '@youtoken/ui.resource-bundles';
import {type Item} from '../utils';

export const useData = (columns: number = 1) => {
  const {
    bundleTariffList: {
      data: {tariffs},
    },
  } = useResources({
    lokalise: getResourceDescriptor(LocaliseResource, {
      namespace: LocaliseResourceNamespace.BUNDLE,
    }),
    bundleTariffList: getResourceDescriptor(BundleTariffListResource, {}),
  });

  const tariffsSpaces = React.useMemo(() => {
    return Array.from({length: tariffs.length % columns});
  }, [columns, tariffs.length]);

  const items = React.useMemo(() => {
    const items: Item[] = [];

    tariffs.forEach(tariff => {
      items.push({
        name: 'tariff',
        data: tariff,
      });
    });

    tariffsSpaces.forEach(() => {
      items.push({
        name: 'tariff_space',
      });
    });

    return items;
  }, [tariffs, tariffsSpaces]);

  return {
    items,
  };
};
