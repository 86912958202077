import * as React from 'react';
import {observer} from 'mobx-react';
import {BundleTariff} from '@youtoken/ui.resource-bundles';
import {type BoxProps, Box, Text} from '@youtoken/ui.primitives';
import {BundleIcon} from '../../Icon';

interface BundleSummaryTariffProps {
  data: BundleTariff;
}

export const BundleSummaryTariff: React.FC<
  BundleSummaryTariffProps & BoxProps
> = observer(
  ({
    data: {
      design: {iconUrl, title, type},
    },
    ...boxProps
  }) => {
    return (
      <Box
        testID="BUNDLE_SUMMARY_TARIFF"
        flexDirection="row"
        gap={8}
        {...boxProps}
      >
        <BundleIcon uri={iconUrl} size={32} />
        <Box>
          <Text testID="TITLE" variant="$body-01-high-accent" color="$text-01">
            {title}
          </Text>
          <Text testID="TYPE" variant="$body-02" color="$text-02">
            {type}
          </Text>
        </Box>
      </Box>
    );
  }
);
