import * as React from 'react';
import {
  LoansListItemFeatureArgs,
  LoanDecreaseForm,
} from '@youtoken/ui.legacy-components';
import {ModalDynamic, type ModalDynamicProps} from '@youtoken/ui.modal';
import {useTranslation} from '@youtoken/ui.service-i18n';

interface ModalDecreaseLtvProps extends ModalDynamicProps {
  loan: LoansListItemFeatureArgs;
}

export const ModalDecreaseLtv: React.FC<ModalDecreaseLtvProps> = ({
  loan,
  ...props
}) => {
  const {t} = useTranslation();

  return (
    <ModalDynamic title={t('surface.loans.decrease_form.title')} {...props}>
      <LoanDecreaseForm id={loan.id} />
    </ModalDynamic>
  );
};
