import * as React from 'react';
import {BundlePortfolio, BundleTariff} from '@youtoken/ui.resource-bundles';
import {type BoxProps, Box} from '@youtoken/ui.primitives';
import {BundleSummaryTariff, BundleSummaryPortfolio} from './components';

export interface BundleSummaryProps {
  data: {
    tariff: BundleTariff;
    userPortfolio: BundlePortfolio | null;
  };
}

export const BundleSummary: React.FC<BundleSummaryProps & BoxProps> = ({
  data: {tariff, userPortfolio},
  ...boxProps
}) => {
  return (
    <Box
      testID="BUNDLE_SUMMARY"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      gap={8}
      {...boxProps}
    >
      <BundleSummaryTariff data={tariff} />
      {Boolean(userPortfolio) && (
        <BundleSummaryPortfolio data={userPortfolio!} />
      )}
    </Box>
  );
};
