import * as React from 'react';
import {observer} from 'mobx-react';
import {Box, Text, type BoxProps} from '@youtoken/ui.primitives';
import {useForm} from '@youtoken/ui.form-elements';
import {TextInput} from '@youtoken/ui.inputs';
import {FieldErrorMessage} from '@youtoken/ui.form-elements';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {DesiredRateLeftPart} from './DesiredRateLeftPart';
import {DesiredRateButtons} from './DesiredRateButtons';
import {AutoConvertState} from '../state';

export const AutoConvertDesiredRate: React.FC<BoxProps> = observer(boxProps => {
  const {
    form: {
      ticker,
      tickerFormatted,
      conversionTicker,
      conversionTickerFormatted,
      setDesiredRate,
      desiredRateValue,
      desiredRateError,
      rateCurrentReversedFormatted,
    },
  } = useForm<AutoConvertState>();

  const {t} = useTranslation();

  return (
    <Box {...boxProps}>
      <Box flexDirection="row" justifyContent="space-between" flex={1} mb={8}>
        <Text variant="$body-01-high-accent">
          {t('surface.auto_converts.desired_rate')}
        </Text>
        <Text color="$text-02">
          {`${t(
            'surface.auto_converts.current_rate'
          )}: ${rateCurrentReversedFormatted} ${tickerFormatted}`}
        </Text>
      </Box>
      <TextInput
        LeftPartComponent={
          <DesiredRateLeftPart
            ticker={ticker}
            tickerFormatted={tickerFormatted}
            conversionTicker={conversionTicker}
            conversionTickerFormatted={conversionTickerFormatted}
          />
        }
        RightPartComponent={
          <Box
            flex={1}
            justifyContent="center"
            alignItems="center"
            pr={16}
            ml={3}
          >
            <Text color="$text-02">{tickerFormatted}</Text>
          </Box>
        }
        size="medium"
        value={desiredRateValue}
        textAlign="right"
        autoComplete="off"
        autoCorrect={false}
        autoCapitalize="none"
        importantForAutofill="no"
        keyboardType="decimal-pad"
        onChangeText={setDesiredRate}
      />
      <FieldErrorMessage
        testID="FIELD_ERROR"
        visible={Boolean(desiredRateError)}
      >
        {desiredRateError}
      </FieldErrorMessage>
      <DesiredRateButtons mt={8} />
    </Box>
  );
});
