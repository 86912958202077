import * as React from 'react';
import {G} from 'react-native-svg';
import {useObserver} from 'mobx-react';
import {useColors} from '@youtoken/ui.primitives';
import {
  Wrap,
  GridLabels,
  GridLines,
  LastValueLabel,
  DataLine,
  PastDataLine,
  FutureDataLine,
  LineChartCursor,
  CandlesChartCursor,
  DataCandles,
  ActivityIndicator,
  TriggerPriceLine,
  TriggerPriceLabel,
  TriggerPriceNameLabel,
} from '../Elements';
import {
  useLocalChartState,
  AutoConvertChartState,
  type AutoConvertChartStateProps,
} from '../logic';
import {ChartThemeProvider} from '../Providers';
import {NoDataState} from './NoDataState';

export const AutoConvertChart: React.FC<AutoConvertChartStateProps> =
  React.memo(({children, refetch, ...props}) => {
    const state = useLocalChartState(AutoConvertChartState, props);

    const colors = useColors();

    return useObserver(() => {
      if (!state.hasData) {
        return <NoDataState refetch={refetch} />;
      }

      return (
        <ChartThemeProvider>
          <Wrap chartState={state}>
            <GridLines chartState={state} />
            <GridLabels chartState={state} />

            {state.type === 'line' && (
              <G>
                <PastDataLine chartState={state} />
                <FutureDataLine chartState={state} />
                <DataLine chartState={state} />
              </G>
            )}

            {state.type === 'candle' && (
              <G>
                <DataCandles chartState={state} />
              </G>
            )}

            <G>
              <TriggerPriceLine
                chartState={state}
                color={colors['$interactive-01']}
              />
            </G>

            <G>
              <LastValueLabel chartState={state} />
              <TriggerPriceLabel chartState={state} />
              <TriggerPriceNameLabel chartState={state} />
            </G>

            {state.type === 'line' && <LineChartCursor chartState={state} />}
            {state.type === 'candle' && (
              <CandlesChartCursor chartState={state} />
            )}
          </Wrap>
          {props.displayActivityIndicator && (
            <ActivityIndicator chartState={state} />
          )}
        </ChartThemeProvider>
      );
    }, 'AutoConvertChart');
  });
