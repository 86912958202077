import * as React from 'react';
import {type BoxProps, Box, Text} from '@youtoken/ui.primitives';
import {Tooltip} from '@youtoken/ui.screen-components';
import {BundlesTooltipContent} from './Content';

export const BundlesTooltip: React.FC<BoxProps> = boxProps => {
  return (
    <Tooltip
      testID="BUNDLES_TOOLTIP"
      Component={BundlesTooltipContent}
      {...boxProps}
    />
  );
};
