import {
  computed,
  action,
  observable,
  reaction,
  autorun,
  transaction,
} from 'mobx';
// @ts-ignore
import MobxReactForm from 'mobx-react-form';
import {now, computedFn} from 'mobx-utils';
import Big from 'big.js';
// @ts-ignore
import yupValidator from 'mobx-react-form/lib/validators/YUP';
import {calculateAll, calculateAllReverse} from '@youtoken/converts-calculator';
import * as yupPackage from '@youtoken/ui.yup';
import {
  getTranslatedValidationMessage,
  messages,
} from '@youtoken/ui.validation-messages';
import {Form as FormBase} from '../../ConvertBase/state/Authorized/v2/Form';
import {
  formatBigNumber,
  formatByTicker,
  toBig,
} from '@youtoken/ui.formatting-utils';
import {
  normalizeAmountByTicker,
  normalizeAmountWithPrecision,
} from '@youtoken/ui.normalizers';
import {AutoConvertFormArgs, AutoConvertFormResources} from './types';
import {SHARED_ROUTER_SERVICE} from '@youtoken/ui.shared-router';
import {TRANSPORT} from '@youtoken/ui.transport';
import {LOCAL_NOTIFICATIONS} from '@youtoken/ui.local-notifications';
import {i18n} from '@youtoken/ui.service-i18n';
import {handleFormSubmitRateChangedError} from '../../ConvertBase/state/Authorized/v2/utils';
import {handleFormSubmitError} from '@youtoken/ui.form-utils';
import {getCoinDecimalPrecision} from '@youtoken/ui.coin-utils';

export class Form extends FormBase {
  @observable
  desiredRate: Big;

  @computed get desiredRateValue() {
    return this.instance.$('desiredRate').value;
  }

  constructor(args: AutoConvertFormArgs, resources: AutoConvertFormResources) {
    super({...args, type: 'limit'}, resources);

    this.resources = resources;

    this.desiredRate = this.reverseRate(this.rateCurrent);

    this.instance = new MobxReactForm(
      {
        fields: {
          ticker: {
            value: this.ticker,
          },
          conversionTicker: {
            value: this.conversionTicker,
          },
          amount: {
            value: this.amount,
            handlers: {
              onChange: () => {
                this.side = 'from';
              },
            },
          },
          conversionAmount: {
            handlers: {
              onChange: () => {
                this.side = 'to';
              },
            },
          },
          desiredRate: {
            value: formatByTicker(this.desiredRate, this.ticker),
          },
        },
      },
      {
        plugins: {
          yup: yupValidator({
            package: yupPackage,
            schema: (yup: typeof yupPackage) =>
              yup.lazy(() => {
                return yup.object().shape({
                  ticker: yup.string().required(),
                  conversionTicker: yup.string().required(),
                  amount: yup
                    .big()
                    .gt(0)
                    .lte(this.balance, messages.FUNDS_INSUFFICIENT),
                  conversionAmount: yup.big().gt(0),
                  desiredRate: yup
                    .big()
                    .gt(0)
                    .lte(
                      this.reverseRate(this.rateCurrent),
                      messages.SHOULD_BE_LTE_CURRENT_PRICE
                    )
                    .required(),
                });
              }),
          }),
        },
        hooks: {
          onSuccess: this.submit,
        },
        options: {
          validateOnBlur: false,
          validateOnChange: false,
          validateOnChangeAfterSubmit: true,
        },
      }
    );

    this.disposers = [
      // set rateTimeLeft
      reaction(
        () => now(),
        () => {
          if (this.rateTimeLeft > 0) {
            this.rateTimeLeft = this.rateTimeLeft - 1;
          } else {
            this.updateRate();
          }
        }
      ),
      // set amount / conversionAmount after fields changed
      autorun(() => {
        if (this.side === 'from') {
          this.instance
            .$('conversionAmount')
            .set(this.getConversionAmountFormatted(this.amount));
        } else {
          this.instance
            .$('amount')
            .set(this.getAmountFormatted(this.conversionAmount));
        }
      }),
      // set conversionTicker after fields changed
      reaction(
        () => [this.ticker],
        () => {
          if (this.swap) {
            this.swap = false;
          } else {
            if (this.conversionTickers.includes(this.conversionTicker)) {
              return;
            }

            this.instance.$('conversionTicker').set(this.conversionTickers[0]);
          }

          this.updateAmountsByDesiredRate();
        }
      ),
      // check rate after fields changed
      reaction(
        () => [
          this.side,
          this.ticker,
          this.conversionTicker,
          this.amount,
          this.conversionAmount,
          this.rate,
        ],
        () => {
          this.cancelCheck();
          this.checkDebounced();
        },
        {
          fireImmediately: true,
        }
      ),
      // update amount/conversionAmount after desired rate changed
      reaction(
        () => [this.desiredRateValue],
        () => {
          this.desiredRate = toBig(this.desiredRateValue);
          this.updateAmountsByDesiredRate();
        },
        {
          fireImmediately: true,
        }
      ),

      reaction(
        () => [this.ticker, this.conversionTicker],
        () => {
          transaction(() => {
            this.updateRate();
            this.desiredRate = this.reverseRate(this.rateCurrent);
            this.setDesiredRate(this.desiredRate.toFixed());
          });
        },
        {
          fireImmediately: true,
        }
      ),

      autorun(() => {
        this.resources.ratesResource.refetch();
      }),

      reaction(
        () => [this.amount, this.conversionAmount],
        () => {
          if (!+this.amount && !this.instance.$('amount').changed) {
            this.instance.$('amount').clear();
          }
          if (
            !+this.conversionAmount &&
            !this.instance.$('conversionAmount').changed
          ) {
            this.instance.$('conversionAmount').clear();
          }
        },
        {
          fireImmediately: true,
        }
      ),
    ];
  }

  @observable
  resources: AutoConvertFormResources;

  @action
  setDesiredRate = (value: string) => {
    this.instance
      .$('desiredRate')
      .set('value', normalizeAmountWithPrecision(value, this.tickerPrecision));
  };

  @computed
  get desiredRateError() {
    return getTranslatedValidationMessage(this.instance.$('desiredRate').error);
  }

  @action updateAmountsByDesiredRate = () => {
    if (this.side === 'from') {
      transaction(() => {
        const newConversionAmountNormalized = normalizeAmountByTicker(
          toBig(this.amount).div(this.desiredRate).toString(),
          this.conversionTicker
        );
        this.instance.$('conversionAmount').set(newConversionAmountNormalized);
        this.side = 'from'; // to prevent side change in onChange field handler
      });
    } else {
      transaction(() => {
        const newAmountNormalzed = normalizeAmountByTicker(
          toBig(this.conversionAmount).mul(this.desiredRate).toString(),
          this.ticker
        );
        this.instance.$('amount').set(newAmountNormalzed);
        this.side = 'to'; // to prevent side change in onChange field handler
      });
    }
  };

  @action
  submit = () => {
    const {walletsDisclosureSigned, checkProductAvailability} =
      this.resources.authMeResource;

    if (!checkProductAvailability('exchange')) {
      return Promise.resolve();
    }

    if (!walletsDisclosureSigned) {
      SHARED_ROUTER_SERVICE.navigate('DisclosureWalletsModal', {});
      return Promise.resolve();
    }

    return TRANSPORT.API.post('/v2/converts/exchange', {
      side: this.side,
      fromTicker: this.ticker,
      toTicker: this.conversionTicker,
      fromAmount: this.amount,
      toAmount: this.conversionAmount,
      rate: normalizeAmountWithPrecision(
        this.reverseRate(this.desiredRate).toFixed(),
        this.side === 'from'
          ? this.conversionTickerPrecision
          : this.tickerPrecision
      ),
      type: 'limit',
    })
      .then(() => {
        LOCAL_NOTIFICATIONS.info({
          text: i18n.t('surface.wallets.crypto_withdrawal.message.success'),
        });
        SHARED_ROUTER_SERVICE.navigate('__CloseModal', {});
        SHARED_ROUTER_SERVICE.navigate('WalletsList', {});
        SHARED_ROUTER_SERVICE.navigate('WalletsItem', {
          ticker: this.ticker,
        });
      })
      .then(() => {
        this.resources.autoConvertResource.refetch();
      })
      .catch(error => {
        handleFormSubmitRateChangedError(error, this.updateRate);
        handleFormSubmitError(this.instance, error, {
          price: 'desiredRate',
        });
      });
  };

  getConversionAmount = computedFn(_amount => {
    const amount = toBig(_amount);

    const {toAmount} = calculateAll(
      amount,
      this.tariff.fee,
      this.reverseRate(this.desiredRate),
      this.ticker,
      this.conversionTicker
    );

    return toAmount;
  });

  getAmount = computedFn(_requiredConversionAmount => {
    const conversionAmountRequired = toBig(_requiredConversionAmount);

    const {fromAmount} = calculateAllReverse(
      conversionAmountRequired,
      this.tariff.fee,
      this.reverseRate(this.desiredRate),
      this.ticker
    );

    return fromAmount;
  });

  reverseRate = computedFn(rate => {
    return rate.eq(0) ? toBig(0) : toBig(1).div(rate);
  });

  @computed
  get rateCurrentReversedFormatted() {
    return formatBigNumber(
      this.reverseRate(this.rateCurrent),
      this.tickerPrecision,
      true
    );
  }

  @computed
  get desiredRateUnreversedFormatted() {
    return formatBigNumber(
      this.reverseRate(this.desiredRate),
      this.conversionTickerPrecision,
      true
    );
  }

  @computed get conversionTickerPrecision() {
    return getCoinDecimalPrecision(this.conversionTicker, 8);
  }

  @computed get tickerPrecision() {
    return getCoinDecimalPrecision(this.ticker, 8);
  }

  calculateDesiredRateByPercent = computedFn((percent: number) => {
    const multiplier = 100 - percent;
    this.setDesiredRate(
      this.reverseRate(this.rateCurrent).mul(multiplier).div(100).toFixed()
    );
  });
}
