import * as React from 'react';
import {SectionList} from 'react-native';
import {cell} from '@youtoken/ui.cell';
import {Box} from '@youtoken/ui.primitives';
import {Pagination, Separator} from '@youtoken/ui.elements';
import {
  HistoryItemData,
  HistoryListResource,
} from '@youtoken/ui.resource-history-new';
import {HistoryListEmptyState, SectionHeader} from './components';
import {AutoConvertResource} from '@youtoken/ui.resource-auto-convert';
import {type HistoryListProps} from './types/HistoryListTestProps';
import {renderItem} from './components/renderItem';
import {AutoConvertListItem} from '../AutoConverts';

export const HistoryList: React.FC<HistoryListProps> = cell(
  ({
    resourceKey,
    tickers,
    pageSize,
    SectionListHeaderComponent,
    SectionListComponent = SectionList,
  }) => {
    const {
      sectionsByDate,
      currentPage,
      totalPages,
      setHistoryPage,
      resetPagination,
      isLoading,
      refetch,
    } = HistoryListResource.use({
      key: resourceKey,
      limit: pageSize,
      ticker: tickers,
    });

    const {hasAutoConverts, autoConvertSection: _autoConvertSection} =
      AutoConvertResource.use({
        ticker: tickers?.[0],
      });

    React.useEffect(() => {
      refetch();
      return resetPagination;
    }, []);

    const historyPrevPageRef = React.useRef(currentPage);

    React.useEffect(() => {
      if (historyPrevPageRef.current !== currentPage) {
        window.scrollTo({behavior: 'smooth', top: 0});
        historyPrevPageRef.current = currentPage;
      }
    }, [currentPage]);

    const keyExtractor = React.useCallback(
      (item: HistoryItemData | AutoConvertListItem) => {
        return 'id' in item ? item.id : 'auto_convert';
      },
      []
    );

    const onPageChange = React.useCallback(
      (paginationPage: number) => {
        setHistoryPage(paginationPage - 1);
      },
      [setHistoryPage]
    );

    const autoConvertSection = React.useMemo(() => {
      if (hasAutoConverts && currentPage === 0) {
        return [_autoConvertSection];
      }
      return [];
    }, [hasAutoConverts, currentPage]);

    return (
      <>
        {React.isValidElement(SectionListHeaderComponent) &&
          SectionListHeaderComponent}
        <Box
          width="100%"
          opacity={isLoading ? 0.6 : 1}
          testID={
            tickers
              ? `${tickers.map(t => t.toUpperCase()).join('_')}_HISTORY`
              : 'HISTORY_LIST'
          }
        >
          <Box
            borderColor="$ui-01"
            borderWidth={1}
            overflow="hidden"
            borderRadius={10}
            flex={1}
          >
            <SectionListComponent
              stickySectionHeadersEnabled
              sections={[...autoConvertSection, ...sectionsByDate]}
              // @ts-ignore
              renderSectionHeader={SectionHeader}
              renderItem={renderItem}
              ItemSeparatorComponent={Separator}
              keyExtractor={keyExtractor}
              ListEmptyComponent={HistoryListEmptyState}
            />
          </Box>

          {totalPages > 1 && (
            <Box
              my={24}
              flexDirection="row"
              justifyContent={{default: 'center', desktop: 'flex-start'}}
            >
              <Pagination
                activePage={currentPage + 1}
                totalPages={totalPages}
                onPageChange={onPageChange}
              />
            </Box>
          )}
        </Box>
      </>
    );
  }
);
