import {
  type IReactionDisposer,
  action,
  autorun,
  computed,
  observable,
} from 'mobx';
import {formatDistanceToNowStrict} from '@youtoken/ui.date-fns';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';
import {i18n} from '@youtoken/ui.service-i18n';
import {toBig, formatBigNumber} from '@youtoken/ui.formatting-utils';
import {createFeature, getResourceDescriptor} from '@youtoken/ui.data-storage';
import {WalletsResource} from '@youtoken/ui.resource-wallets';
import {FeaturesResource} from '@youtoken/ui.resource-features';
import {RatesResource} from '@youtoken/ui.resource-rates';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {
  LocaliseResourceNamespace,
  LocaliseResource,
} from '@youtoken/ui.resource-lokalise';
import {Bundle, BundleOverviewResource} from '@youtoken/ui.resource-bundles';
import {SIMPLE_STORAGE} from '@youtoken/ui.service-storage';
import type {ISection, ISections, ISectionsVisibilities} from '../types';
import {getCoinSymbol} from '@youtoken/ui.coin-utils';

const SECTION_VISIBILITIES_STORAGE_KEY = 'wallets:sectionsVisibility';

interface WalletsFeatureResources {
  features: ReturnType<typeof getResourceDescriptor<typeof FeaturesResource>>;
  rates: ReturnType<typeof getResourceDescriptor<typeof RatesResource>>;
  authme: ReturnType<typeof getResourceDescriptor<typeof AuthMeResource>>;
  wallets: ReturnType<typeof getResourceDescriptor<typeof WalletsResource>>;
  lokalise?: ReturnType<typeof getResourceDescriptor<typeof LocaliseResource>>;
  bundleOverview?: ReturnType<
    typeof getResourceDescriptor<typeof BundleOverviewResource>
  >;
}

export class WalletsFeature extends createFeature({
  getKey: _args => {
    return `Wallets`;
  },
  getResources: (): WalletsFeatureResources => {
    const resources = {
      features: getResourceDescriptor(FeaturesResource, {}),
      rates: getResourceDescriptor(RatesResource, {}),
      authme: getResourceDescriptor(AuthMeResource, {}),
      wallets: getResourceDescriptor(WalletsResource, {}),
    };

    if (AuthMeResource.getInstanceSafely({})?.products?.bundles.available) {
      return {
        ...resources,
        lokalise: getResourceDescriptor(LocaliseResource, {
          namespace: LocaliseResourceNamespace.BUNDLE,
        }),
        bundleOverview: getResourceDescriptor(BundleOverviewResource, {}),
      };
    }

    return resources;
  },
}) {
  @observable disposers: IReactionDisposer[] = [];

  @computed
  public get enableNotifications() {
    return this.resources.authme.data.enableTopBar;
  }

  @computed
  public get enableStories() {
    return (
      !this.resources.features.hideStories &&
      this.resources.authme.data.enableStories
    );
  }

  @computed
  public get enableCoindrops() {
    return this.resources.authme.data.hasCoindrops;
  }

  @computed
  public get enableNewReferral() {
    return (
      this.resources.authme.newReferralEnabled &&
      !this.resources.authme.data.clientSettings.newReferralBannerHidden
    );
  }

  @computed
  public get enableOnboardingWidget() {
    return this.resources.authme.data.enableOnboardingWidget;
  }

  @computed
  public get depositNowVariant() {
    return this.resources.authme.data.hasEverDeposited
      ? 'deposited'
      : 'never-deposited';
  }

  constructor(args: any, resources: any) {
    super(args, resources);

    this.disposers.push(
      //  NOTE: Wallets with amount, must be shown
      autorun(() => {
        const tickers = this.resources.wallets.walletsList
          .filter(
            wallet =>
              !this.addedWallets.includes(wallet) &&
              (wallet.amount.gt(0) || wallet.hodlsInputAmount.gt(0))
          )
          .map(wallet => wallet.ticker);

        if (tickers.length) {
          this.resources.authme.appendToShowedTickers(...tickers);
        }
      })
    );
  }

  onDestroy() {
    super.onDestroy();
    this.disposers.forEach(disposer => disposer());
  }

  //#region summary

  @computed
  public get summaryCurrency() {
    return this.resources.authme.mainCurrency;
  }

  @computed
  public get currencySign() {
    return getCoinSymbol(this.summaryCurrency);
  }

  @computed
  private get summaryCapital() {
    return toBig(this.resources.wallets.totalCapital[this.summaryCurrency]);
  }

  @computed
  public get summaryCapitalFormatted() {
    return formatBigNumber(this.summaryCapital, 2);
  }

  @computed
  private get summaryEarned() {
    return toBig(this.resources.wallets.payout.toEarn?.[this.summaryCurrency]);
  }

  @computed
  public get summaryEarnedFormatted() {
    return formatBigNumber(this.summaryEarned, 2);
  }

  @computed
  public get summaryNextSettlementPeriod() {
    return (
      this.resources.wallets.data.payout.nextSettlementPeriod ?? new Date()
    );
  }

  @computed
  public get summaryNextSettlementPeriodFormatted() {
    return i18n.t('surface.wallets.header.payout_in', {
      dateFormated: formatDistanceToNowStrict(this.summaryNextSettlementPeriod),
    });
  }

  @computed
  public get summaryRewardsEnabled() {
    return this.resources.authme.data!.enableRewards;
  }

  //#endregion summary

  //#region sectionOptions

  @computed get sectionsHighlights() {
    return {
      bundles:
        this.resources.authme.data.clientSettings.enableBundleWelcomePage,
    } as Record<ISection, boolean | undefined>;
  }

  @computed get getSectionVisibilities() {
    const sectionVisibilities =
      this.resources.authme.data.clientSettings.sectionVisibilities;

    const storedString = SIMPLE_STORAGE.get(SECTION_VISIBILITIES_STORAGE_KEY);

    if (!storedString) {
      return sectionVisibilities;
    }

    try {
      return JSON.parse(storedString);
    } catch {
      return sectionVisibilities;
    }
  }

  @observable sectionsVisibilities: ISectionsVisibilities =
    this.getSectionVisibilities;

  @action setSectionVisibility = (section: string, value: boolean) => {
    const event = value ? 'wallet-section-expand' : 'wallet-section-collapse';
    DATA_LAYER.trackStrict(event, {section});

    this.sectionsVisibilities = {
      ...this.sectionsVisibilities,
      [section]: value,
    };

    SIMPLE_STORAGE.set(
      SECTION_VISIBILITIES_STORAGE_KEY,
      JSON.stringify(this.sectionsVisibilities)
    );

    this.resources.authme.setShowedSectionTickers(this.sectionsVisibilities);
  };

  //#endregion sectionOptions

  //#region sections

  @computed
  public get sections(): ISections {
    const data = {} as ISections;

    if (this.bundles) {
      data.bundles = this.bundles;
    }

    data.fiat = this.shownFiatWallets;
    data.stable = this.shownStableWallets;
    data.crypto = this.shownCryptoWallets;

    if (this.hiddenWallets.length > 0) {
      data.hidden = this.hiddenWallets;
    }

    return data;
  }

  @computed
  get bundles() {
    return this.resources.bundleOverview?.data?.portfolios;
  }

  @computed
  get addedWallets() {
    return this.resources.wallets.walletsListWithAmountOrder.filter(
      ({ticker}) => {
        return this.resources.authme.addedWalletTickers.includes(ticker);
      }
    );
  }

  @computed
  get shownWallets() {
    return this.resources.wallets.walletsListWithAmountOrder.filter(
      ({ticker}) => {
        return this.resources.authme.showedWalletTickers.includes(ticker);
      }
    );
  }

  @computed
  get shownFiatWallets() {
    return this.shownWallets.filter(({ticker}) =>
      this.resources.wallets.fiatTickers.includes(ticker)
    );
  }

  @computed
  get shownStableWallets() {
    return this.shownWallets.filter(({ticker}) =>
      this.resources.wallets.stableTickers.includes(ticker)
    );
  }

  @computed
  get shownCryptoWallets() {
    return this.shownWallets.filter(({ticker}) =>
      this.resources.wallets.cryptoTickers.includes(ticker)
    );
  }

  @computed
  get hiddenWallets() {
    return this.resources.wallets.walletsListWithAmountOrder.filter(
      ({ticker}) => this.resources.authme.hiddenWalletTickers.includes(ticker)
    );
  }

  //#endregion sections
}
