import * as React from 'react';

export const SurfaceParamsContext = React.createContext({});

export function SurfaceParamsContextProvider<Params extends {}>({
  children,
  params,
}: {
  children: React.ReactNode;
  params: Params;
}) {
  return (
    <SurfaceParamsContext.Provider value={params}>
      {children}
    </SurfaceParamsContext.Provider>
  );
}

export function useSurfaceParams<Params extends {}>() {
  return React.useContext(SurfaceParamsContext) as Params;
}
