import * as React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {Box, Text} from '@youtoken/ui.primitives';
import {TickerAndAmountInput} from '@youtoken/ui.ticker-and-amount-input';
import {useForm, Label, FieldErrorMessage} from '@youtoken/ui.form-elements';
import type {BundleWithdrawalFormState} from '../../../state';
import {TotalBundleAmount} from './TotalBundleAmount';

export const Amount: React.FC = observer(() => {
  const {t} = useTranslation();

  const {
    form: {
      tickerItems,
      ticker,
      amount,
      sourceHasError,
      sourceError,
      sourceInfo,
      calculated: {data},
      tickerOnChange,
      amountOnChange,
    },
  } = useForm<BundleWithdrawalFormState>();

  return (
    <Box testID="AMOUNT" zIndex={1}>
      <Box flexDirection="row" justifyContent="space-between" mb={8}>
        <Label testID="AMOUNT_LABEL">
          {t('surface.bundles.withdrawal_form.amount')}
        </Label>
        <TotalBundleAmount />
      </Box>

      <TickerAndAmountInput
        testID="AMOUNT_INPUT"
        disabled={data?.onlyWithdrawAll}
        wallets={tickerItems}
        ticker={ticker}
        amount={amount}
        onChangeTicker={tickerOnChange}
        onChangeAmount={amountOnChange}
      />

      <Box>
        <Box opacity={Number(!sourceHasError)}>
          <Text testID="AMOUNT_INFO" variant="$body-02" color="$text-02" mt={8}>
            {sourceInfo}
          </Text>
        </Box>
        <Box position="absolute" left={0} right={0}>
          <FieldErrorMessage testID="AMOUNT_ERROR" visible={sourceHasError}>
            {sourceInfo}
          </FieldErrorMessage>
        </Box>
      </Box>
    </Box>
  );
});
