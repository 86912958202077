import * as React from 'react';
import {observer} from 'mobx-react';
import {SUPPORT} from '@youtoken/ui.support';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {BaseErrorFallback} from './BaseFallback';
import {Handler} from './Handler';
import {ErrorFallbackProps} from './types';

export const DefaultErrorFallback: React.FC<ErrorFallbackProps> = observer(
  ({retriesCount = 0, ...props}) => {
    const {t} = useTranslation();

    return (
      <BaseErrorFallback
        hint={t('common.errors.smth_went_wrong.hint')}
        {...props}
      >
        {retriesCount > 6 && (
          <Handler onPress={() => SUPPORT.reloadApp}>
            {t('surface.error.reload')}
          </Handler>
        )}
        {retriesCount <= 6 && (
          <Handler onPress={props.dismissError}>
            {t('surface.error.retry')}
          </Handler>
        )}
      </BaseErrorFallback>
    );
  }
);
