import * as React from 'react';
import {Box, type BoxProps} from '../Box';

export function XStack({flexDirection = 'row', ...props}: BoxProps) {
  return <Box flexDirection={flexDirection} {...props} />;
}

XStack.displayName = 'XStack';

export function YStack({flexDirection = 'column', ...props}: BoxProps) {
  return <Box flexDirection={flexDirection} {...props} />;
}

YStack.displayName = 'YStack';

export type YStackProps = BoxProps;

export type XStackProps = BoxProps;
