import {i18n} from '@youtoken/ui.service-i18n';
import {Platform} from 'react-native';

type UnlimintWebData = {
  returnUrl: string;
};

type CheckoutWebData = {
  declineUrl: string;
  cancelUrl: string;
  successUrl: string;
};

type CommonNativeData = {
  returnUrl?: string;
  declineUrl: string;
  cancelUrl: string;
  successUrl: string;
  inprocessUrl?: string;
};

type ProvidersData = CheckoutWebData | UnlimintWebData | CommonNativeData;

export const getReturnUrls = (
  ticker: string,
  selectedCardId: string,
  amount: string,
  conversionTicker?: string
): ProvidersData => {
  // NOTE: Redirect urls will leas a user to the bank card withdrawal wizard after 3ds
  const webRedirectUrl = `/wallets/${ticker}/withdrawal/fiat/bank-card?source=checkoutPaymentPage&cardId=${selectedCardId}&amount=${amount}${
    conversionTicker ? `&conversionTicker=${ticker}` : ''
  }`;

  // NOTE: If there is error in 3ds (or card), this param will be used to show error to the user
  const failedWebRedirectUrl = webRedirectUrl + '&withError=3ds';

  return Platform.select({
    web: {
      cancelUrl: failedWebRedirectUrl,
      declineUrl: failedWebRedirectUrl,
      successUrl: webRedirectUrl,
    },
    // NOTE: This is just screens with some statuses
    native: {
      cancelUrl: '/status/cancel?method=verification',
      declineUrl: '/status/fail?method=verification',
      inprocessUrl: '/status/processing?method=verification',
      successUrl: '/status/success?method=verification',
    } as ProvidersData,
  })!;
};

export const getCardWithdrawalWizardTitle = (ticker: string) => {
  return i18n.t('surface.wallets.fiat_withdrawal_card.title', {
    ticker: ticker.toUpperCase(),
  });
};

export const getCardWithdrawalWizardOptions = ({
  route: {
    params: {ticker},
  },
}: {
  route: {
    params: {ticker: string};
  };
}) => {
  return {
    title: getCardWithdrawalWizardTitle(ticker),
  };
};
