import * as React from 'react';
import {Platform} from 'react-native';
import {KeyboardScrollView, useWizard} from '@youtoken/ui.elements';
import {getResourceDescriptor, useResources} from '@youtoken/ui.data-storage';
import {FeeAllResource} from '@youtoken/ui.resource-fee-all';
import {AuthMeResource} from '@youtoken/ui.resource-auth-me';
import {Select, type SelectItem} from '@youtoken/ui.select';
import {
  FieldErrorMessage,
  Form,
  Label,
  makeForm,
  TFAFormField,
} from '@youtoken/ui.form-elements';
import {WalletsResource} from '@youtoken/ui.resource-wallets';
import {DATA_LAYER} from '@youtoken/ui.service-data-layer';
import {CardsResource} from '@youtoken/ui.resource-cards';
import {RatesResource} from '@youtoken/ui.resource-rates';
import {useTranslation} from '@youtoken/ui.service-i18n';
import {IconPaymentMethod} from '@youtoken/ui.icons';
import {Box} from '@youtoken/ui.primitives';
import {Button} from '@youtoken/ui.buttons';
import {cell} from '@youtoken/ui.cell';
import {FeeRow, PaySumRow, HeaderBase} from '../../../components';
import {CardWithdrawalForm} from './state';
import {WithdrawSumField} from './components';
import {
  CardWithdrawalWizardNavigator,
  CardWithdrawalWizardScreenName,
} from '../index';

const showHeader = Platform.select({
  default: true,
  native: false,
});

export const CardWithdrawalSurface: React.FC = cell(() => {
  const {
    step: {
      props: {
        ticker,
        conversionTicker: providedConversionTicker,
        cardId,
        amount,
        withError,
      },
    },
    navigate,
    goBack,
    close,
  } = useWizard<
    CardWithdrawalWizardNavigator,
    CardWithdrawalWizardScreenName.MAIN
  >();

  const {t} = useTranslation();

  const resources = useResources({
    wallets: getResourceDescriptor(WalletsResource, {}),
    feeAll: getResourceDescriptor(FeeAllResource, {}),
    authMe: getResourceDescriptor(AuthMeResource, {}),
    rates: getResourceDescriptor(RatesResource, {}),
    cards: getResourceDescriptor(CardsResource, {}),
  });

  React.useEffect(() => {
    DATA_LAYER.trackStrict('withdrawal-fiat-attempt', {
      provider: 'CheckoutBankCard',
      ticker: ticker,
    });
  }, [ticker]);

  const conversionTicker = React.useMemo(() => {
    if (providedConversionTicker) {
      return providedConversionTicker;
    }

    return (ticker === 'eur' || ticker === 'usd') &&
      resources.authMe.residenceOrCountry === 'ARG'
      ? 'ars'
      : undefined;
  }, [ticker, resources.authMe.residenceOrCountry]);

  const {view, form} = makeForm(() => {
    return new CardWithdrawalForm(
      {
        ticker,
        conversionTicker,
        cardId,
        amount,
        withError,
        onSubmit: close,
      },
      resources
    );
  });

  const {
    tickerFees,
    cardsList,
    isCardsListEmpty,
    setCardId,
    selectedCardId,
    isSubmitDisabled,
    isSubmitLoading,
    isThereCard3dsError,
    isCardVerified,
    twoFactorAuthOperationCodeError,
    createTwoFactorAuthOperation,
    twoFactorAuthOperationCodeOnChange,
    instance: {onSubmit, submitting},
  } = form;
  const {tickerFormatted, conversionTickerFormatted, paySumFormatted} = view;

  const handlePressManage = React.useCallback(() => {
    navigate(CardWithdrawalWizardScreenName.PAYMENT_METHODS);
  }, [navigate]);

  const selectItems: SelectItem[] = React.useMemo(
    () => [
      // NOTE: Main items
      ...cardsList.map(card => {
        const cardBrandFormatted = card.brand.toLowerCase() as
          | 'visa'
          | 'mastercard';

        return {
          value: card.id,
          label: `• ${card.last4}`,
          icon: (
            <Box height={20} flexDirection="row" alignItems="center">
              <IconPaymentMethod name={cardBrandFormatted} size={32} />
            </Box>
          ),
        };
      }),
      // NOTE: Action button (Manage cards)
      {
        type: 'action-button',
        label: t('surface.wallets.manage'),
        onPress: handlePressManage,
      },
    ],
    [cardsList, handlePressManage]
  );

  const handleSubmitWithAddCard = React.useCallback(
    (cardId: string) => {
      setCardId(cardId);

      // NOTE: preventDefault is required avoid conflict with submitting Checkout Frames form inside our MobxReactForm
      onSubmit({
        preventDefault: () => {},
      });
    },
    [navigate, onSubmit]
  );

  const handleNavigateToAddCard = React.useCallback(() => {
    navigate(CardWithdrawalWizardScreenName.ADD_CARD_WITH_PAYMENT, {
      onSubmit: handleSubmitWithAddCard,
      ticker: ticker.toUpperCase(),
    });
  }, [navigate]);

  const submitButtonLabel = React.useMemo(() => {
    if (isCardsListEmpty) {
      return t('surface.wallets.fiat_deposit_advcash.continue_button');
    }

    if (!isCardVerified) {
      return t('surface.wallets.fiat_withdrawal.verify_card_title');
    }

    return t('surface.wallets.fiat_withdrawal.title', {
      ticker: ticker.toUpperCase(),
    });
  }, [isCardVerified, isCardsListEmpty, ticker]);

  if (!tickerFees) {
    return null;
  }

  return (
    <>
      {showHeader && (
        <HeaderBase onPressBack={goBack} shouldShowSeparator>
          {t('surface.wallets.fiat_withdrawal_card.title', {
            ticker: ticker.toUpperCase(),
          })}
        </HeaderBase>
      )}
      <KeyboardScrollView
        insideTabs={false}
        wrapInSafeArea
        scrollViewProps={{
          keyboardDismissMode: 'interactive',
          style: {flex: 1},
          contentContainerStyle: {
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: 0,
            paddingRight: 0,
          },
        }}
      >
        <Box flex={1} py={24}>
          <Form form={{view, form}}>
            <Box flex={1} gap={24} justifyContent="space-between">
              <Box zIndex={10}>
                <Box px={24}>
                  <WithdrawSumField />

                  {!isCardsListEmpty && (
                    <Box gap={8}>
                      <Label pt={24}>
                        {t(
                          'surface.wallets.checout_bank_card.select_card_action'
                        )}
                      </Label>
                      <Box>
                        <Select
                          bottomSheetTitle={t(
                            'surface.wallets.checout_bank_card.select_card_action'
                          )}
                          items={selectItems}
                          onSelect={setCardId}
                          selected={selectedCardId}
                          size="large"
                          width="100%"
                        />
                        <FieldErrorMessage visible={isThereCard3dsError}>
                          {t('validation.CARD_VALIDATION_FAILED')}
                        </FieldErrorMessage>
                      </Box>
                    </Box>
                  )}
                </Box>
              </Box>
              <Box>
                {!isCardsListEmpty && isCardVerified && (
                  <TFAFormField
                    error={twoFactorAuthOperationCodeError}
                    getOperationData={createTwoFactorAuthOperation}
                    onChangeCode={twoFactorAuthOperationCodeOnChange}
                  />
                )}
                <FeeRow px={24} mt={24} />
                <PaySumRow
                  px={24}
                  value={paySumFormatted}
                  ticker={conversionTickerFormatted || tickerFormatted}
                  text={t('surface.wallets.crypto_withdrawal.to_receive')}
                />
                <Box width="100%" px={24} flex={1}>
                  <Button
                    disabled={isSubmitDisabled || submitting}
                    loading={isSubmitLoading || submitting}
                    onPress={
                      isCardsListEmpty ? handleNavigateToAddCard : onSubmit
                    }
                    size="large"
                    testID="FIAT_WITHDRAW_SUBMIT_BUTTON"
                    color="interactive"
                  >
                    {submitButtonLabel}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Form>
        </Box>
      </KeyboardScrollView>
    </>
  );
});
