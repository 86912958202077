import * as React from 'react';
import {Helmet} from 'react-helmet';
import {cell} from '@youtoken/ui.cell';
import {PageSwitcherValue} from '@youtoken/ui.legacy-components';
import {HODLsMainPageContent} from './elements/HODLsMainPageContent';
import {InnerPageShell} from '@web-app/src/components/layout/Shell';

export interface HODLsMainPageProps {
  innerPage: PageSwitcherValue;
}

export const HODLsMainPage: React.FC<HODLsMainPageProps> = cell(
  ({innerPage}) => {
    return (
      <InnerPageShell>
        <Helmet title="YouHodler | MultiHODL™" />
        <HODLsMainPageContent innerPage={innerPage} />
      </InnerPageShell>
    );
  }
);
