import * as React from 'react';
import {observer} from 'mobx-react';
import {BundlePortfolioTickersListItem} from '@youtoken/ui.resource-bundles';
import {type BoxProps, Box, Text} from '@youtoken/ui.primitives';
import {LogoColored, LogoColoredIconName} from '@youtoken/ui.icons';

interface BundleTickersListItemProps {
  item: BundlePortfolioTickersListItem;
}

export const BundleTickersListItem: React.FC<
  BundleTickersListItemProps & BoxProps
> = observer(({testID = 'BUNDLE_TICKERS_LIST_ITEM', item, ...boxProps}) => {
  return (
    <Box
      testID={`${testID}_${item.ticker.toUpperCase()}`}
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      py={16}
      {...boxProps}
    >
      <Box
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        gap={8}
      >
        <LogoColored
          testID="LOGO"
          name={item.ticker as LogoColoredIconName}
          size={32}
        />
        <Box>
          <Text variant="$body-01-high-accent">{item.tickerName}</Text>
          <Text variant="$body-02" color="$text-02">
            {item.rateFormatted} {item.conversionTickerFormatted}
          </Text>
        </Box>
      </Box>
      <Box alignItems="flex-end">
        <Text variant="$body-01-high-accent">
          {item.conversionAmountFormatted} {item.conversionTickerFormatted}
        </Text>
        <Text variant="$body-02" color="$text-02">
          {item.amountFormatted} {item.tickerFormatted}
        </Text>
      </Box>
    </Box>
  );
});
