import * as React from 'react';
import {
  Text,
  Box,
  TouchableBox,
  TColorTokensNames,
  TColorTokens,
} from '@youtoken/ui.primitives';
import {Icon, BaseIconName} from '@youtoken/ui.icons';

const getActionColors = (
  withAccent: boolean,
  disabled?: boolean
): {
  bgColor: TColorTokensNames;
  iconColor: keyof TColorTokens;
  textColor: TColorTokensNames;
} => {
  return {
    bgColor: disabled
      ? '$ui-01'
      : withAccent
      ? '$interactive-01'
      : '$interactive-02',
    iconColor: disabled ? '$text-03' : withAccent ? '$text-04' : '$text-05',
    textColor: disabled ? '$text-03' : '$text-05',
  };
};

const ITEM_SIZE = 56;
const LABEL_OFFSET = 8;
const LABEL_HEIGHT = 18;

export type ActionItemProps = {
  title: string;
  iconName: BaseIconName;
  withAccent: boolean;
  disabled?: boolean;
  onPress: () => void;
};

export const ActionItem: React.FC<ActionItemProps> = ({
  title,
  iconName,
  withAccent,
  disabled,
  onPress,
}) => {
  const {bgColor, iconColor, textColor} = getActionColors(withAccent, disabled);

  return (
    <TouchableBox
      px={4}
      onPress={onPress}
      disabled={disabled}
      alignItems="center"
      testID={`ACTIONS_BAR_ITEM_${title.toUpperCase()}`}
      pb={LABEL_HEIGHT}
      overflow="visible"
    >
      <Box
        justifyContent="center"
        alignItems="center"
        width={ITEM_SIZE}
        height={ITEM_SIZE}
        borderRadius={ITEM_SIZE / 2}
        backgroundColor={bgColor}
        mb={8}
      >
        <Icon name={iconName} size={24} color={iconColor} />
      </Box>
      <Box
        position="absolute"
        top={ITEM_SIZE + LABEL_OFFSET}
        width={{default: 85, tablet: 95}}
      >
        <Text
          variant="$body-01-medium-accent"
          color={textColor}
          textAlign="center"
        >
          {title}
        </Text>
      </Box>
    </TouchableBox>
  );
};
