import * as React from 'react';
import {cell} from '@youtoken/ui.cell';
import {type BoxProps, Box} from '@youtoken/ui.primitives';
import {getResourceDescriptor, useResources} from '@youtoken/ui.data-storage';
import {MarketsResource} from '@youtoken/ui.resource-markets';
import {FeeAllResource} from '@youtoken/ui.resource-fee-all';
import {RatesResource} from '@youtoken/ui.resource-rates';
import {Form, makeForm} from '@youtoken/ui.form-elements';
import {
  ConversionUnauthorizedFormClass,
  ExchangeFormContentV1,
  ExchangeFormFooterV1,
} from '../ConvertBase';

export interface ConversionUnauthorizedFormProps extends BoxProps {
  ticker?: string;
  toTicker?: string;
  isInIframe?: boolean;
}

export const ConversionUnauthorizedForm: React.FC<ConversionUnauthorizedFormProps> =
  cell(({ticker, toTicker, isInIframe, ...props}) => {
    const resources = useResources({
      marketsResource: getResourceDescriptor(MarketsResource, {}),
      ratesResource: getResourceDescriptor(RatesResource, {}),
      feeResource: getResourceDescriptor(FeeAllResource, {}),
    });

    const form = makeForm(
      () =>
        new ConversionUnauthorizedFormClass(resources, {
          ticker,
          conversionTicker: toTicker,
          isInIframe,
        })
    );

    return (
      <Form form={form}>
        <Box borderWidth={1} borderColor="$ui-01" borderRadius={12} {...props}>
          <ExchangeFormContentV1
            showBalance={false}
            targetAmountEditable={false}
            showAmountFastHandlers={false}
          />
          <ExchangeFormFooterV1 showAgreements={false} />
        </Box>
      </Form>
    );
  });
